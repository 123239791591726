import { useEffect, useRef } from "react";

// eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
export default function useEffectOnce(callback: Function) {
  const strictRef = useRef(false);
  return useEffect(() => {
    if (!strictRef.current) {
      const callbackResult = callback();

      if (typeof callbackResult === "function") {
        return callbackResult;
      }

      strictRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
