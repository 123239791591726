import { produce } from "immer";
import UserRest from "../../api/model/UserRest";
import { sliceUpdate, UpdateFunction } from "../UpdateFunction";
import { PersonalDetails } from "./PersonalDetails";

class User {
  readonly personalDetails: PersonalDetails;

  constructor(
    private user: UserRest,
    updateUser: UpdateFunction<UserRest>,
  ) {
    this.personalDetails = new PersonalDetails(
      user.personalDetails,
      sliceUpdate(updateUser, (draft) => draft.personalDetails),
    );
  }

  get id(): number | undefined {
    return this.user.id;
  }

  get email(): string | undefined {
    return this.user.email;
  }

  get isLoggedIn(): boolean {
    return !!this.user.id;
  }

  get ahPlusMembership() {
    return this.user.ahPlusMembership;
  }

  get userProfileUuid() {
    return this.user.userProfileUuid;
  }

  get privacyConsentAccepted() {
    return this.user.privacyConsentAccepted;
  }

  get marketingConsentAccepted() {
    return this.user.marketingConsentAccepted;
  }

  get rest() {
    return produce(this.user, () => {});
  }

  get claimingBlocked() {
    return this.user.claimingBlocked;
  }
}

export default User;
