import { useNavigation } from "../hooks/useNavigation";
import { getEmberPath } from "./utils";

export const RedirectToEmber = () => {
  const { currentLocation } = useNavigation();
  const { goTo } = useNavigation();

  goTo(getEmberPath(`${currentLocation.pathname}${currentLocation.search}${currentLocation.hash}`));

  return null;
};
