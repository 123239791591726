import { createContext, ReactNode, useContext, useState } from "react";
import { sendErrorReport } from "../utils/reporting";
import useFeatureFlagsInitializer from "./useFeatureFlagsInitializer";
import useReferrerInitializer from "./useReferrerInitializer";
import useThemeInitializer from "./useThemeInitializer";
import useUserInitializer from "./useUserInitializer";
import useTrustpilotScore from "../api/react-query-hooks/useTrustpilotScore";
import { useCoralCampaign } from "../api/react-query-hooks/useCoralCampaign";
import { useInitOptimizely } from "tracking/tools/optimizely";
import tokensApi from "api/tokens";
import { useNavigation } from "../hooks/useNavigation";

type AppInitializerContextType = {
  appInitialized: boolean;
  initializeApp(): void;
};

const DEFAULT_CONTEXT = {
  appInitialized: false,
  initializeApp: () => {},
};

const AppInitializerContext = createContext<AppInitializerContextType>(DEFAULT_CONTEXT);

export const AppInitializerProvider = ({ children }: { children?: ReactNode }) => {
  const featureFlagsInitializer = useFeatureFlagsInitializer();
  const userInitializer = useUserInitializer();
  const themeInitializer = useThemeInitializer();
  const referrerInitializer = useReferrerInitializer();
  const [appInitialized, setAppInitialized] = useState(false);
  const [isAppInitializing, setIsAppInitializing] = useState(false);
  const { replaceTo } = useNavigation();

  useTrustpilotScore();
  useCoralCampaign();
  useInitOptimizely();

  const initializeApp = async () => {
    if (isAppInitializing || appInitialized) {
      return;
    }

    try {
      setIsAppInitializing(true);
      await Promise.all([
        featureFlagsInitializer(),
        userInitializer().then((user) => {
          if (user?.claimingBlocked) {
            replaceTo("/claims/error-blocked");
          }
        }),
        referrerInitializer(),
        themeInitializer(),
      ]);

      await tokensApi.fetchTokens();
    } catch (error) {
      reportInitialisationError(error);

      throw error;
    } finally {
      setAppInitialized(true);
      setIsAppInitializing(false);
    }
  };

  return (
    <AppInitializerContext.Provider
      value={{
        appInitialized,
        initializeApp,
      }}
      children={children}
    />
  );
};

const IGNORED_ERROR_MESSAGES = ["Network Error", "Request aborted"];

function reportInitialisationError(error: unknown) {
  if (error instanceof Error && IGNORED_ERROR_MESSAGES.includes(error.message)) {
    return;
  }

  sendErrorReport(error, "App initialization failed");
}

export const useAppInitializer = () => useContext(AppInitializerContext);
