import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

const { VITE_SENTRY_DSN_TOKEN, MODE } = import.meta.env;
const SUPPORTED_DOMAIN_REGEX =
  /https:\/\/(www\.)?((ahplus|auth|app|funnel|coral)(-sta)?\.)?airhelp\.com/i;
const AUTH_ALLOW_REGEX = /https:\/\/auth\.airhelp\.com(\/.*)?$/i;

export function initSentry() {
  Sentry.init({
    dsn: VITE_SENTRY_DSN_TOKEN,
    environment: MODE,
    enabled: Boolean(VITE_SENTRY_DSN_TOKEN),
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.browserApiErrorsIntegration({
        XMLHttpRequest: true,
        eventTarget: true,
      }),
      Sentry.extraErrorDataIntegration(),
      Sentry.httpClientIntegration(),
      Sentry.dedupeIntegration(),
      Sentry.replayIntegration({
        networkDetailAllowUrls: ["https://app.airhelp.com/api/barcode_leads", AUTH_ALLOW_REGEX],
        networkRequestHeaders: ["refresh_token", "access_token"],
        networkResponseHeaders: ["refresh_token", "access_token"],
        maskAllText: false,
        blockAllMedia: false,
        unmask: ["[data-sentry-unmask]"],
      }),
    ],

    // This option is required for capturing headers and cookies. Used by httpClientIntegration
    sendDefaultPii: true,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 0.1,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.05,
    replaysOnErrorSampleRate: 0.1,

    beforeBreadcrumb(breadcrumb) {
      if (breadcrumb.category === "xhr" && !breadcrumb.data?.url.match(SUPPORTED_DOMAIN_REGEX)) {
        return null;
      }

      return breadcrumb;
    },

    maxValueLength: 1000,
    ignoreErrors: [
      "Failed to fetch",
      "ttq is not defined",
      "fbq is not defined",
      "esk is not defined",
      "Can't find variable: fbq",
      "Can't find variable: ttq",
      "Can't find variable: esk",
      "window.lintrk is not a function",
      "Request aborted",
      "Failed to start the audio device",
      "timeout exceeded",
      "NotAllowedError",
      "NotAllowedError: Permission denied",
    ],
    allowUrls: [SUPPORTED_DOMAIN_REGEX, "http://localhost:6660"],
    denyUrls: [
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
      // Other
      /gtm\.js/i,
    ],
  });
}
