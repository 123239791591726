import React, { PropsWithChildren, useCallback, useContext, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { FunnelDefinition } from "./builder/FunnelDefinition";
import { calculateFirstStep, findStepById } from "./builder/stepCalculations";
import { useNavigation } from "../hooks/useNavigation";
import { clearPreEligibilitySavedSession, isPreEligibilityFunnel } from "../initializers/useSavedPreEligibilitySession";

type Definition = FunnelDefinition | (() => FunnelDefinition);

/**
 * Basic operations on FunnelDefinition provided by FunnelDefinitionContext:
 *
 * - `register` - allows to register (and start from the first step) new funnel definition. Definition will be displayed
 *     by <Funnel /> component. It is possible to provide:
 *     - `static` definition (simple `FunnelDefinition` JS object) - this definition will not change during user journey
 *     - `dynamic` definition via factory function. This definition will be recalculated on each step and can change based on data provided by user.
 *        It gives a great flexibility while creating dynamic funnel - see `GoodwillFunnelDefinition` for example.
 */

export type RegisterDefinitionOptions = {
  skipStart?: boolean;
  startFromStep?: string;
  params?: object;
};

type FunnelDefinitionContextType = {
  funnelMetadata: FunnelMetadata;
  updateFunnelMetadata: (newMetadata: Partial<FunnelMetadata>) => void;
  register(funnelDefinition: Definition, options?: RegisterDefinitionOptions): void;
  previousFunnelDefinition?: FunnelDefinition;
  funnelDefinition: FunnelDefinition;
};

type FunnelMetadata = {
  funnelName: string;
  flowName?: string;
};

type FunnelDefinitionProviderProps = PropsWithChildren<{
  definition?: FunnelDefinition;
  metadata?: FunnelMetadata;
}>;

export const FunnelDefinitionContext = React.createContext<FunnelDefinitionContextType | undefined>(
  undefined
);

const EMPTY_FUNNEL = { name: "", funnelData: {}, steps: [] };
const extractFunnel = (funnelDefinition: Definition) =>
  typeof funnelDefinition === "function" ? funnelDefinition() : funnelDefinition;

export const FunnelDefinitionProvider = ({
  children,
  definition,
  metadata,
}: FunnelDefinitionProviderProps) => {
  const [previousFunnelDefinition, setPreviousFunnelDefinition] = useState<
    FunnelDefinition | undefined
  >(undefined);
  const [funnelDefinition, setFunnelDefinition] = useState<FunnelDefinition>(
    definition ?? EMPTY_FUNNEL
  );
  const [funnelMetadata, setFunnelMetadata] = useState<FunnelMetadata>(
    metadata ?? {
      funnelName: "",
    }
  );

  const updateFunnelMetadata = useCallback(
    (newMetadata: Partial<FunnelMetadata>) =>
      setFunnelMetadata({ ...funnelMetadata, ...newMetadata }),
    [funnelMetadata, setFunnelMetadata]
  );
  const { replaceTo } = useNavigation();

  const params = useParams();
  const paramsRef = useRef(params);
  paramsRef.current = params;

  const register = useCallback(
    (
      registeredFunnelDefinition: Definition,
      options: RegisterDefinitionOptions = { params: {} }
    ) => {
      const definition = extractFunnel(registeredFunnelDefinition);

      setPreviousFunnelDefinition(funnelDefinition);
      setFunnelDefinition(definition);

      if (!isPreEligibilityFunnel(definition.name)) {
        clearPreEligibilitySavedSession();
      }

      if (options?.skipStart) {
        updateFunnelMetadata({
          funnelName: definition.name,
          flowName: definition?.flow,
        });

        if (options.startFromStep) {
          const targetStep = findStepById(definition, options.startFromStep);

          // @ts-ignore
          replaceTo(targetStep?.path);
        }

        return;
      }

      const firstStep = calculateFirstStep(definition, { ...paramsRef.current, ...options.params });
      updateFunnelMetadata({
        funnelName: definition.name,
        flowName: definition?.flow,
      });
      replaceTo(firstStep.location);
    },
    [replaceTo, updateFunnelMetadata, funnelDefinition]
  );

  return (
    <FunnelDefinitionContext.Provider
      value={{
        previousFunnelDefinition,
        funnelDefinition,
        funnelMetadata,
        updateFunnelMetadata,
        register,
      }}
    >
      {children}
    </FunnelDefinitionContext.Provider>
  );
};

export const usePreviousFunnelName = () => {
  const funnelContext = useContext(FunnelDefinitionContext);
  if (!funnelContext) {
    throw new Error("useFunnelDefinition must be used within a FunnelProvider");
  }
  return funnelContext.previousFunnelDefinition?.name;
};

export const useFunnelDefinition = () => {
  const funnelContext = useContext(FunnelDefinitionContext);
  if (!funnelContext) {
    throw new Error("useFunnelDefinition must be used within a FunnelProvider");
  }
  const { register, funnelDefinition } = funnelContext;
  const registerFunnelDefinition = useCallback(
    (funnelDefinition: Definition, options?: RegisterDefinitionOptions) =>
      register(funnelDefinition, options),
    [register]
  );

  return {
    registerFunnelDefinition,
    funnelDefinition,
  };
};

export const useFunnelMetadata = () => {
  const funnelContext = useContext(FunnelDefinitionContext);
  if (!funnelContext) {
    throw new Error("useFunnelMetadata must be used within a FunnelProvider");
  }

  return {
    funnelMetadata: funnelContext.funnelMetadata,
    updateFunnelMetadata: funnelContext.updateFunnelMetadata,
  };
};
