import { Cookies } from "react-cookie";
import { proxy, snapshot, useSnapshot } from "valtio";
import queryParams from "utils/queryParams";
import {
  Locale,
  Currency,
  mapBrowserLanguageToLocale,
  normalizeLocale,
} from "app-settings/localesAndCurrencies";
import i18n from "../../i18n";

const domain = import.meta.env.VITE_EMBER_REGULATIONS_COOKIE_DOMAIN;
const cookies = new Cookies();

const INITIAL_LANGUAGE_COOKIE_NAME = "initial_language";
const LANGUAGE_COOKIE_NAME = "user_language";
const CURRENCY_COOKIE_NAME = "user_currency";

const { lang: languageFromQueryParam } = queryParams<"lang">(window.location.search);
const languageFromCookie = cookies.get(LANGUAGE_COOKIE_NAME);
const languageFromBrowser = mapBrowserLanguageToLocale(navigator.language);

const currencyFromCookie = cookies.get(CURRENCY_COOKIE_NAME);

const rawUserLanguage = languageFromQueryParam || languageFromCookie || languageFromBrowser || "en";
const normalizedUserLanguage = normalizeLocale(rawUserLanguage);

setLanguageCookie(normalizedUserLanguage);
setInitialLanguageCookie(normalizedUserLanguage);

const DEFAULT_CURRENCY: Currency = currencyFromCookie || "EUR";
const DEFAULT_LOCALE: Locale = normalizedUserLanguage;

type AppSettings = {
  locale: Locale;
  currency: Currency;
};

const appSettings: AppSettings = {
  locale: DEFAULT_LOCALE,
  currency: DEFAULT_CURRENCY,
};

const appSettingsState = proxy(appSettings);

function setInitialLanguageCookie(locale: Locale) {
  cookies.set(INITIAL_LANGUAGE_COOKIE_NAME, locale, { path: "/", domain });
}

function setLanguageCookie(locale: Locale) {
  cookies.set(LANGUAGE_COOKIE_NAME, locale, { path: "/", domain });
}

function setCurrencyCookie(currency: Currency) {
  cookies.set(CURRENCY_COOKIE_NAME, currency, { path: "/", domain });
}

export function setAppLocale(locale: Locale) {
  appSettingsState.locale = locale;
  setLanguageCookie(locale);
  i18n.changeLanguage(locale);
}

export function setAppCurrency(currency: Currency) {
  appSettingsState.currency = currency;
  setCurrencyCookie(currency);
}

export function appSettingsSnapshot() {
  return snapshot(appSettingsState);
}

export function useAppSettingsSnapshot() {
  return useSnapshot(appSettingsState);
}
