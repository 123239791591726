import { useBrandSnapshot } from "./index";
import { useMemo } from "react";
import { extendTheme } from "@chakra-ui/react";
import { HEXToHSL, HSLtoCSS, HSLtoRGB, RGBtoHSL } from "../../utils/colors";
import { defaultFunnelTheme } from "../../../theme/funnelTheme";

const DARK_COLORS_LIGHTNESS_DELTA = [0.54, 0.46, 0.3, 0.22, 0, -0.16, -0.24, -0.2, -0.2745];
const LIGHTNESS_DELTA = [0.32, 0.26, 0.17, 0.1, 0, -0.16, -0.24, -0.2, -0.2745];
const CHAKRA_ALIASES = [100, 200, 300, 400, 500, 600, 700, 800, 900] as const;

export function useFunnelTheme() {
  const brand = useBrandSnapshot();

  return useMemo(() => {
    if (brand?.primaryColor && brand?.secondaryColor) {
      return extendTheme(defaultFunnelTheme, {
        colors: {
          primary: generatePalette(brand.primaryColor),
          secondary: generatePalette(brand?.secondaryColor),
          font: {
            primary: "#000000",
          },
        },
      });
    }
    if (brand?.primaryColor) {
      return extendTheme(defaultFunnelTheme, {
        colors: {
          primary: generatePalette(brand.primaryColor),
          font: {
            primary: "#000000",
          },
        },
      });
    }
    return defaultFunnelTheme;
  }, [brand?.primaryColor, brand?.secondaryColor]);
}

function getLightnessDelta(lightness: number) {
  if (lightness <= 0.5) {
    return DARK_COLORS_LIGHTNESS_DELTA;
  }

  return LIGHTNESS_DELTA;
}

export function generatePalette(color: string): {
  [_p in (typeof CHAKRA_ALIASES)[number]]?: string;
} {
  const { H: baseH, S: baseS, L: baseL } = HEXToHSL(color);
  const lightnessDelta = getLightnessDelta(baseL);

  const preparedColors = lightnessDelta.map((lightnessCorrection) => {
    const lightness = baseL + lightnessCorrection;
    const modifiedLightness = Math.min(lightness, 0.98);

    const modifiedRGB = HSLtoRGB({ H: baseH, S: baseS, L: modifiedLightness });
    const modifiedHSL = RGBtoHSL(modifiedRGB);

    return HSLtoCSS(modifiedHSL);
  });

  return preparedColors.reduce((acc, color, index) => {
    return {
      ...acc,
      [CHAKRA_ALIASES[index]]: color,
    };
  }, {});
}
