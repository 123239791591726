import { webappClient } from "../clients";
import z from "zod";
import { validateResponse } from "../validation";

export const fetchBrandResponseSchema = z.object({
  brand: z.object({
    site_url: z.string().optional(),
    logo_url: z.string(),
    name: z.string(),
    logo_present_in_webapp: z.boolean(),
    logo_width: z.number(),
    primary_color: z.string(),
    secondary_color: z.string(),
    is_expedia: z.boolean(),
    is_egencia: z.boolean(),
    slug: z.string(),
    reload_pii: z.boolean(),
  }),
});

export async function fetchBrand(name: string) {
  const data = await webappClient.get(`api/brands/${name}`).json();
  const { brand } = validateResponse({
    data,
    schema: fetchBrandResponseSchema,
    tag: `Brand API response for brand ${name}`,
  });

  return brand;
}
