import queryString from "query-string";

type QueryParams<AvailableKeys extends string = any> = {
  [_param in AvailableKeys]: string | undefined;
};

const queryParams = <Keys extends string = any>(search: string) => {
  const parsedQuery = queryString.parse(search);
  return Object.entries(parsedQuery).reduce(
    (acc, el) => ({ [el[0]]: Array.isArray(el[1]) ? el[1][0] : el[1], ...acc }),
    {},
  ) as QueryParams<Keys>;
};

export default queryParams;
