import { Currency, Locale } from "app-settings/localesAndCurrencies";

export function toCurrency(amount: number, currency: Currency, locale: Locale) {
  return new Intl.NumberFormat(locale, {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    style: "currency",
    currency,
  }).format(amount);
}
