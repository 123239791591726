import { Cookies } from "react-cookie";
import { deserialize, serialize } from "class-transformer";
import { ClaimEnquiryRest } from "../api/model/ClaimEnquiryRest";
import { defaultQueryClient } from "../config";
import { AIRHELP_GLOBAL_COOKIE_DOMAIN } from "../constants/cookies";
import { useQuery } from "@tanstack/react-query";
import { PRE_ELIGIBILITY_BARCODE_FUNNEL_NAME } from "../funnels/pre-eligibility/barcode/builder/PreEligibilityBarcodeFunnelBuilder";
import { PRE_ELIGIBILITY_FUNNEL_NAME } from "../funnels/pre-eligibility/default/builder/PreEligibilyFunnelBuilder";
import { sendWarningReport } from "../utils/reporting";

const PRE_ELIGIBILITY_SESSION_STORAGE_KEY = "ah-saved-pre-eligibility-session";
const PRE_ELIGIBILITY_SESSION_QUERY_KEY = ["saved-pre-eligibility-session"];
const PRE_ELIGIBILITY_SESSION_COOKIE = "ah-saved-pre-eligibility-session-exists";
const PRE_ELIGIBILITY_SESSION_COOKIE_EXPIRATION = 2 * 30 * 24 * 60 * 60 * 1000; // 2 months

const cookies = new Cookies();

interface PreEligibilitySession {
  claimEnquiry: ClaimEnquiryRest;
  funnel: string;
  step: string;
  flow?: string;
}

export function useSavedPreEligibilitySession() {
  const { data: session } = useQuery<PreEligibilitySession | null>({
    queryKey: PRE_ELIGIBILITY_SESSION_QUERY_KEY,
    queryFn: undefined,
    enabled: false,
  });

  return session ?? null;
}

export function rememberPreEligibilitySession({
  claimEnquiry,
  funnel,
  step,
  flow,
}: PreEligibilitySession) {
  try {
    localStorage.setItem(
      PRE_ELIGIBILITY_SESSION_STORAGE_KEY,
      JSON.stringify({
        claimEnquiry: serialize(claimEnquiry, { ignoreDecorators: true, groups: ["native"] }),
        funnel,
        step,
        flow,
      }),
    );
    cookies.set(PRE_ELIGIBILITY_SESSION_COOKIE, "true", {
      path: "/",
      domain: AIRHELP_GLOBAL_COOKIE_DOMAIN,
      expires: new Date(Date.now() + PRE_ELIGIBILITY_SESSION_COOKIE_EXPIRATION),
    });
  } catch (e) {
    sendWarningReport(
      "Failed to save the pre-eligibility session for returning user, " +
        "a null value will be returned instead",
      e,
    );
  }
}

export function rememberPreEligibilitySessionMetadata({
  funnel,
  step,
  flow,
}: Pick<PreEligibilitySession, "funnel" | "flow" | "step">) {
  try {
    localStorage.setItem(
      PRE_ELIGIBILITY_SESSION_STORAGE_KEY,
      JSON.stringify({
        funnel,
        step,
        flow,
      }),
    );
  } catch (e) {
    sendWarningReport(
      "Failed to save the pre-eligibility session metadata for returning user, " +
        "a null value will be returned instead",
      e,
    );
  }
}

export async function loadSavedPreEligibilitySession() {
  return await defaultQueryClient.fetchQuery({
    queryKey: PRE_ELIGIBILITY_SESSION_QUERY_KEY,
    queryFn: getSavedPreEligibilitySession,
    staleTime: Infinity,
  });
}

export function clearPreEligibilitySavedSession() {
  try {
    localStorage.removeItem(PRE_ELIGIBILITY_SESSION_STORAGE_KEY);
    cookies.remove(PRE_ELIGIBILITY_SESSION_COOKIE, {
      path: "/",
      domain: AIRHELP_GLOBAL_COOKIE_DOMAIN,
    });
  } catch (e) {
    sendWarningReport(
      "Failed to clear the saved pre-eligibility session metadata for returning user",
      e,
    );
  }
}

function getSavedPreEligibilitySession(): PreEligibilitySession | null {
  let data;

  try {
    data = localStorage.getItem(PRE_ELIGIBILITY_SESSION_STORAGE_KEY);
  } catch {
    return null;
  }

  if (!data) {
    return null;
  }

  const { funnel, step, flow, claimEnquiry } = JSON.parse(data);

  try {
    return {
      funnel,
      step,
      flow,
      claimEnquiry: deserialize(ClaimEnquiryRest, claimEnquiry, {
        ignoreDecorators: true,
        groups: ["native"],
      }),
    };
  } catch (e) {
    sendWarningReport(
      "Failed to get saved pre-eligibility session, a null value will be returned instead",
      e,
    );
    return null;
  }
}

export function getSavedPreEligibilitySessionMetadata(): Pick<
  PreEligibilitySession,
  "funnel" | "flow" | "step"
> | null {
  let data;

  try {
    data = localStorage.getItem(PRE_ELIGIBILITY_SESSION_STORAGE_KEY);
  } catch {
    return null;
  }

  if (!data) {
    return null;
  }

  const { funnel, step, flow } = JSON.parse(data);

  return {
    funnel,
    step,
    flow,
  };
}

export function isPreEligibilityFunnel(name: string) {
  return [PRE_ELIGIBILITY_BARCODE_FUNNEL_NAME, PRE_ELIGIBILITY_FUNNEL_NAME].includes(name);
}
