import { Collapsible } from "@airhelp/react";
import { Flex } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import PoweredByLogo from "../../../components/ui-elements/powered-by/PoweredByLogo";
import FooterBrandDisclaimer from "../brand-disclaimer/FooterBrandDisclaimer";
import FooterLinks from "../links/FooterLinks";
import styles from "./FooterContent.module.scss";

const OtaFooterContent = () => {
  const { t } = useTranslation();
  const year = new Date().getFullYear();

  return (
    <Flex direction="column" borderBottom="1px solid" borderColor="greyscale.400">
      <Flex borderBottom="1px solid" borderColor="greyscale.400">
        <Collapsible title={t("footer.more_info")} fontWeight="normal" fontSize="xs" w="100%" p={0}>
          <Flex direction="column" gap={5} pt={3} pb={3}>
            <FooterLinks className={styles.otaLinks} />
            <FooterBrandDisclaimer className={styles.otaDisclaimer} />
          </Flex>
        </Collapsible>
      </Flex>
      <Flex align="center" justify="space-between" p={4}>
        <PoweredByLogo className={styles.poweredByLogo} />
        <div className={styles.copyrightSection}>{t("footer.copyrights", { year })}</div>
      </Flex>
    </Flex>
  );
};

export default OtaFooterContent;
