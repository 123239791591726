import { immerable } from "immer";
import { Expose } from "class-transformer";
import { fromJson, toJson } from "./json";

export type FellowPassengerRestWrapper = {
  fellow_passenger: FellowPassengerRest;
};

export class FellowPassengerRest {
  [immerable] = true;

  @Expose() id?: number;
  @Expose() firstName: string;
  @Expose() lastName: string;
  @Expose() minor: boolean;
  @Expose() email: string;

  internalId?: number;

  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  toJson(claimEnquiryId?: number) {
    return {
      claim_enquiry_id: claimEnquiryId,
      ...toJson(this),
    };
  }

  static fromJson(fellowPassenger: any) {
    return fromJson(FellowPassengerRest, fellowPassenger);
  }
}
