import camelcaseKeys from "camelcase-keys";
import { ClassConstructor, classToPlain, plainToClass } from "class-transformer";
import { ClassTransformOptions, TransformFnParams } from "class-transformer/types/interfaces";
import { format, parseISO } from "date-fns";
import snakecaseKeys from "snakecase-keys";

export const DATE_TO_PLAIN_TRANSFORMER = ({ value }: TransformFnParams) =>
  value ? format(value, "dd-MM-yyyy") : undefined;
export const PLAIN_TO_DATE_TRANSFORMER = ({ value }: TransformFnParams) =>
  value ? parseISO(value) : undefined;

const PLAIN_TO_CLASS_OPTIONS: ClassTransformOptions = {
  strategy: "excludeAll",
  excludeExtraneousValues: true,
  groups: ["api"],
};
const CLASS_TO_PLAIN_OPTIONS: ClassTransformOptions = {
  strategy: "excludeAll",
  excludeExtraneousValues: true,
  groups: ["api"],
};

export const fromJson = <T>(
  ObjectClass: ClassConstructor<T>,
  value: Partial<T>,
  options: ClassTransformOptions = {}
): T => {
  if (!value) {
    return new ObjectClass();
  }

  const plain = camelcaseKeys(value, { deep: true });
  return plainToClass(ObjectClass, plain, { ...PLAIN_TO_CLASS_OPTIONS, ...options });
};

export const toJson = <T>(value: T) =>
  snakecaseKeys(classToPlain(value, CLASS_TO_PLAIN_OPTIONS), { deep: true });
