import React, { useCallback, useContext, useState } from "react";

export type ProgressBarSegment =
  | "eligibility_check"
  | "additional_information"
  | "documents"
  | "finish";

type ProgressBarContextType = {
  segment: ProgressBarSegment;
  activateSegment(providedSegment: ProgressBarSegment): void;
};

const defaultSegment: ProgressBarSegment = "eligibility_check";
const DEFAULT_CONTEXT = { segment: defaultSegment, activateSegment: () => {} };

const ProgressBarContext = React.createContext<ProgressBarContextType>(DEFAULT_CONTEXT);

export const ProgressBarProvider = ({ children }: { children?: React.ReactNode }) => {
  const [segment, setSegment] = useState<ProgressBarSegment>(defaultSegment);

  const activateSegment = useCallback(
    (providedSegment: ProgressBarSegment) => setSegment(providedSegment),
    [setSegment],
  );

  return (
    <ProgressBarContext.Provider value={{ segment, activateSegment }}>
      {children}
    </ProgressBarContext.Provider>
  );
};

export const useProgressBar = () => {
  const progressBarContext = useContext(ProgressBarContext);
  if (!progressBarContext) {
    throw new Error("useFunnel must be used within a FunnelProvider");
  }

  return {
    segment: progressBarContext.segment,
    activateSegment: progressBarContext.activateSegment,
  };
};
