import { fetchAirport } from "../../../api/airports";
import {
  groupItineraryFlights,
  updateFlightDetails,
  updateItineraryFlightByIndex,
  updateItineraryFlights,
} from "../state/preEligibilityState";
import { extractAirportData } from "../../../modules/serializers/intake-funnel";

function getIataFromQueryParams() {
  const params = new URLSearchParams(window.location.search);

  const departureAirportIata = params.get("departureAirportIata");
  const arrivalAirportIata = params.get("arrivalAirportIata");
  const connectingAirportsIatas = params.get("connectingAirportsIatas")?.split(",") || [];

  return { departureAirportIata, arrivalAirportIata, connectingAirportsIatas };
}

async function fetchAirportsFromIata() {
  const { arrivalAirportIata, departureAirportIata, connectingAirportsIatas } =
    getIataFromQueryParams();

  const connections = departureAirportIata && arrivalAirportIata ? connectingAirportsIatas : [];

  const fetchPromises = [departureAirportIata, arrivalAirportIata, ...connections].map((iata) =>
    iata ? fetchAirport(iata).catch(() => null) : null,
  );

  const results = await Promise.allSettled(fetchPromises);

  return results.map((result) => (result.status === "fulfilled" ? result.value : null));
}

export async function loadAirportsFromQueryParams() {
  const [rawDepartureAirport, rawArrivalAirport, ...rawConnectingAirports] =
    await fetchAirportsFromIata();

  const departureAirport = rawDepartureAirport
    ? extractAirportData(rawDepartureAirport)
    : undefined;
  const arrivalAirport = rawArrivalAirport ? extractAirportData(rawArrivalAirport) : undefined;

  if (departureAirport || arrivalAirport) {
    updateItineraryFlightByIndex(0, { departureAirport, arrivalAirport });
  }

  const connectingAirports = rawConnectingAirports
    .filter((airport): airport is NonNullable<typeof airport> => !!airport)
    .map((rawAirport) => ({ airport: extractAirportData(rawAirport) }));

  if (departureAirport && arrivalAirport && connectingAirports.length > 0) {
    updateFlightDetails({ anyConnections: true, connections: connectingAirports });

    const groupedItineraryFlights = groupItineraryFlights({
      departureAirport,
      arrivalAirport,
      connections: connectingAirports,
    });

    updateItineraryFlights(groupedItineraryFlights);
  }
  return { departureAirport, arrivalAirport };
}
