import { flyTranquiUrl } from "constants/linksPaths";
import { useBrand } from "../../../brand/BrandContext";
import Logotype from "../../../components/ui-elements/logotype/Logotype";
import styles from "./HeaderLogo.module.scss";
import AirHelpLogo from "./airhelp-logo/AirHelpLogo";

const HeaderLogo = () => {
  const brand = useBrand();
  const siteUrl = brand?.isFlyTranqui ? flyTranquiUrl : brand?.siteUrl;
  return (
    <div
      className={styles.headerLogo}
      style={{ width: brand?.logoWidth }}
      data-testid={brand ? "brand-logo" : "logo"}
    >
      {brand?.logoPresentInWebapp ? (
        <Logotype
          logoSrc={brand.logoUrl}
          href={siteUrl}
          dataTestId="logo-brand"
          brandName={brand.slug}
          logoWidth={brand.logoWidth}
        />
      ) : (
        <AirHelpLogo />
      )}
    </div>
  );
};

export default HeaderLogo;
