import { HTTPError } from "ky";

export function isHttpError(error: unknown): error is HTTPError {
  return error instanceof HTTPError;
}

export function isAuthError(error: unknown): error is HTTPError {
  return (
    error instanceof HTTPError && (error.response.status === 401 || error.response.status === 403)
  );
}
