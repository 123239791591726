import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { Cookies } from "react-cookie";
import i18n from "../i18n";
import {
  Currency,
  Locale,
  locales,
  normalizeLocale,
  mapBrowserLanguageToLocale,
} from "./localesAndCurrencies";
import queryParams from "../utils/queryParams";

const domain = import.meta.env.VITE_EMBER_REGULATIONS_COOKIE_DOMAIN;
const cookies = new Cookies();

export type AppSettingsContextType = {
  currency: Currency;
  locale: Locale;
  setCurrency(value: Currency): void;
  setLocale(value: Locale): void;
};

const AppSettingsContext = createContext<AppSettingsContextType | undefined>(undefined);

export const AppSettingsProvider = ({ children }: { children?: React.ReactNode }) => {
  const { lang: langFromQueryParams } = queryParams<"lang">(window.location.search);
  const browserPreferredLanguage = navigator.language;
  const langFromCookies = cookies.get("user_language");
  const langFromBrowser = mapBrowserLanguageToLocale(browserPreferredLanguage);

  const normalizedLocale = normalizeLocale(
    langFromQueryParams || langFromCookies || langFromBrowser || "en",
  );

  if (!cookies.get("initial_language")) {
    cookies.set("initial_language", normalizedLocale);
  }

  const DEFAULT_CURRENCY = cookies.get("user_currency") || "EUR";

  const [locale, setLocale] = useState<Locale>(normalizedLocale);
  const [currency, setCurrency] = useState<Currency>(DEFAULT_CURRENCY);

  useEffect(() => {
    i18n.changeLanguage(locale);
    cookies.set("user_language", locale, { path: "/", domain });
  }, [locale]);

  useEffect(() => {
    cookies.set("user_currency", currency, { path: "/", domain });
  }, [currency]);

  const setNormalizedLocale = useCallback(
    (lang: string) => {
      const locale = normalizeLocale(lang);
      if (locales[locale]) {
        setLocale(locale);
      }
    },
    [setLocale],
  );

  const value = {
    locale,
    setLocale: setNormalizedLocale,
    currency,
    setCurrency,
  };

  return <AppSettingsContext.Provider value={value}>{children}</AppSettingsContext.Provider>;
};

export const useAppSettings = (): AppSettingsContextType => {
  const context = useContext(AppSettingsContext);
  if (!context) {
    throw new Error("useAppSettings must be used within a AppSettingsProvider");
  }

  return context;
};
