import { UpdateFunction } from "../../UpdateFunction";
import { ClaimEnquiryRest } from "../../../api/model/ClaimEnquiryRest";

export type FunnelDocumentType =
  | "boarding_pass"
  | "eticket_or_booking_confirmation"
  | "passport_or_id"
  | "proof_of_address_for_main_claiment"
  | "ah_plus_proof"
  | "extra_expenses_proof"
  | "eticket_for_all_passengers"
  | "eticket_for_rerouting"
  | "proof_of_delay"
  | "profession_of_all_passengers"
  | "invoice_or_proof_of_payment"
  | "other";

type FunnelDocument = {
  file: File;
  passengerId?: number;
};

export type DocumentsRaw = { [_key in FunnelDocumentType]?: FunnelDocument[] };

export class Documents {
  constructor(
    public documents: DocumentsRaw,
    private updateEnquiry: UpdateFunction<ClaimEnquiryRest>,
  ) {}

  get(documentType: FunnelDocumentType, passengerId?: number) {
    const documents = this.documents[documentType] || [];
    if (passengerId) {
      return documents.filter((d) => d.passengerId === passengerId);
    }

    return documents;
  }

  set(documents: FunnelDocument[] = [], documentType: FunnelDocumentType) {
    this.updateEnquiry((draft) => {
      draft.documents[documentType] = documents;
    });
  }
}
