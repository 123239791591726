import User from "../../../model/user/User";
import hashing from "../../utils/hashing";
import { camelize } from "../../utils/string";
import { analyticsGA4 } from "../../analytics";
import { useAppSettings } from "../../../app-settings/AppSettingsContext";
import { useCallback } from "react";
import { Currency, Locale } from "../../../app-settings/localesAndCurrencies";
import { useUser } from "../../../model/user/UserContext";
import { useBrand } from "../../../brand/BrandContext";
import { getUtmParams } from "../../getUtmParams";
import { getFunnelReferrer } from "../../../utils/referrer";
import { sessionTime } from "../../utils/sessionTime";
import {
  ClaimEnquirySnapshot,
  useClaimEnquiryExternalStore,
} from "../../../model/claim-enquiry/ClaimEnquiryContext";
import { PageViewed } from "../events/pageViewed";

interface PageViewedEventProperties {
  previousPageUrl: string;
  funnelType: string;
}

const pathsBeforeUuid: string[] = ["claim_enquiries", "verify"];

const hasPageTypeFormat = (pathname: string) => pathname.match(/^[a-z-]+$/);

export const removeUuidFromPath = (splitPath: string[]) => {
  pathsBeforeUuid.forEach((pathBeforeUuid) => {
    const pathBeforeUuidIndex = splitPath.findIndex((path) => path === pathBeforeUuid);
    if (pathBeforeUuidIndex !== -1) splitPath.splice(pathBeforeUuidIndex + 1, 1);
  });
};

export const createPageType = () => {
  const pathname = window.location.pathname;
  const splitPathname = pathname.split("/");

  if (pathname.startsWith("/ota")) {
    const lastPath = splitPathname[splitPathname.length - 1];
    if (hasPageTypeFormat(lastPath)) {
      return `OtaLead${camelize(lastPath, true)}`;
    }

    return "OtaLead";
  }

  removeUuidFromPath(splitPathname);

  return splitPathname.map((s) => camelize(s, true)).join("");
};

const appInfo = (currency: Currency, locale: Locale) => {
  return {
    page_language: locale,
    page_currency: currency,
  };
};

export const userInfo = (user: User, claimEnquiry: ClaimEnquirySnapshot) => {
  const email = user.email;
  const phoneNumber = claimEnquiry?.contact?.phoneNumber.number;
  const firstName = claimEnquiry?.contact?.firstName;
  const lastName = claimEnquiry?.contact?.lastName;
  const city = claimEnquiry?.contact?.address?.city;
  const countryCode = claimEnquiry?.contact?.address?.countryCode;
  const postalCode = claimEnquiry?.contact?.address?.zipCode;

  return {
    is_logged: user.isLoggedIn,
    user_id: user.id?.toString(),
    hashed_email_id: email && hashing.sha256(email),
    user_type: claimEnquiry?.isEligible ? "Eligible" : "Ineligible",
    user_city: claimEnquiry?.contact?.address?.city,
    hashed_user_city: city && hashing.sha256(city),
    user_postal_code: claimEnquiry?.contact?.address?.zipCode,
    hashed_user_postal_code: postalCode && hashing.sha256(postalCode),
    user_country: claimEnquiry?.contact?.address?.countryCode,
    hashed_user_country: countryCode && hashing.sha256(countryCode),
    hashed_user_phone_number: phoneNumber && hashing.sha256(phoneNumber),
    hashed_user_first_name: firstName && hashing.sha256(firstName),
    hashed_user_last_name: lastName && hashing.sha256(lastName),
  };
};

export const disruptedFlightData = (snapshot: ClaimEnquirySnapshot) => {
  const disruptedFlight = snapshot?.disruptedFlight;
  return {
    disrupted_airline: disruptedFlight?.airline?.airlineIdentifier,
    disruption_segment_destination: disruptedFlight?.departureAirport?.iata,
    disruption_segment_source: disruptedFlight?.arrivalAirport?.iata,
    trip_date: disruptedFlight?.departureTime,
    trip_departure: disruptedFlight?.departureAirport?.iata,
    trip_destination: disruptedFlight?.arrivalAirport?.iata,
  };
};

export const claimEnquiryData = (claimEnquiry: ClaimEnquirySnapshot) => ({
  claim_id: claimEnquiry?.claimId,
  regulation: claimEnquiry?.processingRegulation,
  disruption_type: claimEnquiry?.claimType,
  claim_type: claimEnquiry?.claimType,
  delayed_flight_disruption_reason: claimEnquiry?.airlineReason,
  denied_boarding_reservation_surrendered: claimEnquiry?.voluntarilySurrendered ? "Y" : "N",
  denied_boarding_total_delay: claimEnquiry?.delay,
  fellow_passengers: claimEnquiry?.fellowPassengers?.length,
  flight_type:
    claimEnquiry?.itineraryFlights && claimEnquiry?.itineraryFlights?.length > 1
      ? "connection"
      : "direct",
  is_on_hold: claimEnquiry?.isEligibleOnHold ? 0 : 1,
  cancelled_flight_disruption_reason: claimEnquiry?.isCancelled ? claimEnquiry?.airlineReason : "",
  cancelled_flight_total_delay: claimEnquiry?.isCancelled ? claimEnquiry?.delay : "",
  delayed_flight_total_delay: claimEnquiry?.isDelayed ? claimEnquiry?.delay : "",
  claim_revenue: claimEnquiry?.compensationDetails?.revenue || 0,
});

const compensationData = (claimEnquiry: ClaimEnquirySnapshot) => ({
  service_fee: claimEnquiry?.compensationDetails?.serviceFeeAmount || 0,
  total_compensation: claimEnquiry?.compensationDetails?.totalPassengerCompensationAmount || 0,
});

export const useBuildPageViewedProperties = () => {
  const { user } = useUser();
  const { currency, locale } = useAppSettings();
  const { claimEnquiry } = useClaimEnquiryExternalStore();
  const utmParams = getUtmParams();
  const brand = useBrand();
  const referrer = getFunnelReferrer();

  return useCallback(
    (properties: PageViewedEventProperties): PageViewed => {
      return {
        event: "page_view",
        process: "Claim",
        page_referrer: properties.previousPageUrl || document.referrer,
        claim_referrer: referrer,
        ota_brand: brand?.slug,
        transaction_source: utmParams?.source,
        funnel_type: properties?.funnelType,
        page_type: createPageType(),
        total_time_claim: sessionTime.getDuration(),
        business_type: brand?.slug || claimEnquiry?.isOta() ? "B2B" : "B2C",
        ...appInfo(currency, locale),
        ...userInfo(user, claimEnquiry),
        ...claimEnquiryData(claimEnquiry),
        ...disruptedFlightData(claimEnquiry),
        ...compensationData(claimEnquiry),
      };
    },
    [brand, currency, locale, referrer, user, utmParams, claimEnquiry],
  );
};

export const usePageViewedEventV2 = () => {
  const eventProperties = useBuildPageViewedProperties();

  return useCallback(
    (properties: PageViewedEventProperties) => {
      analyticsGA4.track("page_view", eventProperties(properties));
    },
    [eventProperties],
  );
};
