import { produce } from "immer";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import ClaimEnquiry from "./ClaimEnquiry";
import { ClaimEnquiryRest } from "../../api/model/ClaimEnquiryRest";
import { proxy, useSnapshot } from "valtio";

type UpdateClaimEnquiry = (
  updateEnquiryFunction: (draft: ClaimEnquiryRest) => void,
) => ClaimEnquiryRest;

export type CreateClaimEnquiry = (
  raw: ClaimEnquiryRest,
  update: UpdateClaimEnquiry,
) => ClaimEnquiry;

const ClaimEnquiryContext = React.createContext<ClaimEnquiryRest | undefined>(undefined);

const ClaimEnquiryUpdateContext = React.createContext<
  { updateClaimEnquiry: UpdateClaimEnquiry; createClaimEnquiry: CreateClaimEnquiry } | undefined
>(undefined);

const claimEnquiryExternalStore = proxy<{ claimEnquiry?: ClaimEnquiry }>({
  claimEnquiry: undefined,
});

export function useClaimEnquiryExternalStore() {
  return useSnapshot(claimEnquiryExternalStore);
}

export type ClaimEnquirySnapshot = ReturnType<typeof useClaimEnquiryExternalStore>["claimEnquiry"];

type ClaimEnquiryProviderProps = {
  initialClaimEnquiry: ClaimEnquiryRest;
  createClaimEnquiry: CreateClaimEnquiry;
  children: React.ReactNode;
};

const ClaimEnquiryProvider = ({
  children,
  createClaimEnquiry,
  initialClaimEnquiry,
}: ClaimEnquiryProviderProps) => {
  const [claimEnquiry, setClaimEnquiry] = useState<ClaimEnquiryRest>(initialClaimEnquiry);

  const updateClaimEnquiry = useCallback(
    (updateEnquiryFunction: any) => {
      const newClaimEnquiry = produce(claimEnquiry, updateEnquiryFunction);
      setClaimEnquiry(newClaimEnquiry);
      return newClaimEnquiry;
    },
    [claimEnquiry],
  );

  useEffect(() => {
    claimEnquiryExternalStore.claimEnquiry = createClaimEnquiry(claimEnquiry, updateClaimEnquiry);
  }, [claimEnquiry, createClaimEnquiry, updateClaimEnquiry]);

  return (
    <ClaimEnquiryContext.Provider value={claimEnquiry}>
      <ClaimEnquiryUpdateContext.Provider value={{ updateClaimEnquiry, createClaimEnquiry }}>
        {children}
      </ClaimEnquiryUpdateContext.Provider>
    </ClaimEnquiryContext.Provider>
  );
};

export const useClaimEnquiryRest = () => {
  const claimEnquiry = useContext(ClaimEnquiryContext);

  if (!claimEnquiry) {
    throw new Error("Claim enquiry context cannot be used until it is initialised");
  }

  return claimEnquiry;
};

const useClaimEnquiry = () => {
  const claimEnquiryRest = useClaimEnquiryRest();
  const claimEnquiryContext = useContext(ClaimEnquiryUpdateContext);

  if (!claimEnquiryContext) {
    throw new Error("Claim enquiry context cannot be used until it is initialised");
  }

  const { updateClaimEnquiry, createClaimEnquiry } = claimEnquiryContext;
  const claimEnquiry = useMemo(() => {
    const enquiry = claimEnquiryRest
      ? createClaimEnquiry(claimEnquiryRest, updateClaimEnquiry!)
      : undefined;

    return enquiry;
  }, [claimEnquiryRest, updateClaimEnquiry, createClaimEnquiry]);

  if (!claimEnquiry) {
    throw new Error("useClaimEnquiry must be used within a ClaimEnquiryProvider");
  }

  return claimEnquiry as ClaimEnquiry;
};

export { ClaimEnquiryProvider, useClaimEnquiry };
