import { Currency } from "../../app-settings/localesAndCurrencies";
import { feePercentage } from "../../constants/compensation";
import { ClaimEnquiryRest } from "../../api/model/ClaimEnquiryRest";

class CompensationDetails {
  constructor(private claimEnquiry: ClaimEnquiryRest) {}

  getRoundedCompensation(currency?: Currency) {
    const compensation = this.getCompensation(currency);

    return Math.round(Number(compensation)) || 0;
  }

  getCompensation(currency?: Currency) {
    if (currency) {
      return this.claimEnquiry.compensationInCurrencies[currency];
    }

    return this.claimEnquiry.compensation;
  }

  get totalPassengerCompensationAmount() {
    return this.totalItineraryCompensationAmount - this.totalServiceFeeAmount;
  }

  get totalServiceFeeAmount() {
    return (this.totalItineraryCompensationAmount * feePercentage) / 100;
  }

  get totalItineraryCompensationAmount() {
    return this.itineraryCompensation * (1 + (this.claimEnquiry.fellowPassengers?.length || 0));
  }

  get revenue() {
    return this.claimEnquiry.revenue;
  }

  get marketingServiceFeePercentage() {
    return this.claimEnquiry.marketingServiceFeePercentage;
  }

  get serviceFeeAmount() {
    return (this.itineraryCompensation * feePercentage) / 100;
  }

  private get itineraryCompensation() {
    return this.claimEnquiry.itineraryCompensation || 0;
  }
}

export default CompensationDetails;
