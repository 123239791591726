import { Locale } from "../app-settings/localesAndCurrencies";
import axios from "./axios";

export type ReferralSource = "webapp:rejected-path" | "webapp:referral-step";

export const CORAL_URL = import.meta.env.VITE_CORAL_URL;
export const DEFAULT_REFERRAL_EURO_AMOUNT = 10;
export const DEFAULT_REFERRAL_CAMPAIGN_ID = 1;
export const DEFAULT_CORAL_CAMPAIGN: CoralCampaignResponse = {
  id: DEFAULT_REFERRAL_CAMPAIGN_ID,
  commission_value: DEFAULT_REFERRAL_EURO_AMOUNT,
};
const coralProductType = "claim";

async function registerClaimCommission(claimId: number, shareCode: string) {
  return axios.post(
    "/api/commissions",
    {
      product_type: coralProductType,
      product_id: claimId.toString(),
      share_code: shareCode,
    },
    { withCredentials: false, baseURL: CORAL_URL },
  );
}

async function fetchShareLink(
  language: Locale,
  email: string,
  source: ReferralSource,
  coralCampaignId: number,
) {
  const response = await axios.post<{ share_url: string }>(
    "/api/users",
    {
      campaign_id: coralCampaignId,
      email,
      language,
      source,
    },
    { withCredentials: false, baseURL: CORAL_URL },
  );

  return response.data.share_url;
}

async function getCampaign() {
  const { data } = await axios.get<CoralCampaignResponse>("/api/campaigns/webapp", {
    withCredentials: false,
    baseURL: CORAL_URL,
  });

  return data;
}

export interface CoralCampaignResponse {
  id: number;
  commission_value: number;
}

const coralApi = {
  registerClaimCommission,
  fetchShareLink,
  getCampaign,
};

export default coralApi;
