import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { sendWarningReport } from "../utils/reporting";
import authApi from "./auth";
import { isInvalidAuthenticityToken } from "../utils/api";
import tokensApi from "./tokens";
import resolveAppUrl from "utils/resolveAppUrl";
import { generateXRequestId } from "v2/api/clients";

const webappBaseURL = resolveAppUrl(
  window.location.origin,
  import.meta.env.VITE_WEBAPP_BASE_URI || "",
);

const ahplusApiURL = resolveAppUrl(
  window.location.origin,
  import.meta.env.VITE_AHPLUS_API_URL || "/api-ahplus",
);

const airspaceBaseURL = import.meta.env.VITE_WEBAPP_BASE_URI || "";

export const axiosExternal = axios.create({
  baseURL: airspaceBaseURL,
});

const axiosConfig = {
  withCredentials: true,
  headers: {
    "X-Caller": "webapp-funnel",
    "X-Requested-With": "XMLHttpRequest",
  },
};

const axiosInstance = axios.create({ ...axiosConfig, baseURL: webappBaseURL });

export const axiosAhplusInstance = axios.create({ ...axiosConfig, baseURL: ahplusApiURL });

export const axiosCleanInstance = axios.create({ ...axiosConfig, baseURL: webappBaseURL });

const authBaseUrl = resolveAppUrl(
  window.location.origin,
  import.meta.env.VITE_AUTH_URL || "/api-auth",
);

export const axiosAuthInstance = axios.create({ ...axiosConfig, baseURL: authBaseUrl });

const getCSRFTokenMetaTag = () => document.querySelector('meta[name="csrf-token"]');

const setupCsrfToken = (token: string | undefined): void => {
  if (token) {
    const csrfMetaTag = getCSRFTokenMetaTag();

    if (csrfMetaTag) {
      csrfMetaTag.setAttribute("content", token);
    }
  }
};

function addCSRFTokenInterceptor(instance: AxiosInstance) {
  instance.interceptors.request.use((config) => {
    const csrfToken = getCSRFTokenMetaTag()?.getAttribute("content");

    if (config.headers && csrfToken) {
      config.headers["X-CSRF-Token"] = csrfToken;
    }

    return config;
  });
}

function addXRequestIdInterceptor(instance: AxiosInstance) {
  instance.interceptors.request.use((config) => {
    if (config.headers) {
      config.headers["X-Request-ID"] = generateXRequestId();
    }
    return config;
  });
}

addXRequestIdInterceptor(axiosInstance);
addXRequestIdInterceptor(axiosAhplusInstance);
addXRequestIdInterceptor(axiosCleanInstance);

addCSRFTokenInterceptor(axiosInstance);
addCSRFTokenInterceptor(axiosAhplusInstance);
addCSRFTokenInterceptor(axiosCleanInstance);

function addCSRFResponseInterceptor(instance: AxiosInstance) {
  instance.interceptors.response.use(
    (response) => {
      if (
        response.headers["cache-control"]?.includes("no-store") ||
        response.headers["Cache-Control"]?.includes("no-store")
      ) {
        setupCsrfToken(response.headers["x-csrf-token"]);
      }

      return response;
    },
    (error: AxiosError) => {
      if (!error.response && !error.message.includes("Network Error")) {
        sendWarningReport("Network or back button error on request", error);
      }

      if (error.response?.headers?.["x-csrf-token"]) {
        setupCsrfToken(error.response.headers["x-csrf-token"]);
      }

      return Promise.reject(error);
    },
  );
}

addCSRFResponseInterceptor(axiosInstance);
addCSRFResponseInterceptor(axiosAhplusInstance);
addCSRFResponseInterceptor(axiosCleanInstance);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error: AxiosError) {
    const originalRequest: CustomAxiosRequestConfig | undefined = error.config;
    if (isInvalidAuthenticityToken(error) && originalRequest && !originalRequest._retry) {
      originalRequest._retry = true;
      sendWarningReport("Invalid authenticity token", error);
      return tokensApi.fetchTokens().then(() => {
        return axiosInstance(originalRequest);
      });
    }

    if (error.response?.status === 401 && originalRequest && !originalRequest._retry) {
      originalRequest._retry = true;
      return authApi.refreshAccessToken().then((response) => {
        if (response.status === 200) return axiosInstance(originalRequest);
        return Promise.reject(error);
      });
    }
    return Promise.reject(error);
  },
);

function save<RESPONSE = any>(
  baseUrl: string,
  id?: number,
  data?: any,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<RESPONSE>> {
  if (id) {
    return axiosInstance.put<RESPONSE>(`${baseUrl}/${id}`, data, config);
  }

  return axiosInstance.post<RESPONSE>(baseUrl, data, config);
}

function saveOnParent<RESPONSE = any>(
  baseUrl: string,
  id?: number,
  data?: any,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<RESPONSE>> {
  if (id) {
    return axiosInstance.put<RESPONSE>(baseUrl, data, config);
  }

  return axiosInstance.post<RESPONSE>(baseUrl, data, config);
}

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  _retry?: boolean;
}

const isNotFound = (error?: AxiosError | null): boolean => error?.response?.status === 404;
const isUnauthorized = (error?: AxiosError | null) => error?.response?.status === 401;

export { save, saveOnParent, isNotFound, isUnauthorized };

export default axiosInstance;
