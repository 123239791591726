import { Airhelp, AirhelpPlus } from "@airhelp/react";
import useApplicationLinks from "constants/linksPaths";
import { useUser } from "../../../../model/user/UserContext";
import Logotype from "../../../../components/ui-elements/logotype/Logotype";

const AirHelpLogo = () => {
  const { user } = useUser();
  const { websitePath } = useApplicationLinks();
  const logo = user.ahPlusMembership ? (
    <AirhelpPlus color={"var(--color-primary-900)"} width="auto" maxWidth="150px" height="75%" />
  ) : (
    <Airhelp color={"var(--color-primary-900)"} width="auto" maxWidth="150px" height="100%" />
  );

  return <Logotype logo={logo} href={websitePath} dataTestId="logo-airhelp" />;
};

export default AirHelpLogo;
