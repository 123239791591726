import { ComponentProps, PropsWithChildren } from "react";
import styles from "./TranslationWithHTML.module.scss";
import classNames from "classnames";
import { Box } from "@chakra-ui/react";

const TranslationWithHTML = ({
  text,
  className,
  ...props
}: PropsWithChildren<TranslationWithHTMLProps>) => (
  <Box
    className={classNames(styles.translationText, className)}
    dangerouslySetInnerHTML={{ __html: text }}
    fontSize="md"
    color="primary.900"
    {...props}
  />
);

type TranslationWithHTMLProps = {
  text: string;
  className?: string;
} & ComponentProps<typeof Box>;

export default TranslationWithHTML;
