import { sliceUpdate, UpdateFunction } from "../UpdateFunction";
import { Address } from "./Address";
import { PhoneNumber } from "./PhoneNumber";
import { ClaimEnquiryRest } from "../../api/model/ClaimEnquiryRest";

export class Contact {
  public readonly phoneNumber: PhoneNumber;
  public readonly address: Address;

  constructor(
    private claimEnquiryRest: ClaimEnquiryRest,
    private updateEnquiry: UpdateFunction<ClaimEnquiryRest>
  ) {
    this.phoneNumber = new PhoneNumber(
      claimEnquiryRest.phoneNumber,
      sliceUpdate(updateEnquiry, (draft) => draft.phoneNumber)
    );
    this.address = new Address(
      claimEnquiryRest.address,
      sliceUpdate(updateEnquiry, (draft) => draft.address)
    );
  }

  get firstName() {
    return this.claimEnquiryRest.firstName;
  }

  set firstName(firstNumber) {
    this.updateEnquiry((draft) => {
      draft.firstName = firstNumber;
    });
  }

  get lastName() {
    return this.claimEnquiryRest.lastName;
  }

  set lastName(lastName) {
    this.updateEnquiry((draft) => {
      draft.lastName = lastName;
    });
  }

  get email() {
    return this.claimEnquiryRest.user?.email;
  }

  set email(email) {
    this.updateEnquiry((draft) => {
      draft.user!.email = email;
    });
  }
}
