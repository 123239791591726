import { ConsumerRights } from "@airhelp/react";
import styles from "./FunnelStepHeader.module.scss";
import classNames from "classnames";
import { useBrand } from "../../../../brand/BrandContext";
import { useTranslation } from "react-i18next";

const FunnelStepHeader = ({ className }: FunnelStepHeaderProps) => {
  const brand = useBrand();
  const { t } = useTranslation();

  return (
    <div
      data-testid="headerIncentive"
      className={classNames(brand && styles.brandedShield, styles.container, className)}
    >
      <ConsumerRights text={t("components.rights_assurance.text")} className={styles.badge} />
    </div>
  );
};

type FunnelStepHeaderProps = {
  className?: string;
};

export default FunnelStepHeader;
