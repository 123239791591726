import {
  Box,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { defaultCompensation } from "../../../constants/compensation";
import { isMobileOnly } from "react-device-detect";
import { useBrandSnapshot } from "v2/modules/branding";
import { Airhelp, AirhelpPlus } from "@airhelp/react";
import { useApplicationLinks } from "v2/settings/applicationLinks";
import { toCurrency } from "v2/utils/toCurrency";
import { useAppSettingsSnapshot } from "v2/settings";
import { useUser } from "v2/modules/auth";
import Logotype from "components/ui-elements/logotype/Logotype";

const websiteUrl = import.meta.env.VITE_WEBSITE_URL;

export function PoweredByLogo() {
  const { profile } = useUser();
  const { t } = useTranslation();
  const brand = useBrandSnapshot();
  const { websitePath } = useApplicationLinks();
  const { locale, currency } = useAppSettingsSnapshot();
  const isAmexOrEgencia = brand?.isAmex || brand?.isEgencia;
  const logo = profile?.ahPlusMembership ? (
    <AirhelpPlus
      color={"var(--color-primary-900)"}
      width="auto"
      height={{ base: "25px", sm: "30px" }}
    />
  ) : (
    <Airhelp
      color={"var(--color-primary-900)"}
      width="auto"
      height={{ base: "34px", sm: "40px" }}
    />
  );

  return (
    <Popover
      placement={isMobileOnly ? "top-end" : "right-end"}
      trigger={isMobileOnly ? "click" : "hover"}
      preventOverflow={true}
    >
      <PopoverTrigger>
        <Box
          display="flex"
          alignItems={{ base: "flex-start", md: "center" }}
          mb={{ base: "2x", md: "0" }}
        >
          {!isAmexOrEgencia && (
            <Text
              color="greyscale.500"
              fontSize={{ base: "xs", md: "md" }}
              mr={{ base: 0, md: "1" }}
              mt={{ base: 0, md: "2" }}
              alignSelf="flex-end"
              whiteSpace="nowrap"
              data-testid="poweredByText"
            >
              {t("header.powered_by")}
            </Text>
          )}
          <Box
            width={{ base: "84px", sm: "95px" }}
            height={{ base: "22px", sm: "30px" }}
            ml={{ base: "1", md: "0" }}
          >
            <Logotype logo={logo} href={websitePath} dataTestId="logo-airhelp" />
          </Box>
        </Box>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverHeader>{t("components.powered_by_popover.heading")}</PopoverHeader>
        <PopoverBody>
          <Box>
            <Box mb="4">{t("components.powered_by_popover.worlds_largest")}</Box>
            <Box mb="4">
              {t("components.powered_by_popover.flight_disruption", {
                amount: toCurrency(defaultCompensation, currency, locale),
              })}
            </Box>
            <a href={websiteUrl} target="_blank" rel="noopener noreferrer">
              {websiteUrl}
            </a>
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
