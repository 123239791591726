import { isMobileOnly } from "react-device-detect";
import { LoaderFunctionArgs, redirect } from "react-router-dom";
import { loadAirportsFromQueryParams } from "v2/funnels/pre-eligibility/features/airportsFromQueryParams";
import { setShouldSkipFirstStep } from "v2/funnels/pre-eligibility/state/appState";

const basePath = "/claims/new";
const baseMobilePath = `${basePath}/mobile`;
const desktopFistStep = `${basePath}/trip-details`;
const mobileFirstStep = `${baseMobilePath}/flight-details`;

function redirectToFistStepWith(searchParams: URLSearchParams): string {
  const searchParamsString = searchParams.size > 0 ? `?${searchParams}` : "";

  return `${isMobileOnly ? mobileFirstStep : desktopFistStep}${searchParamsString}`;
}

async function resolveFirstLocation(pathname: string, searchParams: URLSearchParams) {
  const { departureAirport, arrivalAirport } = await loadAirportsFromQueryParams();

  if (pathname === basePath || pathname === baseMobilePath) {
    return redirectToFistStepWith(searchParams);
  }

  const shouldSkipFirstStep = departureAirport && arrivalAirport && isMobileOnly;

  if (shouldSkipFirstStep) {
    if (!pathname.startsWith(`${baseMobilePath}/any-connections-flights`)) {
      return `${baseMobilePath}/any-connections-flights?${searchParams}`;
    }
    setShouldSkipFirstStep();
    return null;
  }

  if (
    (isMobileOnly && pathname !== mobileFirstStep) ||
    (!isMobileOnly && pathname !== desktopFistStep)
  ) {
    return redirectToFistStepWith(searchParams);
  }
  return null;
}

export async function preEligibilityLoaderV2({
  request,
}: LoaderFunctionArgs): Promise<null | ResponseInit> {
  const url = new URL(request.url);
  const { pathname, searchParams } = url;
  const location = await resolveFirstLocation(pathname, searchParams);

  return location ? redirect(location) : null;
}
