import { proxy } from "valtio";
import Theme from "theme/Theme";

const DEFAULT_APPLICATION_THEME = "ec261";

const applicationTheme = proxy({ state: DEFAULT_APPLICATION_THEME });

export function setApplicationTheme(theme: Theme) {
  applicationTheme.state = theme;
}

export function isTheme(theme: Theme) {
  return applicationTheme.state === theme;
}
