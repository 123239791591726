const getLogger = (name: string, icon: string) => {
  return (message: string, ...properties: any) => {
    if (
      import.meta.env.MODE === "development" &&
      import.meta.env.VITE_ANALYTICS_LOGGER_ENABLED === "true"
    ) {
      console.log(`${icon} [${name}] ${message}`, ...properties);
    }
  };
};

export default getLogger;
