import { Expose, Transform, Type } from "class-transformer";
import { immerable } from "immer";
import { PersonalDetailsRest } from "./PersonalDetailsRest";

class UserRest {
  [immerable] = true;

  @Expose() id?: number;
  @Expose() email?: string;
  @Expose({ toPlainOnly: true }) password?: string;
  @Expose() firstName?: string;
  @Expose() lastName?: string;
  @Expose() privacyConsent?: boolean;
  @Expose() marketingConsent?: boolean;
  @Expose() privacyConsentAccepted?: boolean;
  @Expose() marketingConsentAccepted?: boolean;
  @Expose() avatarImageUrl?: string;
  @Expose() ahPlusMembership?: boolean;
  @Expose() userProfileUuid?: string;
  @Expose() claimingBlocked?: boolean;

  @Type(() => PersonalDetailsRest)
  @Expose()
  @Transform(({ value }) => value ?? new PersonalDetailsRest())
  personalDetails: PersonalDetailsRest;
}

export default UserRest;
