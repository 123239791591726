import countries from "i18n-iso-countries";
import daCountries from "i18n-iso-countries/langs/da.json";
import deCountries from "i18n-iso-countries/langs/de.json";
import elCountries from "i18n-iso-countries/langs/el.json";
import enCountries from "i18n-iso-countries/langs/en.json";
import esCountries from "i18n-iso-countries/langs/es.json";
import fiCountries from "i18n-iso-countries/langs/fi.json";
import frCountries from "i18n-iso-countries/langs/fr.json";
import itCountries from "i18n-iso-countries/langs/it.json";
import nbCountries from "i18n-iso-countries/langs/nb.json";
import nlCountries from "i18n-iso-countries/langs/nl.json";
import plCountries from "i18n-iso-countries/langs/pl.json";
import ptCountries from "i18n-iso-countries/langs/pt.json";
import roCountries from "i18n-iso-countries/langs/ro.json";
import ruCountries from "i18n-iso-countries/langs/ru.json";
import svCountries from "i18n-iso-countries/langs/sv.json";
import ptBrCountries from "locales/countries/pt-br.json";
import zhCnCountries from "locales/countries/zh-cn.json";
import trCountries from "i18n-iso-countries/langs/tr.json";

countries.registerLocale(deCountries);
countries.registerLocale(daCountries);
countries.registerLocale(elCountries);
countries.registerLocale(esCountries);
countries.registerLocale(enCountries);
countries.registerLocale(fiCountries);
countries.registerLocale(frCountries);
countries.registerLocale(itCountries);
countries.registerLocale(nbCountries);
countries.registerLocale(nlCountries);
countries.registerLocale(plCountries);
countries.registerLocale(ptCountries);
countries.registerLocale(ptBrCountries);
countries.registerLocale(ruCountries);
countries.registerLocale(roCountries);
countries.registerLocale(svCountries);
countries.registerLocale(zhCnCountries);
countries.registerLocale(trCountries);
