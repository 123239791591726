export function arrayAtPolyfill() {
  if (!Array.prototype.at) {
    Array.prototype.at = function (n) {
      // Convert the argument to an integer
      n = Math.trunc(n) || 0;

      // If the number is negative, count back from the end of the array
      if (n < 0) n += this.length;

      // If the adjusted index is still negative or beyond the array's length, return undefined
      if (n < 0 || n >= this.length) return undefined;

      // Return the element at the computed index
      return this[n];
    };
  }
}

export function stringAtPolyfill() {
  if (!String.prototype.at) {
    String.prototype.at = function (n) {
      // Convert the argument to an integer
      n = Math.trunc(n) || 0;

      // If the number is negative, count back from the end of the string
      if (n < 0) n += this.length;

      // If the adjusted index is still negative or beyond the string's length, return undefined
      if (n < 0 || n >= this.length) return undefined;

      // Return the character at the computed index
      return this[n];
    };
  }
}
