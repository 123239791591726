import { useCallback } from "react";
import { useAppSettings } from "./AppSettingsContext";
import { Currency } from "./localesAndCurrencies";

type FormatOptions = Pick<
  Intl.NumberFormatOptions,
  "maximumFractionDigits" | "minimumFractionDigits"
>;

const useToCurrency = () => {
  const { locale, currency: settingsCurrency } = useAppSettings();

  return useCallback(
    (amount: number, currency: Currency = settingsCurrency, options: FormatOptions = {}) => {
      const currencyOptions = {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
        style: "currency",
        currency,
        ...options,
      };

      return new Intl.NumberFormat(locale, currencyOptions).format(amount);
    },
    [locale, settingsCurrency],
  );
};

export default useToCurrency;
