import { proxy, snapshot, useSnapshot } from "valtio";
import { activateExperiment, getOptimizely } from ".";
import { ExperimentConfig } from "ab-tests/optimizely-experiments";
import { Locale } from "app-settings/localesAndCurrencies";
import { Cookies } from "react-cookie";

type OptimizelyState = {
  variationMap: Record<number, { id: number }>;
  isLoaded: boolean;
  isLoading: boolean;
  loadFailed: boolean;
};

const DEFAULT_OPTIMIZELY_STATE: OptimizelyState = {
  variationMap: {},
  isLoaded: false,
  isLoading: false,
  loadFailed: false,
};

const optimizelyState = proxy(DEFAULT_OPTIMIZELY_STATE);

export function useOptimizelySnapshot() {
  return useSnapshot(optimizelyState);
}

export function optimizelySnapshot() {
  return snapshot(optimizelyState);
}

export function startOptimizelyLoading() {
  optimizelyState.isLoading = true;
}

function finishOptimizelyLoading() {
  optimizelyState.isLoading = false;
}

export function handleOptimizelyLoad() {
  if (optimizelyState.loadFailed) {
    return;
  }

  finishOptimizelyLoading();
  optimizelyState.isLoaded = true;
}

function getExperimentVariationMap(experiment: ExperimentConfig) {
  return optimizelyState?.variationMap[experiment.id];
}

export function handleOptimizelyLoadError() {
  optimizelyState.variationMap = {};
  finishOptimizelyLoading();
  optimizelyState.isLoaded = false;
  optimizelyState.loadFailed = true;
}

function forceVariation(paramName: string) {
  const { MODE } = import.meta.env;
  if (MODE !== "production") {
    const cookies = new Cookies();
    const params = cookies.get(paramName);
    return params ? parseInt(params) : undefined;
  }
}

function getActiveExperimentStates() {
  const optimizely = getOptimizely();
  if (optimizely && typeof optimizely.get === "function") {
    const state = optimizely.get("state");
    if (state && typeof state.getExperimentStates === "function") {
      return state.getExperimentStates({
        isActive: true,
      });
    }
  }
  return null;
}

export function getIsExperimentActive(experimentConfig: ExperimentConfig) {
  const activeExperimentStates = getActiveExperimentStates();
  return activeExperimentStates && activeExperimentStates[experimentConfig.id]?.isActive;
}

export function getVariation(
  experimentConfig: ExperimentConfig,
  locale: Locale,
  paramName?: string,
): number | null {
  if (paramName) {
    const forcedVariation = forceVariation(paramName);
    if (forcedVariation !== undefined) {
      if (getExperimentVariationMap(experimentConfig) === undefined)
        optimizelyState.variationMap[experimentConfig.id] = { id: forcedVariation };
      return forcedVariation;
    }
  }

  if (optimizelyState.loadFailed || !optimizelyState.isLoaded || locale !== "en") {
    return null;
  }

  const activeExperimentStates = getActiveExperimentStates();
  const isInHoldBack =
    activeExperimentStates && activeExperimentStates[experimentConfig.id]?.isInExperimentHoldback;
  const isExperimentActive =
    activeExperimentStates && activeExperimentStates[experimentConfig.id]?.isActive;
  const shouldSetVariant = !isInHoldBack && isExperimentActive;
  let variantId = getExperimentVariationMap(experimentConfig)?.id;

  if (variantId === undefined) {
    experimentConfig.custom && activateExperiment(experimentConfig);
    shouldSetVariant && updateVariationMap();
    const currentExperimentMap = getExperimentVariationMap(experimentConfig);
    if (!currentExperimentMap) {
      optimizelyState.variationMap[experimentConfig.id] = { id: 0 };
    }
    variantId = getExperimentVariationMap(experimentConfig).id;
  }

  return experimentConfig.variations[variantId];
}

function updateVariationMap() {
  optimizelyState.variationMap = getOptimizely().get("state").getVariationMap();
}
