import { ZodError, ZodIssueCode, setErrorMap } from "zod";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { TFunction } from "i18next";

export function setupZod() {
  setErrorMap((issue) => {
    switch (issue.code) {
      case ZodIssueCode.too_big:
        return { message: `validation.string.max#max=${issue.maximum}` };
      case ZodIssueCode.too_small:
        // .nonempty() has the same code as .min()
        if (issue.minimum === 1) return { message: "validation.mixed.required" };
        return { message: `validation.string.min#min=${issue.minimum}` };
      default:
        return { message: issue.message || "" };
    }
  });
}

export function useTranslateZodError() {
  const { t } = useTranslation();
  return useCallback((error: string) => translateZodErrorString(error, t), [t]);
}

export function translateZodErrorString(error: string, t: TFunction) {
  const [translationKey, translationOptions] = error.split("#");

  if (!translationOptions) {
    return t(error);
  }

  const options = translationOptions.split(";").reduce(
    (options, option) => {
      if (!/\w+=\w+/g.test(option)) {
        throw new Error(
          `Invalid translation option: ${option}, make sure your error is correctly formatted in form of key#option1=value1;option2=value2`,
        );
      }

      const [key, value] = option.split("=");
      options[key] = value;
      return options;
    },
    {} as Record<string, string>,
  );

  return t(translationKey, options);
}

export function translateZodError(error: ZodError, t: TFunction) {
  return error.issues.reduce(
    (errors, issue) => {
      errors[issue.path[0]] = translateZodErrorString(issue.message, t);
      return errors;
    },
    {} as Record<string, string>,
  );
}
