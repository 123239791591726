import { Expose, Transform, Type } from "class-transformer";

export class AirlineSearch {
  @Type(() => Airline)
  @Expose()
  airlines: Airline[];
}

export class Airline {
  @Expose() iata: string;
  @Expose() icao?: string;
  @Expose() name: string;
  @Expose() displayName: string;

  @Expose()
  @Transform(({ obj }) => obj.uniqueIdentifier, { toClassOnly: true, groups: ["api"] })
  airlineIdentifier: string;
}
