import { useUser } from "model/user/UserContext";
import { useCallback } from "react";
import usersApi from "../api/users";
import authApi from "api/auth";

const useUserInitializer = () => {
  const { updateUser } = useUser();

  return useCallback(
    async function userInitializer() {
      try {
        const user = await usersApi.fetchLoggedUser();
        updateUser(() => user);
        return user;
      } catch (error: any) {
        if (error?.response?.status !== 401) {
          return null;
        } else {
          try {
            await authApi.refreshAccessToken();
            const user = await usersApi.fetchLoggedUser();
            updateUser(() => user);
            return user;
          } catch {
            return null;
          }
        }
      }
    },
    [updateUser],
  );
};

export default useUserInitializer;
