import { useCallback } from "react";
import { deviceType, getUA, mobileModel, osName } from "react-device-detect";
import { useAppSettings } from "../../app-settings/AppSettingsContext";
import { Currency, Locale } from "../../app-settings/localesAndCurrencies";
import User from "../../model/user/User";
import { useUser } from "../../model/user/UserContext";
import analytics from "../../tracking/analytics";
import hashing from "../utils/hashing";
import { camelize, capitalize } from "../utils/string";
import {
  useClaimEnquiryExternalStore,
  ClaimEnquirySnapshot,
} from "model/claim-enquiry/ClaimEnquiryContext";

interface PageViewedEventProperties {
  previousPageUrl?: string;
  funnelType: string;
}

const WEB_THEME: { [index: string]: string } = {
  mobile: "Phone",
  tablet: "Tablet",
  desktop: "Desktop",
};

const pathsBeforeUuid: string[] = ["claim_enquiries", "verify"];

const hasPageTypeFormat = (pathname: string) => pathname.match(/^[a-z-]+$/);

const removeUuidFromPath = (splitPath: string[]) => {
  pathsBeforeUuid.forEach((pathBeforeUuid) => {
    const pathBeforeUuidIndex = splitPath.findIndex((path) => path === pathBeforeUuid);
    if (pathBeforeUuidIndex !== -1) splitPath.splice(pathBeforeUuidIndex + 1, 1);
  });
};

export const createPageType = () => {
  const pathname = window.location.pathname;
  const splitPathname = pathname.split("/");

  if (pathname.startsWith("/ota")) {
    const lastPath = splitPathname[splitPathname.length - 1];
    if (hasPageTypeFormat(lastPath)) {
      return `OtaLead${camelize(lastPath, true)}`;
    }

    return "OtaLead";
  }

  removeUuidFromPath(splitPathname);

  return splitPathname.map((s) => camelize(s, true)).join("");
};

const pageInfo = (properties: PageViewedEventProperties) => {
  return {
    pageUrl: window.location.href,
    pagePath: window.location.pathname,
    pageType: createPageType(),
    pageTitle: document.title,
    pageReferrer: properties.previousPageUrl || document.referrer,
  };
};

const deviceInfo = {
  userAgent: getUA,
  deviceType: capitalize(deviceType),
  deviceModel: mobileModel || osName,
  webTheme: WEB_THEME[deviceType],
  viewportWidth: window.innerWidth,
  viewportHeight: window.innerHeight,
};

const appInfo = (currency: Currency, locale: Locale, funnelType: string) => {
  return {
    pageLanguage: locale,
    pageCurrency: currency,
    funnelType,
  };
};

export const userInfo = (user: User, claimEnquiry?: ClaimEnquirySnapshot) => {
  const email = user.email;
  const phoneNumber = claimEnquiry?.contact?.phoneNumber.number;
  const firstName = claimEnquiry?.contact?.firstName;
  const lastName = claimEnquiry?.contact?.lastName;
  const city = claimEnquiry?.contact?.address?.city;
  const countryCode = claimEnquiry?.contact?.address?.countryCode;
  const postalCode = claimEnquiry?.contact?.address?.zipCode;

  return {
    loggedIn: user.isLoggedIn,
    userId: user.id,
    hashedEmailId: email && hashing.sha256(email),
    hashedEmailIdEx: email && hashing.exactag(email),
    hashedPhoneNumber: phoneNumber && hashing.sha256(phoneNumber),
    hashedFirstName: firstName && hashing.sha256(firstName),
    hashedLastName: lastName && hashing.sha256(lastName),
    hashedCity: city && hashing.sha256(city),
    hashedCountry: countryCode && hashing.sha256(countryCode),
    hashedPostalCode: postalCode && hashing.sha256(postalCode),
  };
};

// TODO: Fill referral info
const referralInfo = {
  referralProgram: false,
};

export const useBuildPageViewedProperties = () => {
  const { user } = useUser();
  const { locale, currency } = useAppSettings();
  const { claimEnquiry } = useClaimEnquiryExternalStore();

  return useCallback(
    (properties: PageViewedEventProperties) => ({
      ...pageInfo(properties),
      ...deviceInfo,
      ...appInfo(currency, locale, properties.funnelType),
      ...userInfo(user, claimEnquiry),
      ...referralInfo,
    }),
    [user, locale, currency, claimEnquiry]
  );
};

const usePageViewedEvent = () => {
  const eventProperties = useBuildPageViewedProperties();

  return useCallback(
    (properties: PageViewedEventProperties) => {
      analytics.track("pageViewed", eventProperties(properties));
    },
    [eventProperties]
  );
};

export default usePageViewedEvent;
