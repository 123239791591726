import { GuardFunction } from "../useFunnelGuard";
import { FunnelDefinition, FunnelStepDefinition } from "./FunnelDefinition";

const withNewNextStepValue = (previous: FunnelStepDefinition, newStep?: FunnelStepDefinition) => {
  if (previous?.next === null) {
    return previous;
  }

  return {
    ...previous,
    next: previous.next || newStep?.id
  };
};

/**
 * Class responsible for dynamic `FunnelDefinition` creation. Usually wrapped by more specialized `FunnelBuilder` (for example `GoodwillFunnelBuilder`)
 * which is aware what steps are available and how to add them.
 */
class FunnelBuilder {
  private steps: FunnelStepDefinition[] = [];
  private guard?: GuardFunction;
  private flow?: string;

  constructor(private name: string) {
  }

  /**
   * It allows to add new steps and if `next` property on last added step is not present it will automatically
   * set its value to new step id.
   *
   * @param step - definition of new step
   */
  addStep(step: FunnelStepDefinition): this {
    const newStep = { ...step };
    if (this.steps.length === 0) {
      this.steps = [newStep];
      return this;
    }

    const previousStep = withNewNextStepValue(this.lastStep, newStep);
    this.steps = [...this.previousSteps, previousStep, newStep];
    return this;
  }

  /**
   * Useful when funnel builder is already filled with steps but for some reason it needs to be replaced by other definition.
   *
   * @param stepId - id of step that should be replaced
   * @param step - definition of step replacement
   */
  replaceStep(stepId: string, step: FunnelStepDefinition): FunnelBuilder {
    const newStep = { ...step };
    const stepIndex = this.steps.findIndex((s) => s.id === stepId);

    if (stepIndex !== -1) {
      const nextStep = this.steps[stepIndex + 1];
      const previousStep = this.steps[stepIndex - 1];

      if (previousStep) {
        this.steps[stepIndex - 1] = withNewNextStepValue(previousStep, newStep);
      }

      this.steps[stepIndex] = withNewNextStepValue(newStep, nextStep);
    }

    return this;
  }

  /**
   * Adds funnel guard. Only one funnel guard is allowed.
   *
   * @param guard
   */
  withGuard(guard: GuardFunction) {
    this.guard = guard;
    return this;
  }

  markFlow(flow: string) {
    this.flow = flow;
    return this;
  }

  build(): FunnelDefinition {
    return {
      name: this.name,
      guard: this.guard,
      steps: this.steps,
      flow: this.flow
    };
  }

  extend(definition: FunnelDefinition) {
    this.guard = definition.guard;
    this.steps = definition.steps;
    this.flow = definition.flow;

    return this;
  }

  private get lastStep() {
    return this.steps.slice(-1)[0];
  }

  private get previousSteps() {
    return this.steps.slice(0, this.steps.length - 1);
  }
}

export default FunnelBuilder;
