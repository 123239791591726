import { FellowPassengerRest } from "../../api/model/FellowPassengerRest";
import { Expose } from "class-transformer";
import { fromJson } from "../../api/model/json";

export class ClaimFellowPassenger extends FellowPassengerRest {
  @Expose({ toClassOnly: true })
  token?: string;

  static fromJson(data: any) {
    return fromJson(ClaimFellowPassenger, data);
  }
}
