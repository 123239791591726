import { Expose, Transform } from "class-transformer";
import { fromJson, PLAIN_TO_DATE_TRANSFORMER } from "../../../api/model/json";

export type DuplicationType =
  | "instant_correction"
  | "documents_requested"
  | "inactive_claim"
  | "another_user";

class SimilarClaim {
  @Expose() id: number;
  @Expose() duplicationType?: DuplicationType;
  @Expose() claimEnquiryUuid?: string;
  @Expose()
  @Transform(PLAIN_TO_DATE_TRANSFORMER, { toClassOnly: true })
  createdAt?: Date;

  static fromJson(duplicatedClaim: any) {
    return fromJson(SimilarClaim, duplicatedClaim);
  }
}

export default SimilarClaim;
