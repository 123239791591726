import axios from "./axios";

const TOKENS_API_URL = "/api/tokens";

const fetchTokens = async (): Promise<any> => {
  return axios.get(TOKENS_API_URL).then((response) => response);
};

const tokensApi = {
  fetchTokens,
};

export default tokensApi;
