import { ComponentProps } from "react";
import { Trustpilot } from "@airhelp/react";
import { Box } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useTrustpilotScore } from "../../../modules/trustpilot-score";
import { useAppSettingsSnapshot } from "../../../settings";

export function FunnelTrustpilot({ text, ...props }: TrustpilotProps) {
  const { t } = useTranslation();
  const { locale } = useAppSettingsSnapshot();
  const { scoreValue, totalReviews } = useTrustpilotScore(locale);

  const translationsVariables = {
    scoreValue: `${scoreValue}/5`,
    totalReviews: totalReviews,
  };

  return (
    <Trustpilot
      data-testid="trustpilot"
      text={t(text || "footer.trustpilot.text", translationsVariables)}
      {...props}
    />
  );
}

type TrustpilotProps = ComponentProps<typeof Box> & {
  className?: string;
  text?: string;
};
