import { authClient, webappClient } from "../clients";
import { validateResponse } from "../validation";
import z from "zod";
import { Options } from "ky";

const fetchUserProfileSchema = z.object({
  user: z.object({
    id: z.number(),
    country_code: z.string().optional().nullable(),
    email: z.string().email(),
    first_name: z.string().optional().nullable(),
    last_name: z.string().optional().nullable(),
    privacy_consent_accepted: z.boolean(),
    marketing_consent_accepted: z.boolean(),
    user_profile_uuid: z.string(),
    ah_plus_membership: z.boolean(),
    locale: z.string().optional().nullable(),
    phone_number: z
      .object({
        id: z.number(),
        number: z.string().nullable(),
        whatsapp_consent: z.boolean().optional().nullable(),
      })
      .optional()
      .nullable(),
    claiming_blocked: z.boolean().optional(),
  }),
});

export async function fetchUserProfile(httpOptions: Options = {}) {
  const data = await webappClient.get("api/users/me", httpOptions).json();

  const validated = validateResponse({
    data,
    schema: fetchUserProfileSchema,
    tag: "User profile",
  });

  return {
    id: validated.user.id,
    countryCode: validated.user.country_code,
    email: validated.user.email,
    firstName: validated.user.first_name,
    lastName: validated.user.last_name,
    privacyConsentAccepted: validated.user.privacy_consent_accepted,
    marketingConsentAccepted: validated.user.marketing_consent_accepted,
    userProfileUuid: validated.user.user_profile_uuid,
    ahPlusMembership: validated.user.ah_plus_membership,
    locale: validated.user.locale,
    phoneNumber: validated.user.phone_number,
    claimingBlocked: validated.user.claiming_blocked,
  };
}

export async function signoutUser() {
  await authClient.post("api/v1/auth/logout");
  await webappClient.delete("api/session");
}
