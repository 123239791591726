import React, { createContext, useCallback, useContext, useState } from "react";
import { FeatureFlagName } from "./featureFlagsList";

export type FeatureFlag = {
  name: FeatureFlagName;
};

type FeatureFlagsContextType = (name: FeatureFlagName) => boolean;

type SetFeatureFlagsType = (featureFlags: FeatureFlag[]) => void;

const FeatureFlagsContext = createContext<FeatureFlagsContextType | undefined>(undefined);
const SetFeatureFlagsContext = createContext<SetFeatureFlagsType | undefined>(undefined);

export const FeatureFlagsProvider = ({ children }: { children?: React.ReactNode }) => {
  const [featureFlags, setFeatureFlags] = useState<FeatureFlag[]>([]);

  const isFlagActive = useCallback(
    (name: FeatureFlagName) => featureFlags.some((flag) => flag.name === name),
    [featureFlags],
  );

  return (
    <FeatureFlagsContext.Provider value={isFlagActive}>
      <SetFeatureFlagsContext.Provider value={setFeatureFlags}>
        {children}
      </SetFeatureFlagsContext.Provider>
    </FeatureFlagsContext.Provider>
  );
};

export const useSetFeatureFlags = (): SetFeatureFlagsType => {
  const context = useContext(SetFeatureFlagsContext);

  if (!context) {
    throw new Error("useSetFeatureFlags must be used within a FeatureFlagsProvider");
  }

  return context;
};

export const useFeatureFlag = (name: FeatureFlagName): boolean => {
  const isFeatureActive = useContext(FeatureFlagsContext);

  if (!isFeatureActive) {
    throw new Error("useFeatureFlag must be used within a FeatureFlagsProvider");
  }

  return isFeatureActive(name);
};
