import z from "zod";
import { externalClient } from "../clients";
import { validateResponse } from "../validation";

export const airportResponseSchema = z.object({
  iata: z.string(),
  name: z.string(),
  rank: z.number(),
  city: z.string(),
  ec261: z.boolean(),
  country: z.string(),
  country_code: z.string(),
  latitude: z.number(),
  time_zone: z.string(),
  longitude: z.number(),
  search_text: z.string(),
  city_translations: z.object({}),
});

const airportsResponseSchema = z.object({
  airport_search: z.object({
    airports: z.array(airportResponseSchema),
    metropolitan_areas: z.array(
      z.object({
        code: z.string(),
        country_code: z.string(),
        country_name: z.string(),
        name: z.string(),
        rank: z.number(),
        visible_airports: z.array(airportResponseSchema),
      }),
    ),
  }),
});

export type RawAirport = z.infer<typeof airportResponseSchema>;

export async function fetchAirports(searchTerm: string) {
  const data = await externalClient
    .get("api/airports", {
      searchParams: {
        q: searchTerm,
        with_metropolitan_areas: true,
      },
    })
    .json();

  const result = validateResponse({
    data,
    schema: airportsResponseSchema,
    tag: "Airports API response",
  });

  const airports = result.airport_search.airports;
  const metropolitanAreasAirports = result.airport_search.metropolitan_areas.flatMap(
    (m) => m.visible_airports,
  );

  return [...airports, ...metropolitanAreasAirports];
}

export async function fetchAirport(iata: string) {
  const data = await externalClient.get(`api/airports/${iata.toUpperCase()}`).json();

  const result = validateResponse({
    data,
    schema: z.object({
      airport: airportResponseSchema,
    }),
    tag: "Airport search API response",
  });

  return result.airport;
}
