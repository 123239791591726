import { proxy, snapshot, subscribe, useSnapshot } from "valtio";
import {
  Airport,
  ArrivalStatus,
  ClaimType,
  Delay,
  InformedBeforehand,
  ItineraryFlight,
  PotentialRegulation,
} from "../../../modules/types/intake-funnel";
import { isFuture } from "date-fns";

export type PreEligibilityDataSnapshot = typeof preEligibilityDataSnapshot;

export type FlightDetails = {
  anyConnections: boolean;
  connections: { airport: Airport | null }[];
  isFutureFlight: boolean;
};

export type ClaimDetails = {
  airlineReason: "no_remember";
  channel: "ch_web" | "ch_partner" | "ch_affiliate";
  totalDelay: Delay | null;
  claimType: ClaimType | null;
  voluntarilySurrendered: boolean | null;
  potentialClaimType: ArrivalStatus | null;
  informedBeforehand: InformedBeforehand | null;
  potentialRegulation: PotentialRegulation | null;
  prefilled: boolean;
};

export type ItineraryFlights = ItineraryFlight[];

const DEFAULT_ITINERARY_FLIGHT: ItineraryFlight = {
  departureAirport: null,
  arrivalAirport: null,
  airline: null,
  flightDate: null,
  flightNumber: null,
  disrupted: true,
};

const DEFAULT_ITINERARY_FLIGHTS: ItineraryFlights = [DEFAULT_ITINERARY_FLIGHT];

const DEFAULT_FLIGHT_DETAILS: FlightDetails = {
  anyConnections: false,
  connections: [],
  isFutureFlight: false,
};

const DEFAULT_CLAIM_DETAILS: ClaimDetails = {
  potentialRegulation: null,
  potentialClaimType: null,
  claimType: null,
  channel: "ch_web",
  voluntarilySurrendered: null,
  totalDelay: null,
  informedBeforehand: null,
  airlineReason: "no_remember",
  prefilled: false,
};

const initialState = {
  state: {
    flightDetails: DEFAULT_FLIGHT_DETAILS,
    claimDetails: DEFAULT_CLAIM_DETAILS,
    itineraryFlights: DEFAULT_ITINERARY_FLIGHTS,
  },
};

const preEligibilityData = proxy(initialState);

subscribe(preEligibilityData.state, () => {
  const { itineraryFlights, flightDetails } = preEligibilityData.state;

  flightDetails.isFutureFlight = itineraryFlights.some(
    ({ flightDate }) => !!flightDate && isFuture(flightDate),
  );
});

export function updateItineraryFlightByIndex(
  index: number,
  itineraryFlight: Partial<ItineraryFlight>,
) {
  const oldItineraryFlight = preEligibilityData.state.itineraryFlights[index];

  preEligibilityData.state.itineraryFlights[index] = {
    ...oldItineraryFlight,
    ...itineraryFlight,
  };

  return snapshot(preEligibilityData).state.itineraryFlights;
}

export function groupItineraryFlights({
  departureAirport,
  arrivalAirport,
  connections,
}: {
  departureAirport: Airport;
  arrivalAirport: Airport;
  connections: { airport: Airport }[];
}) {
  if (!connections.length)
    return [
      {
        departureAirport: departureAirport,
        arrivalAirport: arrivalAirport,
        airline: null,
        flightDate: null,
        flightNumber: null,
        disrupted: true,
      },
    ];

  return connections.reduce((flights, { airport: connectionAirport }, index) => {
    if (index === 0) {
      flights.push({
        departureAirport: departureAirport,
        arrivalAirport: connectionAirport,
        airline: null,
        flightDate: null,
        flightNumber: null,
        disrupted: false,
      });
    } else {
      flights.push({
        departureAirport: connections[index - 1].airport,
        arrivalAirport: connectionAirport,
        flightDate: null,
        flightNumber: null,
        airline: null,
        disrupted: false,
      });
    }

    if (index === connections.length - 1) {
      flights.push({
        departureAirport: connectionAirport,
        arrivalAirport: arrivalAirport,
        airline: null,
        flightDate: null,
        flightNumber: null,
        disrupted: false,
      });
    }

    return flights;
  }, [] as ItineraryFlight[]);
}

export function updateItineraryFlights(newItineraryFlights: ItineraryFlight[]) {
  preEligibilityData.state.itineraryFlights = newItineraryFlights;

  return snapshot(preEligibilityData).state.itineraryFlights;
}

export function selectDisruptedFlightByIndex(index: number) {
  preEligibilityData.state.itineraryFlights = preEligibilityData.state.itineraryFlights.map(
    (flight, i) => {
      if (i === index) {
        return {
          ...flight,
          disrupted: true,
        };
      }

      return {
        ...flight,
        disrupted: false,
      };
    },
  );

  return snapshot(preEligibilityData).state.itineraryFlights;
}

export function updateFlightDetails(data: Partial<FlightDetails>) {
  const { state } = preEligibilityData;
  const { flightDetails } = state;

  preEligibilityData.state.flightDetails = {
    ...flightDetails,
    ...data,
  };

  return snapshot(preEligibilityData).state.flightDetails;
}

export function updateClaimDetails(data: Partial<ClaimDetails>) {
  const { state } = preEligibilityData;
  const { claimDetails } = state;

  preEligibilityData.state.claimDetails = {
    ...claimDetails,
    ...data,
  };

  return snapshot(preEligibilityData).state.claimDetails;
}

export function usePreEligibilityDataSnapshot() {
  return useSnapshot(preEligibilityData).state;
}

export function getDepartureAirport() {
  return snapshot(preEligibilityData).state.itineraryFlights[0].departureAirport;
}

export function getArrivalAirport() {
  const itineraryFlights = snapshot(preEligibilityData).state.itineraryFlights;
  const lastItineraryFlight = itineraryFlights[itineraryFlights.length - 1];

  return lastItineraryFlight.arrivalAirport;
}

export const preEligibilityDataSnapshot = snapshot(preEligibilityData).state;

export function getPreEligibilityDataSnapshot() {
  return snapshot(preEligibilityData).state;
}
