import { Cookies } from "react-cookie";

const cookies = new Cookies();

export function getFunnelReferrer() {
  let referrer: string | undefined | null;

  try {
    referrer = sessionStorage.getItem("funnel_referrer");
  } catch {
    referrer = cookies.get("funnel_referrer");
  }

  if (referrer) {
    return referrer;
  }
}
