import { GuardFunction } from "../../../useFunnelGuard";

const preEligibilityBarcodeGuard =
  (firstStepPath: string): GuardFunction =>
  (currentLocation, lastLocation, steps, navigationType) => {
    const isTargetFunnelLocation = currentLocation.pathname.includes("claims/barcode");
    const isFunnelFirstStep = !!lastLocation?.pathname.includes(firstStepPath);
    const navigatedBack = isFunnelFirstStep && navigationType === "POP";

    return {
      shouldGuard: isTargetFunnelLocation && navigatedBack,
      redirectPath: firstStepPath,
    };
  };

export default preEligibilityBarcodeGuard;
