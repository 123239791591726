import { proxy, snapshot } from "valtio";

const appState = proxy({
  shouldSkipFirstStep: false,
  shouldCutInformedBeforehandStep: false,
  canBuyAhPlusOffer: true,
});

export function setShouldSkipFirstStep() {
  appState.shouldSkipFirstStep = true;
}

export function getShouldSkipFirstStep() {
  return snapshot(appState).shouldSkipFirstStep;
}

export function setShouldCutInformedBeforehandStep() {
  appState.shouldCutInformedBeforehandStep = true;
}

export function getShouldCutInformedBeforehandStep() {
  return snapshot(appState).shouldCutInformedBeforehandStep;
}

export function setCanBuyAhPlusOffer(value: boolean) {
  appState.canBuyAhPlusOffer = value;
}

export function getCanBuyAhPlusOffer() {
  return snapshot(appState).canBuyAhPlusOffer;
}
