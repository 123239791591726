export function HEXToHSL(HEX: string) {
  const RGB = HEXToRGB(HEX);

  return RGBtoHSL(RGB);
}

export function HEXToRGB(HEX: string): RGB {
  let R = 0,
    G = 0,
    B = 0;

  if (HEX.length === 4) {
    R = parseInt(HEX[1] + HEX[1], 16);
    G = parseInt(HEX[2] + HEX[2], 16);
    B = parseInt(HEX[3] + HEX[3], 16);
  } else if (HEX.length === 7) {
    R = parseInt(HEX[1] + HEX[2], 16);
    G = parseInt(HEX[3] + HEX[4], 16);
    B = parseInt(HEX[5] + HEX[6], 16);
  }

  return {
    R,
    G,
    B,
  };
}

export function RGBtoHSL({ R, G, B }: RGB): HSL {
  let H = 0,
    S = 0,
    L = 0;

  const r = R / 255,
    g = G / 255,
    b = B / 255;

  const Cmin = Math.min(r, g, b);
  const Cmax = Math.max(r, g, b);

  const delta = Cmax - Cmin;

  L = (Cmax + Cmin) / 2;

  if (delta === 0) {
    S = 0;
  } else {
    S = delta / (1 - Math.abs(2 * L - 1));
  }

  if (delta === 0) {
    H = 0;
  } else if (r === Cmax) {
    H = 60 * (((g - b) / delta) % 6);
  } else if (g === Cmax) {
    H = 60 * ((b - r) / delta + 2);
  } else if (b === Cmax) {
    H = 60 * ((r - g) / delta + 4);
  }

  if (H < 0) {
    H += 360;
  }

  S = +S.toFixed(4);
  L = +L.toFixed(4);

  return {
    H,
    S,
    L,
  };
}

export function HSLtoRGB({ H, S, L }: HSL): RGB {
  let R = 0,
    G = 0,
    B = 0;

  const C = (1 - Math.abs(2 * L - 1)) * S;
  const X = C * (1 - Math.abs(((H / 60) % 2) - 1));
  const m = L - C / 2;

  if (0 <= H && H < 60) {
    R = C;
    G = X;
    B = 0;
  } else if (60 <= H && H < 120) {
    R = X;
    G = C;
    B = 0;
  } else if (120 <= H && H < 180) {
    R = 0;
    G = C;
    B = X;
  } else if (180 <= H && H < 240) {
    R = 0;
    G = X;
    B = C;
  } else if (240 <= H && H < 300) {
    R = X;
    G = 0;
    B = C;
  } else if (300 <= H && H < 360) {
    R = C;
    G = 0;
    B = X;
  }

  return {
    R: Math.round((R + m) * 255),
    G: Math.round((G + m) * 255),
    B: Math.round((B + m) * 255),
  };
}

export function HSLtoCSS({ H, S, L }: HSL) {
  const preparedH = H.toFixed(2);
  const preparedS = (S * 100).toFixed(2) + "%";
  const preparedL = (L * 100).toFixed(2) + "%";

  return `hsl(${preparedH}, ${preparedS}, ${preparedL})`;
}

interface HSL {
  H: number;
  S: number;
  L: number;
}

interface RGB {
  R: number;
  G: number;
  B: number;
}
