import { Avatar, Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Cookies } from "react-cookie";
import { useState } from "react";
import { AVATAR_ES_EXPERIMENT_CONFIG } from "../../../ab-tests/optimizely-experiments";
import useOptimizely from "../../../tracking/tools/optimizely";
import useEffectOnce from "../../../hooks/useEffectOnce";
import { getAvatar } from "../../../v2/components/layout/Content";

const cookies = new Cookies();

function FunnelAvatar() {
  const { t } = useTranslation();
  const locale = cookies.get("initial_language");
  const { getVariation } = useOptimizely();
  const [avatarEsVariation, setAvatarEsVariation] = useState<number | null>();

  const avatar = getAvatar(locale, t, getLocalisedExperimentVariation(locale));

  function getLocalisedExperimentVariation(locale: string) {
    if (locale === "es") {
      return avatarEsVariation;
    }
  }

  useEffectOnce(() => {
    setAvatarEsVariation(getVariation(AVATAR_ES_EXPERIMENT_CONFIG, locale));
  });

  return (
    <Flex mb={6} data-testid="funnelAvatar">
      <Avatar mr={4} boxSize={12} src={avatar.src} bg="transparent" />
      <Flex direction="column" justify="center">
        <Text variant="medium" fontWeight="bold">
          {avatar.name}
        </Text>
        <Text fontSize="sm">{t("components.avatar.role")}</Text>
      </Flex>
    </Flex>
  );
}

export default FunnelAvatar;
