import { ThemeProvider } from "./theme/ThemeContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { PropsWithChildren } from "react";
import { FeatureFlagsProvider } from "./app-settings/FeatureFlagsContext";
import { BrandProvider } from "./brand/BrandContext";
import { AppSettingsProvider } from "./app-settings/AppSettingsContext";
import { UserProvider } from "./model/user/UserContext";
import { ProgressBarProvider } from "./layout/sidebar/progress-bar/context/ProgressBarContext";
import ScrollToTop from "./utils/ScrollToTop";
import { AppInitializerProvider } from "./initializers/AppInitializerContext";
import MainPage from "./pages/main-page/MainPage";
import { ChakraProvider } from "@chakra-ui/react";
import { useFunnelTheme } from "./v2/modules/branding/useFunnelTheme";
import { defaultFunnelTheme } from "./theme/funnelTheme";
import { Outlet } from "react-router-dom";
import { Fonts } from "@airhelp/react";

export const defaultQueryClient = new QueryClient({
  defaultOptions: {
    queries: { refetchOnWindowFocus: false },
  },
});

export const ApplicationProviders = ({ children }: PropsWithChildren) => {
  return (
    <ChakraProvider theme={defaultFunnelTheme}>
      <Fonts />
      <ThemeProvider>
        <QueryClientProvider client={defaultQueryClient}>
          <FeatureFlagsProvider>
            <BrandProvider>
              <AppSettingsProvider>
                <UserProvider>
                  <ProgressBarProvider>{children}</ProgressBarProvider>
                </UserProvider>
              </AppSettingsProvider>
            </BrandProvider>
          </FeatureFlagsProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </ChakraProvider>
  );
};

export function ApplicationShell() {
  return (
    <ApplicationProviders>
      <ScrollToTop />
      <AppInitializerProvider>
        <MainPage>
          <Outlet />
        </MainPage>
      </AppInitializerProvider>
    </ApplicationProviders>
  );
}

export function ApplicationShellV2({ children }: PropsWithChildren) {
  const funnelTheme = useFunnelTheme();
  return (
    <ChakraProvider theme={funnelTheme}>
      <Fonts />
      <QueryClientProvider client={defaultQueryClient}>{children}</QueryClientProvider>
    </ChakraProvider>
  );
}
