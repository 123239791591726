import { ReactNode, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import usePageViewedEvent from "./pageViewed";
import { usePageViewedEventV2 } from "../ga4/builders/pageViewed";
import { useNavigation } from "../../hooks/useNavigation";
import { defaultDataLayerData } from "tracking/tools/dataLayer";

const PageViewedEventTracker = ({ children }: { children?: ReactNode }) => {
  const { pathname } = useLocation();
  const { lastLocation } = useNavigation();
  const trackPageViewed = usePageViewedEvent();
  const trackPageViewedV2 = usePageViewedEventV2();
  const funnelFlow = (): string => {
    if (pathname.includes("barcode/scanner")) return "barcodeFlow";
    if (pathname.includes("barcode/uploader")) return "uploaderFlow";

    return "defaultFlow";
  };

  const funnelType = useMemo(
    () => funnelFlow(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    const previousPageUrl = lastLocation?.pathname;
    if (defaultDataLayerData.brand !== undefined) {
      trackPageViewed({ previousPageUrl, funnelType });
      trackPageViewedV2({ previousPageUrl, funnelType });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, defaultDataLayerData.brand]);

  return <>{children}</>;
};

export default PageViewedEventTracker;
