import { useCallback } from "react";
import { isTheme } from "../theme/Theme";
import { useSetTheme } from "../theme/ThemeContext";
import queryParams from "../utils/queryParams";

const useThemeInitializer = () => {
  const setTheme = useSetTheme();

  return useCallback(() => {
    const { theme } = queryParams(window.location.search);

    if (isTheme(theme)) {
      setTheme(theme);
    }
  }, [setTheme]);
};

export default useThemeInitializer;
