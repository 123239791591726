import ky, { HTTPError } from "ky";
import { injectCSRFTokenBeforeRequest, setupCSRFTokenFromResponse } from "./csrf";
import resolveAppUrl from "utils/resolveAppUrl";
import { sendErrorReport, sendWarningReport } from "../../utils/reporting";
import { isHttpError } from "v2/utils/ky";
import { fetchTokens } from "./tokens";

const { VITE_WEBAPP_BASE_URI } = import.meta.env;
const webappBaseURL = resolveAppUrl(window.location.origin, VITE_WEBAPP_BASE_URI);
const DEFAULT_BASE_URI = window.location.protocol + "//" + window.location.host;

export const webappClient = ky.create({
  prefixUrl: webappBaseURL || DEFAULT_BASE_URI,
  credentials: "include",
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    "X-Caller": "webapp-funnel",
  },
  retry: {
    limit: 2,
    statusCodes: [401, 422],
    methods: ["get", "post", "put", "delete", "patch"],
  },
  hooks: {
    beforeRequest: [injectCSRFTokenBeforeRequest, setXRequestIdHeader],
    afterResponse: [setupCSRFTokenFromResponse],
    beforeRetry: [
      async ({ error }) => {
        try {
          if (isHttpError(error)) {
            if (await isInvalidAuthenticityToken(error)) {
              sendWarningReport("Invalid authenticity token error", error);
              await fetchTokens();
            } else if (error.response?.status === 401) {
              await refreshAccessToken().catch((error) => {
                sendWarningReport("Failed to refresh access token", error);
              });
            }
          }
        } catch (error) {
          sendErrorReport(error, "Unhandled error in webappClient retry hook");
        }
      },
    ],
  },
});

export const externalClient = ky.create({
  prefixUrl: import.meta.env.VITE_WEBAPP_BASE_URI || DEFAULT_BASE_URI,
  retry: {
    limit: 2,
    statusCodes: [401],
    methods: ["get", "post", "put"],
  },
});

const authBaseUrl = resolveAppUrl(
  window.location.origin,
  import.meta.env.VITE_AUTH_URL || "/api-auth",
);

export const authClient = ky.create({
  prefixUrl: authBaseUrl,
  headers: {
    "X-Caller": "webapp-funnel",
  },
  credentials: "include",
  hooks: {
    beforeRequest: [setXRequestIdHeader],
  },
});

const refreshAccessToken = async () => {
  return authClient.post(`api/v1/auth/refresh`, {});
};

export const regenerateMagicLink = async (uuid: string, languageCode: string) => {
  return authClient.post("api/v1/m/regenerate", { json: { parentUuid: uuid, languageCode } });
};

export const regenerateMagicLinkV2 = async (id: number, languageCode: string) => {
  return authClient.post("api/v2/regenerate-magic-link", { json: { id, languageCode } });
};

async function isInvalidAuthenticityToken(error: HTTPError) {
  if (error.response?.status === 422) {
    const text = await error.response?.text();
    return Boolean(text?.includes("ActionController::InvalidAuthenticityToken"));
  }

  return false;
}

export function generateXRequestId() {
  try {
    return crypto.randomUUID();
  } catch {
    sendWarningReport("Failed to generate X-Request-ID");
    return "";
  }
}

export function setXRequestIdHeader(request: Request) {
  request.headers.set("X-Request-ID", generateXRequestId());
}
