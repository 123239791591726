import { Expose } from "class-transformer";
import { immerable } from "immer";
import { fromJson } from "../../api/model/json";

export type Jurisdiction = "FR" | "ES" | "PT" | "NL" | "AT" | "DE";
type JurisdictionDocument = "conditional_fee_agreement" | "power_of_attorney";

class LegalFunnelDetails {
  [immerable] = true;

  @Expose() fastTrack: boolean;
  @Expose() jurisdiction: Jurisdiction;
  @Expose() legalEligible: boolean;
  @Expose() jurisdictionalPrerequisites: JurisdictionDocument[];

  @Expose() boardingPassRequired: boolean;
  @Expose() conditionalFeeAgreementRequired: boolean;
  @Expose() consentForSignatureReuseRequired: boolean;
  @Expose() eticketForAllPassengersRequired: boolean;
  @Expose() eticketForReroutingRequired: boolean;
  @Expose() passportOrIdForAllPassengersRequired: boolean;
  @Expose() powerOfAttorneyRequired: boolean;
  @Expose() professionOfAllPassengersRequired: boolean;
  @Expose() proofOfDelayRequired: boolean;
  @Expose() reroutingConfirmationRequired: boolean;

  static fromJson(funnelDetails: any) {
    return fromJson(LegalFunnelDetails, funnelDetails);
  }
}

export default LegalFunnelDetails;
