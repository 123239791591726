import { useTranslation } from "react-i18next";
import FooterBrandDisclaimer from "../brand-disclaimer/FooterBrandDisclaimer";
import styles from "./FooterContent.module.scss";
import FooterLinks from "../links/FooterLinks";
import FunnelTrustpilot from "../../../components/ui-elements/trustpilot/FunnelTrustpilot";
import PoweredByLogo from "../../../components/ui-elements/powered-by/PoweredByLogo";
import { Flex } from "@chakra-ui/react";

const FooterContent = () => {
  const { t } = useTranslation();
  const year = new Date().getFullYear();

  return (
    <div className={styles.footer}>
      <div className={styles.mainSection}>
        <Flex align="center">
          <PoweredByLogo className={styles.poweredByLogo} />
          <FooterLinks />
        </Flex>
        <Flex align="center">
          <FunnelTrustpilot mr={12} display={{ base: "none", md: "flex" }} />
          <div className={styles.copyrightSection}>{t("footer.copyrights", { year })}</div>
        </Flex>
      </div>
      <FooterBrandDisclaimer />
    </div>
  );
};

export default FooterContent;
