import React from "react";
import { GuardFunction } from "../useFunnelGuard";

type NextStepFunction<Params = any> = (data: {
  params?: Params;
}) => string | FunnelDefinition | null;
export type FunnelNextStep<Params = any> =
  | string
  | FunnelDefinition
  | NextStepFunction<Params>
  | null;

/**
 * Funnel step definition abstraction. Allows to define route and component of the given claim and provides information
 * about which step should be displayed first.
 */
export type FunnelStepDefinition<NextStepParams = any> = {
  /**
   * Logical `id` of the step. Should be used in next param in order to properly identify which step should be called next.
   */
  id: string;

  /**
   * Query params to be appended to given route. This functionality allows us to include additional information
   * needed to properly display step. An example and only usage is fellow passport id step in post submission funnel
   * where query params decides to which fellow document will be uploaded.
   */
  queryParams?: Record<string, string>;

  /**
   * Prop representing next page where user should be redirected. It accepts 4 types:
   *
   *  - `string` - id of the next step
   *  - `FunnelDefinition` - new definition that should be registered and where user will be redirected to (see duplicated claim funnel for an example)
   *  - function returning `string` or `FunnelDefinition` - we can pass additional parameters here and dynamically decide which step is next (see email step in `Ec261` funnel for an example)
   *  - null - when there is no next step
   */
  next?: FunnelNextStep<NextStepParams>;

  /**
   * Prop representing previous page where user should be redirected after clicking
   * the back button. It accepts the same types as the "next" prop.
   *
   */
  previous?: FunnelNextStep;

  /**
   * Path of the step. Route with component will be registered under this path, also when calling `nextStep` function
   * user will be redirected to the path of next step.
   */
  path: string;

  /**
   * Specifies which component should be rendered for given path. Allows us to parametrize it.
   */
  component: React.ComponentType;
};

/**
 * Base abstraction over funnel. It allows to create funnel definition via JS object which later can be
 * registered in `FunnelDefinitionContext`.
 */
export type FunnelDefinition = {
  /**
   * Name of the funnel. Might be used by tracking tool to determine which funnel is currently active.
   */
  name: string;

  /**
   * FIXME: To be removed in https://jira.airhelp.net/browse/AIR-39879.
   */
  firstStep?: string;

  /**
   * Array of step definitions. The order is not really important, but might help to understand
   * when they will appear to the user.
   */
  steps: FunnelStepDefinition[];

  /**
   * Function that checks if the user is allowed to be in the specific step and might redirect to the correct place.
   * An example usage is to check if user with submitted claim is not trying to enter pre submission funnel.
   */
  guard?: GuardFunction;

  /**
   * A meta flow, for example, barcode funnel can enter the happy or unhappy flow, this metadata contains such information.
   */
  flow?: string;
};

export const isFunnelDefinition = (definition: any): definition is FunnelDefinition => {
  return (definition as FunnelDefinition)?.steps !== undefined;
};
