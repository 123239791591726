const DEFAULT_LABEL_NAME = "Expedia";

const BRAND_LABEL_MAP = {
  AirAsiaGo: [
    "airasiago_co_id",
    "airasiago_com_au",
    "airasiago_com_hk",
    "airasiago_com_my",
    "airasiago_com_sg",
    "airasiago_jp",
    "china_airasiago_com",
    "thailand_airasiago_com",
  ],
  "Cheaptickets.com": ["cheaptickets_com"],
  Ebookers: [
    "ebookers_fi",
    "ebookers_fr",
    "ebookers_de",
    "ebookers_ie",
    "ebookers_com",
    "ebookers_ch",
  ],
  Egencia: [
    "action_travel_argentina",
    "ect_australia",
    "ect_belgium",
    "ect_canada",
    "ect_china",
    "ect_czech",
    "ect_denmark",
    "ect_finland",
    "ect_france",
    "ect_germany",
    "ect_hong_kong",
    "ect_india",
    "ect_ireland",
    "ect_italy",
    "ect_netherlands",
    "ect_new_zealand",
    "ect_norway",
    "ect_philippines",
    "ect_poland",
    "ect_singapore",
    "ect_spain",
    "ect_sweden",
    "ect_switzerland",
    "ect_turkey",
    "ect_united_kingdom",
    "ect_united_states",
    "egencia",
  ],
  "Hotels.com": ["travel_hotels_com"],
  "Hotwire.com": ["www_hotwire_com"],
  "Lastminute.co.nz": ["lastminute_co_nz"],
  "Lastminute.com.au": ["lastminute_com_au"],
  "Mrjet.se": ["mrjet_se"],
  Orbitz: ["orbitz_com"],
  Travelocity: ["travelocity_ca", "travelocity_com"],
  "Voyages-sncf.com": ["voyages_sncf_com"],
  Wotif: ["wotif_co_nz", "wotif_com"],
} as { [index: string]: string[] };

const findBrandLabel = (brandSlug: string): string =>
  Object.keys(BRAND_LABEL_MAP).find((label) => BRAND_LABEL_MAP[label].includes(brandSlug)) ||
  DEFAULT_LABEL_NAME;

export default findBrandLabel;
