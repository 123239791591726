export function capitalize(word?: string) {
  if (word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }
}

export const camelize = (value: string, firstLetterUppercase?: boolean): string => {
  return value
    .replace(/(?:^|[-_])(\w)/g, (stripped, letter) => (letter ? letter.toUpperCase() : ""))
    .replace(/(^\w)/, (letter) => (firstLetterUppercase ? letter : letter.toLowerCase()));
};
