import { useCallback } from "react";
import { useAppSettings } from "./AppSettingsContext";

const useToLocalNumber = () => {
  const { locale } = useAppSettings();

  return useCallback(
    (value: number) => {
      return new Intl.NumberFormat(locale).format(value);
    },
    [locale]
  );
};

export default useToLocalNumber;
