import React, { useContext, useState } from "react";
import Brand from "./Brand";
import BrandCustomizer from "./BrandCustomizer";
import dataLayer, { defaultDataLayerData } from "tracking/tools/dataLayer";

type BrandContextType = {
  brand?: Brand;
  updateBrand(brand: Brand | undefined): void;
};

const DEFAULT_BRAND_CONTEXT: BrandContextType = {
  updateBrand() {
    throw new Error("Brand should be used within a brand provider");
  },
};

export const BrandContext = React.createContext<BrandContextType>(DEFAULT_BRAND_CONTEXT);

export const BrandProvider = ({ children }: { children?: React.ReactNode }) => {
  const [brand, setBrand] = useState<Brand>();

  return (
    <BrandContext.Provider
      value={{
        brand,
        updateBrand: (newBrand: Brand | undefined) => {
          setBrand(newBrand);
          if (defaultDataLayerData.brand === undefined) dataLayer.setBrand(newBrand?.slug || null);
        },
      }}
    >
      <BrandCustomizer>{children}</BrandCustomizer>
    </BrandContext.Provider>
  );
};

export const useBrand = () => {
  const brandContext = useContext(BrandContext);

  return brandContext.brand;
};

export const useBrandUpdate = () => {
  const brandContext = useContext(BrandContext);
  return brandContext.updateBrand;
};
