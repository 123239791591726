export type ExperimentConfig = {
  name: string;
  id: number;
  variations: {
    [index: string]: number;
  };
  conversion_events?: {
    type: string;
    eventName: string;
  }[];
  /**
   * Use this option for experiments that are activated manually.
   * Also, the 'name' field should match the corresponding experiment name in Optimizely.
   */
  custom?: boolean;
};

export const OTA_MISSED_CONNECTION_EXPERIMENT_CONFIG: ExperimentConfig = {
  name: "OTA missed connection copy",
  id: 28070860071,
  variations: {
    28009180668: 0,
    28038730363: 1,
  },
};

export const AVATAR_ES_EXPERIMENT_CONFIG: ExperimentConfig = {
  name: "Avatar ES",
  id: 29689590113,
  variations: {
    29690250034: 0,
    29680550075: 1,
    29725120011: 2,
  },
};

export const SIGNATURE_STEP_REVAMP_EXPERIMENT_CONFIG: ExperimentConfig = {
  name: "Signature step revamp",
  id: 29655400474,
  variations: {
    29666040239: 0,
    29687580178: 1,
    29603820832: 2,
  },
  conversion_events: [
    {
      type: "event",
      eventName: "signatureStep_revamp_continueClicked",
    },
  ],
};

export const ACCOUNT_CREATION_EXPERIMENT_CONFIG: ExperimentConfig = {
  name: "Account creation",
  id: 28352041195,
  variations: {
    28356370808: 0,
    28370041094: 1,
    28464510246: 2,
  },
  conversion_events: [
    {
      type: "event",
      eventName: "summaryStep_customerDashboard_accountCreation",
    },
    {
      type: "event",
      eventName: "summaryStep_claimStatus_accountCreation",
    },
  ],
};

export const MOTIVATORS_EXPERIMENT_CONFIG: ExperimentConfig = {
  name: "Motivators on first funnel step and email step",
  id: 29730550301,
  variations: {
    29752030176: 0,
    29788940065: 1,
    29734870203: 2,
  },
  conversion_events: [
    {
      type: "event",
      eventName: "tripDetailsStep_motivators_continueClicked",
    },
    {
      type: "event",
      eventName: "emailStep_motivators_continueClicked",
    },
  ],
};

export const AH_PLUS_FALLBACK_OFFER_EXPERIMENT_CONFIG: ExperimentConfig = {
  name: "Fallback offer for AH Plus membership",
  id: 30128520218,
  variations: {
    30171010128: 1,
    30129990754: 2,
  },
  conversion_events: [
    {
      type: "event",
      eventName: "ahplusMembership_fallbackOffer_accepted",
    },
  ],
};
