import { useCallback } from "react";
import { useNavigate, useLocation, NavigateOptions } from "react-router-dom";
import { proxy, useSnapshot } from "valtio";
import { areLocationsDifferent, Location } from "../utils/navigation";

interface NavigationState {
  historyListener?: (event: PopStateEvent) => void;
  locationStack: Location[];
  guardIndex: number;
}

export const navigationState = proxy<NavigationState>({
  historyListener: undefined,
  locationStack: [],
  guardIndex: 0,
});

export function registerHistoryListener() {
  if (!navigationState.historyListener && typeof window !== "undefined") {
    const callback = () => {
      navigationState.locationStack.push({
        pathname: window.location.pathname,
        search: window.location.search,
        hash: window.location.hash,
      });
    };

    navigationState.historyListener = callback;
    window.addEventListener("popstate", callback);
  }
}

export const useNavigation = () => {
  const navigate = useNavigate();
  const currentLocation = useLocation();
  const { locationStack } = useSnapshot(navigationState);
  const lastLocation: Location | undefined =
    locationStack[locationStack.length === 1 ? 1 : locationStack.length - 2];

  const navigator =
    (
      navigationTrigger: (
        to: string | Partial<Location>,
        navigatorOptions?: NavigateOptions,
      ) => void,
    ) =>
    (to: Partial<Location>, navigatorOptions?: NavigateOptions) => {
      // Dedupe pushes to the same location
      if (areLocationsDifferent(currentLocation, to)) {
        navigationState.locationStack.push({
          pathname: to.pathname ?? "",
          search: to.search ?? "",
          hash: to.hash ?? "",
        });
      }
      navigationTrigger(to, navigatorOptions);
    };

  const push = navigator(navigate);
  const replace = navigator((to) => navigate(to, { replace: true }));

  const createNavigator = useCallback(
    (triggerNavigation: (to: Partial<Location>) => void) => (to: string | Partial<Location>) => {
      const targetLocationString =
        typeof to === "object" ? `${to.pathname}${to.search}${to.hash}` : to;
      const navigatingToDomain =
        targetLocationString.startsWith("http://") || targetLocationString.startsWith("https://");

      if (navigatingToDomain) {
        const currentAddress = `${window.location.protocol}//${window.location.hostname}${
          window.location.port ? ":" + window.location.port : ""
        }`;
        const destination = new URL(targetLocationString);
        const destinationAddress = `${destination.protocol}//${destination.hostname}`;

        if (currentAddress === destinationAddress) {
          return triggerNavigation({
            pathname: destination.pathname,
            search: destination.search,
            hash: destination.hash,
          });
        }

        return window.location.assign(targetLocationString);
      }

      const targetLocation = typeof to === "object" ? to : stringToLocation(to);
      triggerNavigation(targetLocation);
    },
    [],
  );

  const goTo = createNavigator(push);
  const replaceTo = createNavigator(replace);
  const goBack = () => window.history.back();
  const goForward = () => window.history.forward();

  return {
    goTo,
    replaceTo,
    goBack,
    goForward,
    locationHash: currentLocation.hash,
    currentLocation,
    lastLocation,
  };
};

export function stringToLocation(source: string) {
  const [pathname, rest] = source.split("?");
  const [search, hash] = rest ? rest.split("#") : [];

  return {
    pathname,
    search: search ? `?${search}` : "",
    hash: hash ? `#${hash}` : "",
  } as Location;
}
