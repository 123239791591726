import { useTranslation } from "react-i18next";
import { Box, BoxProps, Flex } from "@chakra-ui/react";
import { ConsumerRights } from "@airhelp/react";
import { SettingsModal } from "../funnel/modals/SettingsModal";
import { AppLogo } from "../funnel/ui-elements/AppLogo";

function Badge({ ...props }) {
  const { t } = useTranslation();
  return (
    <ConsumerRights
      data-testid="headerIncentive"
      text={t("components.rights_assurance.text")}
      {...props}
    />
  );
}

export function Header({ fullScreen = false, ...props }: BoxProps & { fullScreen?: boolean }) {
  return (
    <Box {...props}>
      <Box
        h={{ base: 14, sm: 16 }}
        maxW="720px"
        minW={{ base: "auto", md: "720px" }}
        m={{ base: "0 16px", md: "0 auto", xl: fullScreen ? "0 16px 0 auto" : "0" }}
      >
        <Flex w="100%" h="100%" justifyContent={{ base: "space-between", xl: "flex-end" }}>
          <AppLogo />
          <Flex alignItems="center" w="100%" justifyContent="flex-end">
            <Badge
              background="transparent!important"
              display={{ base: "none", sm: "flex" }}
              mr={{ base: 0, md: "12" }}
              w="fit-content"
            />
            <Flex mr={{ base: 0, md: 4, xl: 0 }} justifyContent="space-between" alignItems="center">
              <SettingsModal />
            </Flex>
          </Flex>
        </Flex>
      </Box>
      <Badge display={{ base: "flex", sm: "none" }} />
    </Box>
  );
}
