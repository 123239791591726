import { useRef } from "react";
import { Cookies } from "react-cookie";
import { Turnstile } from "@marsidev/react-turnstile";

export const TURNSTILE_COOKIE_NAME = "cf-turnstile-token" as const;

const TURNSTILE_ENABLED = import.meta.env.VITE_TURNSTILE_ENABLED === "true";
const TURNSTILE_SITE_KEY = import.meta.env.VITE_TURNSTILE_SITE_KEY || "";

export function TurnstileWidget() {
  const cookies = new Cookies();
  const ref = useRef();

  if (!TURNSTILE_ENABLED || !TURNSTILE_SITE_KEY) {
    return null;
  }

  return (
    <Turnstile
      ref={ref}
      siteKey={TURNSTILE_SITE_KEY}
      options={{
        size: "invisible",
        refreshExpired: "auto",
      }}
      onSuccess={(token) => {
        cookies.set(TURNSTILE_COOKIE_NAME, token);
      }}
    />
  );
}
