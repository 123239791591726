import styles from "./FooterLink.module.scss";
import classNames from "classnames";

const FooterLink = ({ title, link, className }: FooterLinkProps) => (
  <li className={classNames(styles.footerLink, className)}>
    <a href={link} target="_blank" rel="noopener noreferrer">
      {title}
    </a>
  </li>
);

type FooterLinkProps = {
  title: string;
  link: string;
  className?: string;
};

export default FooterLink;
