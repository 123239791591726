export const brazilTermsPath = Object.freeze({
  de: "brasilianische-agb",
  da: "brasilianske-vilkaar",
  en: "brazilian-terms",
  es: "terminos-y-condiciones-para-brasil",
  fi: "brasilian-ehdot",
  fr: "conditions-dutilisation-bresiliennes",
  el: "oroi",
  it: "termini-brasiliani",
  nl: "braziliaanse-termen",
  nb: "brasilianske-vilkar",
  pl: "warunki-umowy-dla-brazylii",
  pt: "termos-para-o-brasil",
  "pt-BR": "termos-brasil",
  ro: "termenii-pentru-brazilia",
  ru: "sroki",
  sv: "brasilianska-villkor",
  "zh-CN": "brazilian-terms",
  tr: "brazilian-terms",
});

export const brazilBrandTermsPath = Object.freeze({
  de: "brasilianische-agb",
  da: "brasilianske-vilkaar",
  en: "brazil-terms",
  es: "terminos-y-condiciones-para-brasil",
  fi: "brasilian-ehdot",
  fr: "conditions-dutilisation-bresiliennes",
  el: "oroi-brazilias",
  it: "termini-brasiliani",
  nl: "braziliaanse-termen",
  nb: "brasilianske-vilkar",
  pl: "warunki-umowy-dla-brazylii",
  pt: "termos-para-o-brasil",
  "pt-BR": "termos-para-o-brasil",
  ro: "termenii-pentru-brazilia",
  ru: "usloviya-dlya-brazilii",
  sv: "brasilianska-villkor",
  "zh-CN": "brazil-terms",
  tr: "brazil-terms",
});

export const brazilPriceListPath = Object.freeze({
  de: "brasilianische-preisliste",
  da: "brasiliansk-prisliste",
  en: "brazilian-price-list",
  es: "lista-de-precios-para-brasil",
  fi: "brasilian-hinnasto",
  fr: "grille-tarifaire-bresilienne",
  el: "timokatalogos",
  it: "costi-del-servizio-brasiliano",
  nl: "braziliaanse-prijslijst",
  nb: "brasiliansk-prisliste",
  pl: "cennik-dla-brazylii",
  pt: "lista-de-precos-para-o-brasil",
  "pt-BR": "precos-brasil",
  ro: "lista-de-preturi-pentru-brazilia",
  ru: "прайс-лист-для-бразилии",
  sv: "brasiliansk-prislista",
  "zh-CN": "brazilian-price-list",
  tr: "brazilian-price-list",
});
