import ClaimEnquiry from "../../model/claim-enquiry/ClaimEnquiry";
import User from "../../model/user/User";
import { immerable } from "immer";
import { Expose } from "class-transformer";
import { fromJson, toJson } from "./json";

export interface PhoneNumberRestWrapper {
  phone_number: PhoneNumberRest;
}

export class PhoneNumberRest {
  [immerable] = true;

  @Expose() id?: number;
  @Expose() number: string;
  @Expose({ name: "whatsapp_consent" }) whatsAppConsent?: boolean;

  static fromJson(response: any) {
    return fromJson(PhoneNumberRest, response);
  }

  toJson(claimEnquiry: ClaimEnquiry, user: User) {
    return {
      phone_number: {
        ...toJson(this),
        user_id: user.id,
        claim_enquiry_id: claimEnquiry.id,
      },
    };
  }
}
