import classNames from "classnames";
import Header from "../header/Header";
import FunnelStepHeader from "../../components/step/funnel-step/header/FunnelStepHeader";
import styles from "./Content.module.scss";
import { PropsWithChildren, useRef } from "react";
import { Box } from "@chakra-ui/react";
import { AlertContext } from "components/alerts/AlertContext";

const Content = ({ children, isScanner }: { isScanner: boolean } & PropsWithChildren) => {
  const alertRef = useRef(null);

  return (
    <div className={classNames(styles.content, isScanner && styles.scannerContent)}>
      {!isScanner && (
        <>
          <Header />
          <FunnelStepHeader className={styles.header} />

          <Box ref={alertRef}></Box>
        </>
      )}
      <AlertContext.Provider value={{ ref: alertRef }}>
        <div
          className={classNames(styles.contentWrapper, isScanner && styles.scannerContentWrapper)}
        >
          {children}
        </div>
      </AlertContext.Provider>
    </div>
  );
};

export default Content;
