import { useEffect, useMemo, useState } from "react";
import { useDisclosure } from "@chakra-ui/react";
import { LanguageSwitcherModal } from "@airhelp/react";
import { IconType } from "@airhelp/icons";
import { ListItem } from "@airhelp/react";
import {
  currencies,
  locales,
  Locale,
  mapLocaleToFlagKey,
  Currency,
} from "../../../app-settings/localesAndCurrencies";
import { useTranslation } from "react-i18next";
import { useAppSettings } from "../../../app-settings/AppSettingsContext";

type LocaleItem = {
  code: string;
  displayName: string;
  icon: IconType;
};

const mapLocaleItemsToListItems = (items: LocaleItem[]): ListItem[] => {
  return items.map(({ code, displayName, icon }) => ({
    id: code,
    name: displayName,
    icon,
  }));
};

const suggestedLocales: LocaleItem[] = [locales.en, locales["pt-BR"]];
const suggestedLocalesSettings: ListItem[] = mapLocaleItemsToListItems(suggestedLocales);
const currenciesSettings: ListItem[] = [
  {
    id: currencies.EUR.code,
    name: currencies.EUR.displayName,
  },
  {
    id: currencies.USD.code,
    name: currencies.USD.displayName,
  },
];

const wasCurrencySelected = (data: ListItem): boolean => {
  return currenciesSettings.map((currency) => currency.id).includes(data.id);
};

const SettingsModal = (): JSX.Element => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { locale, setLocale, currency, setCurrency } = useAppSettings();
  const [flagKey, setFlagKey] = useState<string>(mapLocaleToFlagKey(locale));
  const mapLocaleToActiveItem = (locale: Locale): string => {
    return locale.toLowerCase();
  };
  const [activeItems, setActiveItems] = useState<string[]>([
    mapLocaleToActiveItem(locale),
    currency,
  ]);
  const onItemClick = (data: ListItem): void => {
    if (wasCurrencySelected(data)) {
      setCurrency(data.id as Currency);
    } else {
      setLocale(data.id as Locale);
      setFlagKey(mapLocaleToFlagKey(data.id as Locale));
    }
    onClose();
  };

  const supportedLocales: LocaleItem[] = Object.values(locales).filter(
    (l) => suggestedLocales.indexOf(l) === -1,
  );

  const supportedLocalesSettings: ListItem[] = useMemo(
    () => mapLocaleItemsToListItems(supportedLocales),
    [supportedLocales],
  );

  const settingsModalContent = [
    {
      listHeading: t("footer.settings_modal.suggested_languages"),
      listItems: suggestedLocalesSettings,
    },
    {
      listHeading: t("footer.settings_modal.select_language"),
      listItems: supportedLocalesSettings,
      listAutoflow: { base: "row", lg: "column" },
    },
    {
      listHeading: t("footer.settings_modal.select_currency"),
      listItems: currenciesSettings,
    },
  ];

  useEffect(() => {
    setActiveItems([locale, currency]);
    setFlagKey(mapLocaleToFlagKey(locale));
  }, [locale, currency, flagKey]);

  return (
    <LanguageSwitcherModal
      activeItems={activeItems}
      lists={settingsModalContent}
      flagKey={flagKey}
      onItemClick={onItemClick}
      onClose={onClose}
      onOpen={onOpen}
      isOpen={isOpen}
      heading=""
    />
  );
};

export default SettingsModal;
