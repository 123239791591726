import axios, { axiosCleanInstance } from "./axios";
import { fromJson, toJson } from "./model/json";
import UserRest from "./model/UserRest";

const registerUser = (user: UserRest): Promise<UserRest> => {
  return axios
    .post("/api/users", { user: toJson(user) })
    .then((response) => fromJson(UserRest, response.data.user));
};

const updateConsents = (
  privacyConsent: boolean | undefined,
  marketingConsent: boolean | undefined,
): Promise<UserRest> => {
  return axios.put("/api/users", {
    privacy_consent: privacyConsent,
    marketing_consent: marketingConsent,
  });
};

const registerUserV3 = (user: UserRest): Promise<UserRest> => {
  return axios
    .post("/api/v3/users", { user: toJson(user) })
    .then((response) => fromJson(UserRest, response.data.user));
};

const fetchLoggedUser = (): Promise<UserRest> => {
  return axiosCleanInstance
    .get("/api/users/me")
    .then((response) => fromJson(UserRest, response.data.user));
};

const usersApi = {
  fetchLoggedUser,
  registerUser,
  registerUserV3,
  updateConsents,
};

export default usersApi;
