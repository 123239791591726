export const defaultCompensation = 600;
export const feePercentage = 35;
export const minExtraExpensesCompensation = 200;
export const maxExtraExpensesCompensation = 6000;
export const maxLuggageCompensation = 1400;

export const goodwillServiceFee = 29.99;
export const maxGoodwillVouchersCompensation = 125;

export const defaultBrazilCompensation = 10000;
