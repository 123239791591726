import { Cookies } from "react-cookie";

export const getUtmParams = () => {
  const cookies = new Cookies();
  const encodedTrackingParams = cookies.get("tracking_params");

  if (!encodedTrackingParams) return {};

  const parsedTrackingParams: UtmParams = JSON.parse(atob(encodedTrackingParams));
  const utms = {
    utm_source: parsedTrackingParams.source,
    utm_medium: parsedTrackingParams.medium,
    utm_gclid: parsedTrackingParams.gclid,
  };

  return Object.fromEntries(Object.entries(utms).filter(([, v]) => v));
};

export type UtmParams = {
  source?: string;
  medium?: string;
  gclid?: string;
};
