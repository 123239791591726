import { LoaderFunctionArgs, redirect } from "react-router-dom";
import { PRE_ELIGIBILITY_BARCODE_FUNNEL_NAME } from "../funnels/pre-eligibility/barcode/builder/PreEligibilityBarcodeFunnelBuilder";
import {
  loadSavedPreEligibilitySession,
  clearPreEligibilitySavedSession,
} from "../initializers/useSavedPreEligibilitySession";
import { PRE_ELIGIBILITY_FUNNEL_NAME } from "../funnels/pre-eligibility/default/builder/PreEligibilyFunnelBuilder";

const RESTORE_PRE_ELIGIBILITY_SESSION_QUERY_PARAM = "restore-pre-eligibility-session";

export async function preEligibilityLoader({
  request,
}: LoaderFunctionArgs): Promise<null | ResponseInit> {
  const url = new URL(request.url);

  await checkSavedPreEligibilitySession(url);

  return null;
}

async function checkSavedPreEligibilitySession(url: URL) {
  const shouldLoadPreEligibilitySession = !!url.searchParams.get(
    RESTORE_PRE_ELIGIBILITY_SESSION_QUERY_PARAM,
  );

  if (shouldLoadPreEligibilitySession) {
    const savedFunnel = await loadSavedPreEligibilitySession();

    if (
      savedFunnel?.funnel === PRE_ELIGIBILITY_BARCODE_FUNNEL_NAME &&
      !url.pathname.startsWith("/claims/barcode")
    ) {
      // eslint-disable-next-line @typescript-eslint/only-throw-error
      throw redirect(`/claims/barcode?${RESTORE_PRE_ELIGIBILITY_SESSION_QUERY_PARAM}=true`);
    }

    if (
      savedFunnel?.funnel === PRE_ELIGIBILITY_FUNNEL_NAME &&
      !url.pathname.startsWith("/claims/new")
    ) {
      // eslint-disable-next-line @typescript-eslint/only-throw-error
      throw redirect(`/claims/new?${RESTORE_PRE_ELIGIBILITY_SESSION_QUERY_PARAM}=true`);
    }

    clearPreEligibilitySavedSession();
  }
}
