import { Cookies } from "react-cookie";
import { useLocation } from "react-router-dom";

const cookies = new Cookies();

const useReferrerInitializer = () => {
  const location = useLocation();

  return () => {
    const queryParams = new URLSearchParams(location.search);
    const referrer = queryParams.get("referrer") || document.referrer;
    const decodedReferrer = decodeURIComponent(referrer);

    try {
      sessionStorage.setItem("funnel_referrer", decodedReferrer);
    } catch {
      cookies.set("funnel_referrer", decodedReferrer);
    }
  };
};

export default useReferrerInitializer;
