import { ClaimFellowPassenger } from "../fellow-passenger/ClaimFellowPassenger";
import { ClaimType } from "./ClaimEnquiry";
import { Expose, Transform, Type } from "class-transformer";
import { fromJson, PLAIN_TO_DATE_TRANSFORMER } from "api/model/json";
import Itinerary from "../itinerary-flight/Itinerary";

type AirlineRequiredDocumentType = "eticket_for_all_passengers" | "passport_or_id";

class Claimant {
  @Expose() fullName: string;
}

class Claim {
  @Expose() id: number;
  @Expose() airlineRequiredDocuments: AirlineRequiredDocumentType[];
  @Expose({ name: "bookingRef" }) bookingReference: string;
  @Expose() claimType: ClaimType;
  @Expose() claimantFirstName: string;
  @Expose() claimantLastName: string;
  @Expose() claimantId: number;
  @Expose() @Type(() => Claimant) claimant: Claimant;
  @Expose() @Type(() => Itinerary) itinerary: Itinerary;
  @Expose() @Type(() => ClaimFellowPassenger) fellowPassengers: ClaimFellowPassenger[];
  @Expose() @Transform(PLAIN_TO_DATE_TRANSFORMER, { toClassOnly: true }) createdAt: Date;

  get isETicketRequired() {
    return this.airlineRequiredDocuments?.includes("eticket_for_all_passengers");
  }

  get isIdOrPassportRequired() {
    return this.airlineRequiredDocuments?.includes("passport_or_id");
  }

  get disruptedFlight() {
    return this.itinerary.flights.find((f) => f.disrupted)!;
  }

  get claimantFullName() {
    return this.claimant.fullName;
  }

  static fromJson(claim: any) {
    return fromJson(Claim, claim);
  }
}

export default Claim;
