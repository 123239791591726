import {
  getVariation,
  handleOptimizelyLoad,
  handleOptimizelyLoadError,
  optimizelySnapshot,
  startOptimizelyLoading,
  useOptimizelySnapshot,
} from "./optimizelyState";
import { addExternalScript } from "utils/addExternalScript";
import { useAppSettings } from "app-settings/AppSettingsContext";
import { ExperimentConfig } from "ab-tests/optimizely-experiments";
import { pushOptimizelyEvent } from "./events";
import useEffectOnce from "hooks/useEffectOnce";
import { sendWarningReport } from "../../../utils/reporting";
import { appSettingsSnapshot } from "../../../v2/settings";

const { VITE_OPTIMIZELY_URL } = import.meta.env;

export function getOptimizely() {
  window["optimizely"] = window["optimizely"] || [];
  return window["optimizely"];
}

function checkAndSetOptimizelyCookie() {
  const isLocalhostCookieSet = document.cookie.indexOf("optly_localhost_test") !== -1;
  const isStagingOrProdCookieSet = document.cookie.indexOf("optly_airhelp_test") !== -1;

  if (isLocalhostCookieSet || isStagingOrProdCookieSet) {
    window["optimizely"] = window["optimizely"] || [];
    window["optimizely"].push({
      type: "user",
      attributes: {
        test_by_cookie: true,
      },
    });
  }
}

export function useInitOptimizely() {
  const { locale } = useAppSettings();
  const { isLoaded } = optimizelySnapshot();

  useEffectOnce(() => {
    if (isLoaded || locale !== "en") return;
    startOptimizelyLoading();
    addExternalScript({
      url: VITE_OPTIMIZELY_URL,
      customOnLoad: handleOptimizelyLoad,
      customOnError: handleOptimizelyLoadError,
    }).catch((error) => {
      sendWarningReport(error);
    });
  });
}

export async function loadOptimizely() {
  const { locale } = appSettingsSnapshot();
  const { isLoaded } = optimizelySnapshot();

  if (isLoaded || locale !== "en") {
    return;
  }

  checkAndSetOptimizelyCookie();
  startOptimizelyLoading();
  return addExternalScript({
    url: VITE_OPTIMIZELY_URL,
    customOnLoad: handleOptimizelyLoad,
    customOnError: handleOptimizelyLoadError,
  }).catch((error) => {
    sendWarningReport(error);
  });
}

export function activateExperiment(experiment: ExperimentConfig) {
  pushOptimizelyEvent({
    type: "page",
    pageName: "24813410713_url_targeting_for_" + experiment.name.toLowerCase().replace(/ /g, "_"),
  });
}

export default function useOptimizely() {
  const { isLoading, isLoaded } = useOptimizelySnapshot();
  const { locale } = useAppSettings();

  return {
    getVariation: (experimentConfig: ExperimentConfig, paramName?: string) =>
      getVariation(experimentConfig, locale, paramName),
    isLoaded,
    isLoading,
  };
}
