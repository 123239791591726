import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { setLocale } from "yup";
import HttpBackend from "i18next-http-backend";

// Fixes return type of t() function, this types the returnNull: false
// in the init call below, this is not an empty override.
declare module "i18next" {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

i18n.use(initReactI18next); // passes i18n down to react-i18next

if (import.meta.env.MODE !== "test") {
  i18n.use(HttpBackend);
}

function setupYupTranslations() {
  setLocale({
    mixed: {
      required: "validation.mixed.required",
    },
    string: {
      min: ({ min }: { min: number }) => ({
        key: "validation.string.min",
        options: {
          min,
        },
      }),
      max: ({ max }: { max: number }) => ({
        key: "validation.string.max",
        options: {
          max,
        },
      }),
    },
  });
}

export const translationInit = new Promise((resolve) => {
  setupYupTranslations();

  i18n.init(
    {
      lng: "en",
      fallbackLng: "en",
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
      load: "currentOnly",
      backend: {
        loadPath: (languages: string[]) => {
          const lng = languages[0] === "zh-CN" ? "zh" : languages[0];
          return `/locales/${lng}.json`;
        },
      },
      react: {
        useSuspense: false,
      },
      returnNull: false,
    },
    (error, t) => {
      resolve({ error, t });
    },
  );
});

export function translationInitV2(language = "en") {
  return i18n.init(
    {
      lng: language,
      fallbackLng: "en",
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
      load: "currentOnly",
      backend: {
        loadPath: (languages: string[]) => {
          const lng = languages[0] === "zh-CN" ? "zh" : languages[0];
          return `/locales/${lng}.json`;
        },
      },
      react: {
        useSuspense: false,
      },
      returnNull: false,
    },
    () => {
      // TODO: Remove this when v1 architecture will be removed. It forces language switch
      i18n.changeLanguage(language);
    },
  );
}

export default i18n;
