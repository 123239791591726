import React, { useContext, useState } from "react";
import Theme from "./Theme";

type ThemeContextType = {
  theme?: Theme;
  setTheme(theme?: Theme): void;
};

export const ThemeContext = React.createContext<ThemeContextType | undefined>(undefined);

export const ThemeProvider = ({ children }: { children?: React.ReactNode }) => {
  const [theme, setTheme] = useState<Theme>();

  return <ThemeContext.Provider value={{ theme, setTheme }}>{children}</ThemeContext.Provider>;
};

export const useSetTheme = () => {
  const themeContext = useContext(ThemeContext);

  if (!themeContext) {
    throw new Error("useSetTheme must be used within a ThemeProvider");
  }

  return themeContext.setTheme;
};

const useIsTheme = (theme: Theme) => {
  const themeContext = useContext(ThemeContext);

  if (!themeContext) {
    throw new Error("useIsTheme must be used within a ThemeProvider");
  }

  return themeContext.theme === theme;
};

export default useIsTheme;
