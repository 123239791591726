import { ComponentType, PropsWithChildren, ReactNode, useState } from "react";
import { Avatar, Box, Flex, FlexProps, Text } from "@chakra-ui/react";
import { Footer } from "./Footer";
import { Header } from "./Header";
import humanAgent from "../../../assets/images/avatars/avatar.webp";
import humanAgent1 from "../../../assets/images/avatars/avatar_1.webp";
import { useTranslation } from "react-i18next";

import { Cookies } from "react-cookie";
import { TFunction } from "i18next";
import humanAgent2 from "../../../assets/images/avatars/avatar_2.webp";
import useEffectOnce from "../../../hooks/useEffectOnce";
import { AVATAR_ES_EXPERIMENT_CONFIG } from "../../../ab-tests/optimizely-experiments";
import { getVariation } from "../../../tracking/tools/optimizely/optimizelyState";

const cookies = new Cookies();

export function getAvatar(locale: string, t: TFunction, experimentVariation?: number | null) {
  switch (locale) {
    case "es":
      switch (experimentVariation) {
        case 1:
          return { src: humanAgent, name: "Sofia" };
        case 2:
          return { src: humanAgent2, name: "Sofia" };
        default:
          return { src: humanAgent, name: t("components.avatar.name") };
      }
    case "fr":
      return { src: humanAgent1, name: "Élise" };
    case "de":
      return { src: humanAgent1, name: "Anna" };
    default:
      return { src: humanAgent, name: t("components.avatar.name") };
  }
}

function AvatarBox() {
  const { t } = useTranslation();
  const locale = cookies.get("initial_language");
  const [avatarEsVariation, setAvatarEsVariation] = useState<number | null>();

  const avatar = getAvatar(locale, t, getLocalisedExperimentVariation(locale));

  function getLocalisedExperimentVariation(locale: string) {
    if (locale === "es") {
      return avatarEsVariation;
    }
  }

  useEffectOnce(() => {
    setAvatarEsVariation(getVariation(AVATAR_ES_EXPERIMENT_CONFIG, locale));
  });

  return (
    <Box m={{ base: "24px 16px", md: "24px 0" }} mt={{ md: 12 }}>
      <Flex mb={6} data-testid="funnelAvatar">
        <Avatar mr={4} boxSize={12} src={avatar.src} bg="transparent" />
        <Flex direction="column" justify="center">
          <Text variant="medium" fontWeight="bold">
            {avatar.name}
          </Text>
          <Text fontSize="sm">{t("components.avatar.role")}</Text>
        </Flex>
      </Flex>
    </Box>
  );
}

export function Content({
  children,
  avatar = <AvatarBox />,
  wrapper: ContentWrapper = DefaultContentWrapper,
  fullScreen = false,
}: PropsWithChildren<{
  avatar?: ReactNode;
  wrapper?: ComponentType<PropsWithChildren>;
  alertContainer?: ReactNode;
  fullScreen?: boolean;
}>) {
  return (
    <ContentWrapper>
      <Header fullScreen={fullScreen} />
      {avatar}
      <Box m={{ base: "0 16px", md: "0" }} flex={1}>
        {children}
      </Box>
    </ContentWrapper>
  );
}

function DefaultContentWrapper({ children, ...props }: FlexProps) {
  return (
    <Flex
      flex={5}
      direction="column"
      justifyContent="space-between"
      alignItems={{ base: "center", xl: "flex-start" }}
      w="100%"
      {...props}
    >
      <Box ml={{ base: 0, xl: "12%" }} mb="10" w="100%" maxWidth="704px" flex="1 0 auto">
        {children}
      </Box>
      <Footer />
    </Flex>
  );
}
