import React from "react";
import "reflect-metadata";
import { setAutoFreeze } from "immer";
import "./index.scss";
import "./i18n";
import "./i18n-countries";
import { router } from "./routes";
import TrackingScripts from "./tracking/TrackingScripts";
import { registerHistoryListener } from "./hooks/useNavigation";
import { createRoot } from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { setupZod } from "./v2/utils/zod";
import { initSentry } from "./sentry";
import { initPolyfills } from "polyfills";
import { TurnstileWidget } from "./v2/modules/security/TurnstileWidget";

initPolyfills();
initSentry();
setupZod();
setAutoFreeze(false);
registerHistoryListener();

const container = document.getElementById("root");
const root = createRoot(container as Element);

root.render(
  <React.StrictMode>
    <TurnstileWidget />
    <TrackingScripts />
    <RouterProvider router={router} />
  </React.StrictMode>
);
