import { ReactNode, useMemo } from "react";
import { useBrandSnapshot } from "../../modules/branding";
import { useTranslation } from "react-i18next";
import { useApplicationLinks } from "../../settings/applicationLinks";
import { Box, Flex, Link } from "@chakra-ui/react";
import { FunnelTrustpilot } from "../funnel/ui-elements/FunnelTrustpilot";
import { FooterBrandDisclaimer } from "./FooterBrandDisclaimer";
import { PoweredByLogo } from "./PoweredByLogo";

export function Footer() {
  const year = new Date().getFullYear();
  const footerLinks = useFooterLinks();
  const { t } = useTranslation();
  const brand = useBrandSnapshot();

  return (
    <Flex
      w="100%"
      direction="column"
      display="block"
      borderTop="1px"
      borderColor={"greyscale.400"}
      justifyContent={"space-between"}
      p={{ base: "20px 16px", sm: "20px 56px", xl: "20px 32px" }}
    >
      <Flex width="100%">
        {brand && (
          <Flex display={{ base: "block", md: "none" }}>
            <PoweredByLogo />
          </Flex>
        )}
        <Flex justifyContent={{ base: "flex-end", md: "space-between" }} width="100%">
          <Flex align="flex-start" display={{ base: "none", md: "flex" }} mr="-8">
            {footerLinks.map(({ link, title }) => {
              return link && <FooterLink key={link} link={link} title={t(title)} />;
            })}
          </Flex>

          <Flex align="center">
            <FunnelTrustpilot mr={12} display={{ base: "none", md: "flex" }} />
            <Box color="greyscale.600" fontSize="sm" textAlign="right">
              {t("footer.copyrights", { year })}
            </Box>
          </Flex>
        </Flex>
      </Flex>
      <FooterBrandDisclaimer />
    </Flex>
  );
}

function FooterLink({ link, title }: { link: string; title: ReactNode }) {
  return (
    <Box mr={8}>
      <Link
        w="auto"
        p="3 0"
        fontWeight="normal"
        color={"var(--font-color-primary)"}
        isExternal
        href={link}
        fontSize={14}
        _visited={{ color: "var(--font-color-primary)" }}
        _hover={{ color: "greyscale.600" }}
      >
        {title}
      </Link>
    </Box>
  );
}

function useFooterLinks() {
  const links = useApplicationLinks();
  const brand = useBrandSnapshot();
  return useMemo(
    () => [
      {
        title: "footer.help",
        link: links.help,
      },
      {
        title: "footer.terms_and_conditions",
        link: links.termsAndConditions,
      },
      {
        title: "footer.contact_brand",
        link: links.contactBrand,
      },
      {
        title: "footer.price_list",
        link: brand && links.priceList,
      },
      {
        title: "footer.privacy_policy",
        link: !brand && links.privacyPolicy,
      },
    ],
    [links, brand],
  );
}
