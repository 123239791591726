import { AppSettingsContextType } from "../../app-settings/AppSettingsContext";
import { CompensationInCurrency, Locale } from "../../app-settings/localesAndCurrencies";
import Brand from "../../brand/Brand";
import ClaimEnquiry, {
  AirlineReason,
  ClaimChannel,
  ClaimType,
  Delay,
  DisruptionMins,
  InformedBeforehandDays,
  mapDelayToDisruptionMins,
} from "../../model/claim-enquiry/ClaimEnquiry";
import LegalFunnelDetails from "../../model/claim-enquiry/LegalFunnelDetails";
import ProcessingRegulation from "../../model/claim-enquiry/ProcessingRegulation";
import User from "../../model/user/User";
import { AddressRest } from "./AddressRest";
import { FellowPassengerRest } from "./FellowPassengerRest";
import { fromJson, toJson } from "./json";
import { PhoneNumberRest } from "./PhoneNumberRest";
import UserRest from "./UserRest";
import Claim from "../../model/claim-enquiry/Claim";
import Itinerary from "../../model/itinerary-flight/Itinerary";
import { Expose, Transform, Type } from "class-transformer";
import OtaLead from "../../model/ota-lead/OtaLead";
import { DocumentsRaw } from "../../model/claim-enquiry/documents/Documents";
import SimilarClaim from "../../model/claim-enquiry/post/SimilarClaim";
import { immerable } from "immer";

export class ClaimEnquiryRest {
  [immerable] = true;

  @Expose() @Type(() => Brand) brand?: Brand;
  @Expose() ahplus?: boolean;
  @Expose() ahplusSelfAssigned?: boolean;
  @Expose()
  @Transform(({ value }) => value ?? "no_remember", { toPlainOnly: true })
  airlineReason?: AirlineReason;
  @Expose() bookingRef?: string;
  @Expose() bookingSourceIdentifier?: string;
  @Expose() channel?: ClaimChannel;
  @Expose() @Type(() => Claim) claim?: Claim;
  @Expose() claimId?: number;
  @Expose() claimType?: ClaimType;
  @Expose() claimValid?: boolean;
  @Expose() @Type(() => LegalFunnelDetails) claimEnquiryFunnelDetails?: LegalFunnelDetails;
  @Expose() compensation?: number;
  @Expose({ toClassOnly: true })
  @Type(() => CompensationInCurrency)
  @Transform(({ value }) => value ?? {})
  compensationInCurrencies: CompensationInCurrency;
  @Expose() delay?: Delay;
  disruptionMins?: DisruptionMins;
  @Expose() eligibilityCheckResult?: string;
  @Expose() eligibleOnHold?: boolean;
  @Expose() extraExpensesEnabled?: boolean;
  @Expose() goodwillPaymentCreditCard?: boolean;
  @Expose() id?: number;
  informedBeforehandDays?: InformedBeforehandDays;
  @Expose() @Type(() => Itinerary) itinerary?: Itinerary;
  @Expose() itineraryCompensation?: number;
  @Expose() processingRegulation?: ProcessingRegulation;
  @Expose() wasSigned?: boolean;
  @Expose() uuid?: string;
  @Expose() firstName?: string;
  @Expose() lastName?: string;
  @Expose() locale?: Locale;
  @Expose() revenue?: number;
  @Expose() marketingServiceFeePercentage?: number;
  @Expose() otaLeadId?: string;
  @Expose() otaCampaignIdentifier?: string;
  @Expose()
  @Type(() => UserRest)
  @Transform(({ value }) => value ?? new UserRest())
  user: UserRest;
  @Expose()
  @Type(() => PhoneNumberRest)
  @Transform(({ value }) => value ?? new PhoneNumberRest())
  phoneNumber: PhoneNumberRest;
  @Expose()
  @Type(() => AddressRest)
  @Transform(({ value }) => value ?? new AddressRest())
  address: AddressRest;
  @Expose()
  @Type(() => FellowPassengerRest)
  @Transform(({ value }) => value ?? [])
  fellowPassengers: FellowPassengerRest[];
  @Expose() voluntarilySurrendered?: boolean;
  @Expose() whatHappened?: string;
  @Expose() offeredRerouting?: boolean;
  requireReroutingInfo?: boolean;
  @Expose() missedConnection?: boolean;
  @Expose() optionalQuestionsId?: number;
  @Expose({ toClassOnly: true }) @Type(() => SimilarClaim) similarClaim?: SimilarClaim;
  otaLead?: OtaLead;
  documents: DocumentsRaw = {};
  bookingReferenceProvidedByUser = false;
  prefilled = false;
  @Expose() experiment?: string;

  static fromJson(data: any) {
    return fromJson(ClaimEnquiryRest, data);
  }

  toJson(enquiry: ClaimEnquiry, settings: AppSettingsContextType, brand?: Brand, user?: User) {
    const transformedEnquiry = toJson(this);
    return {
      claim_enquiry: {
        ...transformedEnquiry,
        disruption_mins: enquiry.delay && mapDelayToDisruptionMins(enquiry.delay),
        informed_beforehand_days: enquiry.informedBeforehandDays,
        airline_reason:
          enquiry.claimType === "overbooked" ? "overbooked" : transformedEnquiry.airline_reason,
        brand_slug: brand?.slug,
        locale: settings.locale,
        user_preferred_currency: settings.currency,
        first_name: enquiry.contact.firstName,
        last_name: enquiry.contact.lastName,
        user: user ? toJson(user) : undefined,
        ...otaEnhancements(enquiry),
      },
    };
  }
}

const otaEnhancements = (
  claimEnquiry: ClaimEnquiry,
): { ota_lead_id?: string; ota_campaign_identifier?: string } => {
  if (claimEnquiry.isOta()) {
    return {
      ota_lead_id: claimEnquiry.otaLead?.id,
      ota_campaign_identifier: claimEnquiry.otaCampaignIdentifier,
    };
  }

  return {};
};
