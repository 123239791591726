import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useBrand } from "./BrandContext";
import { generatePalette } from "../v2/modules/branding/useFunnelTheme";

const BrandCustomizer = ({ children }: { children?: React.ReactNode }) => {
  const { t } = useTranslation();
  const brand = useBrand();
  const [primaryPalette, setPrimaryPalette] = useState<Record<string, string>>({});
  const [secondaryPalette, setSecondaryPalette] = useState<Record<string, string>>({});

  useEffect(() => {
    (async () => {
      if (brand?.primaryColor) {
        const primaryColor = generatePalette(brand?.primaryColor);
        setPrimaryPalette(primaryColor);
      }
      if (brand?.secondaryColor) {
        const secondaryColor = generatePalette(brand?.secondaryColor);
        setSecondaryPalette(secondaryColor);
      } else if (brand?.primaryColor) {
        const primaryColor = generatePalette(brand?.primaryColor);
        setSecondaryPalette(primaryColor);
      }
    })();
  }, [brand, setPrimaryPalette]);

  return (
    <>
      {brand && (
        // @ts-ignore
        <Helmet>
          <style type="text/css">
            {`
            :root {
              --chakra-colors-font-primary: var(--color-greyscale-900);
            
              --chakra-colors-primary-100: ${primaryPalette[100]};
              --chakra-colors-primary-200: ${primaryPalette[200]};
              --chakra-colors-primary-300: ${primaryPalette[300]};
              --chakra-colors-primary-400: ${primaryPalette[400]};
              --chakra-colors-primary-500: ${primaryPalette[500]};
              --chakra-colors-primary-600: ${primaryPalette[600]};
              --chakra-colors-primary-700: ${primaryPalette[700]};
              --chakra-colors-primary-800: ${primaryPalette[800]};
              --chakra-colors-primary-900: ${primaryPalette[900]};
              
              --chakra-colors-secondary-100: ${secondaryPalette[100]};
              --chakra-colors-secondary-200: ${secondaryPalette[200]};
              --chakra-colors-secondary-300: ${secondaryPalette[300]};
              --chakra-colors-secondary-400: ${secondaryPalette[400]};
              --chakra-colors-secondary-500: ${secondaryPalette[500]};
              --chakra-colors-secondary-600: ${secondaryPalette[600]};
              --chakra-colors-secondary-700: ${secondaryPalette[700]};
              --chakra-colors-secondary-800: ${secondaryPalette[800]};
              --chakra-colors-secondary-900: ${secondaryPalette[900]};
            }
        `}
          </style>
          <title>{t("brand_title", { brand: brand.name })}</title>
        </Helmet>
      )}
      {children}
    </>
  );
};

export default BrandCustomizer;
