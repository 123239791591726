import {
  Airline,
  Airport,
  BarcodeLeadsFlightResponse,
  FlightSelectorFlight,
} from "../../types/intake-funnel";
import { RawAirline } from "../../../api/airlines";
import { RawAirport } from "../../../api/airports";
import { RawFlight } from "v2/api/flights";
import { RawBarcodeLeadsFlight } from "v2/api/barcode-leads";
import { Nullable } from "__tests-utils__/types";

export function extractAirlineData(rawAirline: RawAirline): Airline {
  return {
    iata: rawAirline.iata,
    icao: rawAirline.icao,
    name: rawAirline.name,
    code: rawAirline.code || "",
    ec261: rawAirline.ec261,
    countryCode: rawAirline.country_code,
    displayName: rawAirline.display_name,
    uniqueIdentifier: rawAirline.unique_identifier,
  };
}

export function extractAirportData(rawAirport: RawAirport): Airport {
  return {
    iata: rawAirport.iata,
    name: rawAirport.name,
    rank: rawAirport.rank,
    city: rawAirport.city,
    ec261: rawAirport.ec261,
    country: rawAirport.country,
    countryCode: rawAirport.country_code,
    timeZone: rawAirport.time_zone,
    latitude: rawAirport.latitude,
    longitude: rawAirport.longitude,
    searchText: rawAirport.search_text,
    cityTranslations: rawAirport.city_translations,
  };
}

export function extractFlightsData(rawFlight: RawFlight): Nullable<FlightSelectorFlight> {
  return {
    flightNumber: rawFlight.flight_number,
    localDepartureAt: rawFlight.local_departure_at ? new Date(rawFlight.local_departure_at) : null,
    localArrivalAt: rawFlight.local_arrival_at ? new Date(rawFlight.local_arrival_at) : null,
    airlineCode: rawFlight.airline_code,
    airlineIdentifier: rawFlight.airline_identifier,
    airlineName: rawFlight.airline_name,
    operatingAirlineCode: rawFlight.operating_airline_code,
    humanizedArrivalStatus: rawFlight.humanized_arrival_status,
  };
}

export function extractFlightData(data: RawBarcodeLeadsFlight): BarcodeLeadsFlightResponse {
  const eligibilityCheckResult = data?.eligibility_check_result;
  const itinerary = data?.itinerary?.flights.at(0);

  if (!itinerary) {
    throw new Error("No itinerary found in barcode leads response");
  }

  return {
    disrupted: itinerary.disrupted || undefined,
    localDepartureDate: date(itinerary.local_departure_date),
    flightIdentifier: itinerary.flight_identifier,
    arrivalTime: date(itinerary.arrival_time) || new Date(),
    departureTime: date(itinerary.departure_time) || new Date(),
    actualArrivalTime: date(itinerary.actual_arrival_time),
    actualDepartureTime: date(itinerary.actual_departure_time),
    informed: itinerary?.informed !== undefined ? itinerary.informed : undefined,
    flightNumber: itinerary.flight_number,
    delayMins: itinerary.delay_mins || undefined,
    distanceKm: itinerary.distance_km || undefined,
    recognized: itinerary.recognized,
    potentialRegulation: data?.potential_regulation,
    airline: {
      iata: itinerary.airline?.iata,
      name: itinerary.airline?.name,
      uniqueIdentifier: itinerary.airline?.unique_identifier,
    },
    arrivalAirport: {
      city: itinerary.arrival_airport?.city,
      iata: itinerary.arrival_airport?.iata,
      name: itinerary.arrival_airport?.name,
    },
    departureAirport: {
      city: itinerary.departure_airport?.city,
      iata: itinerary.departure_airport?.iata,
      name: itinerary.departure_airport?.name,
    },
    eligibilityCheckResult: {
      finalEligibility: eligibilityCheckResult?.final_eligibility,
      disruptionReason: eligibilityCheckResult?.disruption_reason,
      compensationInCurrencies: eligibilityCheckResult?.compensation_in_currencies,
      rejectionReason: eligibilityCheckResult?.rejection_reason,
      disruptedFlightIdentifier: itinerary.flight_identifier,
      standard: eligibilityCheckResult?.standard,
    },
  };
}

function date(value?: string | undefined | null) {
  return value ? new Date(value) : undefined;
}
