import { axiosAuthInstance } from "./axios";

const AUTH_API_VERSION = import.meta.env.VITE_AUTH_API_VERSION || "v1";

const refreshAccessToken = async () => {
  return axiosAuthInstance.post(
    `/api/${AUTH_API_VERSION}/auth/refresh`,
    {},
    {
      validateStatus(status) {
        return (status >= 200 && status < 300) || status === 400;
      },
    }
  );
};

const createUser = async (email: string, password: string) => {
  return axiosAuthInstance.post<{ uuid: string; email: string }>(`/api/${AUTH_API_VERSION}/users`, {
    email,
    password,
  });
};

const loginUser = async (email: string, password: string) => {
  return axiosAuthInstance.post(`/api/${AUTH_API_VERSION}/auth/login`, {
    email,
    password,
  });
};

const authApi = { refreshAccessToken, createUser, loginUser };

export default authApi;
