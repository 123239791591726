import { plainToClass } from "class-transformer";
import { produce } from "immer";
import React, { PropsWithChildren, useCallback, useContext, useMemo, useState } from "react";
import UserRest from "../../api/model/UserRest";
import User from "./User";

export type UpdateUser = (updateUserFunction: (draft: UserRest) => void) => User | undefined;

export const UserContext = React.createContext<UserRest | undefined>(undefined);
export const UserUpdateContext = React.createContext<UpdateUser | undefined>(undefined);

const DEFAULT_USER = plainToClass(UserRest, { personalDetails: {} });

const UserProvider = ({ children }: PropsWithChildren) => {
  const [user, setUser] = useState<UserRest>(DEFAULT_USER);
  const updateUser = useCallback(
    (updateUserFunction: any) => {
      const newUser = produce(user, updateUserFunction);
      setUser(newUser);

      return new User(newUser, updateUserFunction);
    },
    [user]
  );

  return (
    <UserContext.Provider value={user}>
      <UserUpdateContext.Provider value={updateUser}>{children}</UserUpdateContext.Provider>
    </UserContext.Provider>
  );
};

const useUserState = () => {
  const userRaw = useContext(UserContext);
  const updateUser = useContext(UserUpdateContext)!;
  const user = useMemo(
    () => (userRaw ? new User(userRaw, updateUser) : undefined),
    [userRaw, updateUser]
  );
  if (!user) {
    throw new Error("useUser must be used within a UserProvider");
  }

  return user;
};

const useUserUpdate = () => {
  const updateUser = useContext(UserUpdateContext);
  if (!updateUser) {
    throw new Error("useUserUpdate must be used within a UserProvider");
  }

  return updateUser;
};

const useUser: () => { user: User; updateUser: UpdateUser; isSignedIn: boolean } = () => {
  const user = useUserState();
  return {
    user,
    isSignedIn: user.isLoggedIn,
    updateUser: useUserUpdate(),
  };
};

export { UserProvider, useUser };
