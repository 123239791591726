export function replaceUndefinedProperties({
  source,
  recursive = false,
  replacement = "",
}: {
  source: object;
  recursive?: boolean;
  replacement?: unknown;
}): object {
  function transformValue(value: unknown): unknown {
    if (typeof value !== "object" || value === null) {
      return value;
    }

    if (Array.isArray(value)) {
      if (!recursive) {
        return source;
      }

      return value.map((item) => transformValue(item));
    }

    return replaceUndefinedProperties({ source: value, recursive, replacement });
  }

  return Object.fromEntries(
    Object.entries(source as object).map(([key, value]) => {
      if (value === undefined) {
        return [key, replacement];
      }

      if (recursive) {
        return [key, transformValue(value)];
      }

      return [key, value];
    })
  );
}

const isArray = Array.isArray;
const keyList = Object.keys;
const hasProp = Object.prototype.hasOwnProperty;

export function isEqual(a: unknown, b: unknown): boolean {
  if (a === b) {
    return true;
  }

  if (a && b && typeof a === "object" && typeof b === "object") {
    const arrA = isArray(a),
      arrB = isArray(b);
    let i, length, key;

    if (arrA && arrB) {
      length = a.length;
      if (length !== b.length) {
        return false;
      }
      for (i = length; i-- !== 0; ) {
        if (!isEqual(a[i], b[i])) {
          return false;
        }
      }
      return true;
    }

    if (arrA !== arrB) {
      return false;
    }

    const dateA = a instanceof Date,
      dateB = b instanceof Date;
    if (dateA !== dateB) {
      return false;
    }
    if (dateA && dateB) {
      return a.getTime() === b.getTime();
    }

    const regexpA = a instanceof RegExp,
      regexpB = b instanceof RegExp;
    if (regexpA !== regexpB) {
      return false;
    }
    if (regexpA && regexpB) {
      return a.toString() === b.toString();
    }

    const keys = keyList(a);
    length = keys.length;

    if (length !== keyList(b).length) {
      return false;
    }

    for (i = length; i-- !== 0; ) {
      if (!hasProp.call(b, keys[i])) {
        return false;
      }
    }

    for (i = length; i-- !== 0; ) {
      key = keys[i];
      // @ts-ignore
      if (!isEqual(a[key], b[key])) {
        return false;
      }
    }

    return true;
  }

  return a !== a && b !== b;
}
