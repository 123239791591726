import z from "zod";
import { webappClient } from "../clients";
import { validateResponse } from "../validation";

const fetchTrustpilotScoreResponseSchema = z.object({
  totalReviews: z.number(),
  scoreValue: z.number(),
});

export async function fetchTrustpilotScore() {
  const data = await webappClient.get("api/airhelp_score").json();

  return validateResponse({
    data,
    schema: fetchTrustpilotScoreResponseSchema,
    tag: "Trustpilot API response",
  });
}
