import { AfterResponseHook, BeforeRequestHook } from "ky";

let CSRFToken: string | null = null;

export const injectCSRFTokenBeforeRequest: BeforeRequestHook = (request) => {
  if (CSRFToken) {
    request.headers.set("X-CSRF-Token", CSRFToken);
  }
};

export const setupCSRFTokenFromResponse: AfterResponseHook = (request, options, response) => {
  const tokenPresent = response.headers.has("x-csrf-token");
  const noStoreCache =
    response.headers.get("cache-control")?.includes("no-store") ||
    response.headers.get("Cache-Control")?.includes("no-store");

  if (tokenPresent && noStoreCache) {
    CSRFToken = response.headers.get("x-csrf-token");
  }

  return response;
};
