import { useTranslation } from "react-i18next";
import findBrandLabel from "layout/footer/brand-disclaimer/footerBrandLabels";
import { Box } from "@chakra-ui/react";
import { useBrandSnapshot } from "../../modules/branding";
import { amexEgenciaPrivacyStatementUrl } from "../../../constants/linksPaths";
import TranslationWithHTML from "../../../components/ui-elements/translation-with-html/TranslationWithHTML";

export function FooterBrandDisclaimer() {
  const { t } = useTranslation();
  const brand = useBrandSnapshot();
  const year = new Date().getFullYear();

  if (!brand?.hasDisclaimer) {
    return null;
  }

  const { isEgencia, isExpedia, isTripit, isAmex, slug } = brand!;
  const isAmexOrEgencia = isAmex || isEgencia;

  return (
    <Box data-testid="brandDisclaimer" fontSize="xs" color="greyscale.500" pt="5">
      {isExpedia && t("footer.disclaimer.expedia", { brandLabel: findBrandLabel(slug) })}
      {isTripit && t("footer.disclaimer.tripit")}
      {isAmexOrEgencia && (
        <TranslationWithHTML
          fontSize="xs"
          color="greyscale.500"
          sx={{ a: { textDecoration: "underline" } }}
          text={t("footer.disclaimer.amex_and_egencia", {
            privacyStatementLink: amexEgenciaPrivacyStatementUrl,
            year,
          })}
        />
      )}
    </Box>
  );
}
