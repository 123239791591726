import FunnelBuilder from "../../../builder/FunnelBuilder";
import { FunnelStepDefinition } from "../../../builder/FunnelDefinition";
import preEligibilityBarcodeGuard from "./preEligibilityBarcodeGuard";

export const PRE_ELIGIBILITY_BARCODE_FUNNEL_NAME = "preEligibilityBarcode";

class PreEligibilityBarcodeFunnelBuilder {
  private funnelBuilder = new FunnelBuilder(PRE_ELIGIBILITY_BARCODE_FUNNEL_NAME);

  markFlow(flow: "happy" | "unhappy") {
    this.funnelBuilder.markFlow(flow);
    return this;
  }

  addPreEligibilityBarcodeGuard(firstStepPath: string) {
    this.funnelBuilder.withGuard(preEligibilityBarcodeGuard(firstStepPath));
    return this;
  }

  addStep(step: FunnelStepDefinition) {
    this.funnelBuilder.addStep(step);
    return this;
  }

  build() {
    return this.funnelBuilder.build();
  }
}

export default PreEligibilityBarcodeFunnelBuilder;
