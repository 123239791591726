import { sendWarningReport } from "../../../utils/reporting";
import { Brand } from "./Brand";
import { proxy, snapshot, useSnapshot } from "valtio";
import { fetchBrand } from "../../api/branding";
import { updateClaimDetails } from "v2/funnels/pre-eligibility/state/preEligibilityState";

const PARTNER_KEY = "partner";
const AFFILIATE_KEY = "affiliate";

interface BrandingState {
  brand?: Brand | null;
}

const brandingState = proxy<BrandingState>({
  brand: undefined,
});

export function useBrandSnapshot() {
  return useSnapshot(brandingState).brand;
}

export function brandSnapshot() {
  return snapshot(brandingState).brand;
}

export function extractBrandFromRequest({
  request,
  params,
}: {
  request: Request;
  params: Record<string, string | undefined>;
}) {
  const parsedUrl = new URL(request.url);
  const { partner: partnerFromSessionStorage, affiliate: affiliateFromSessionStorage } =
    getBrandFromSessionStorage();

  const partnerFromUrl = parsedUrl.searchParams.get(PARTNER_KEY);
  const affiliateFromUrl = parsedUrl.searchParams.get(AFFILIATE_KEY);

  return {
    partner: params[PARTNER_KEY] || partnerFromUrl || partnerFromSessionStorage,
    affiliate: affiliateFromUrl || affiliateFromSessionStorage,
  };
}

export async function loadClientBrand({
  partner,
  affiliate,
}: {
  partner: string | null;
  affiliate: string | null;
}) {
  if (partner) {
    updateClaimDetails({ channel: "ch_partner" });
    saveBrandToSessionStorage({ partner });
  } else if (affiliate) {
    updateClaimDetails({ channel: "ch_affiliate" });
    saveBrandToSessionStorage({ affiliate });
  }

  const brand = partner || affiliate;

  if (typeof brandingState.brand !== "undefined") {
    return brandingState.brand;
  }

  if (brand) {
    try {
      brandingState.brand = await getBrandData(brand);
    } catch (error) {
      sendWarningReport(`Failed to fetch brand ${brand}`, error);
      brandingState.brand = null;
    }
  } else {
    brandingState.brand = null;
  }

  return brandingState.brand;
}

async function getBrandData(name: string) {
  const brand = await fetchBrand(name);

  return {
    siteUrl: brand.site_url,
    logoUrl: brand.logo_url,
    name: brand.name,
    logoPresentInWebapp: brand.logo_present_in_webapp,
    logoWidth: brand.logo_width,
    primaryColor: brand.primary_color,
    secondaryColor: brand.secondary_color,
    isExpedia: brand.is_expedia,
    isEgencia: brand.is_egencia,
    slug: brand.slug,
    reloadPii: brand.reload_pii,
    get isAmex() {
      return this.slug === "amex_gbt";
    },
    get isTripit() {
      return this.slug === "tripit";
    },
    get hasDisclaimer() {
      return this.slug === "tripit" || this.isExpedia || this.isEgencia || this.isAmex;
    },
  } satisfies Brand as Brand;
}

function saveBrandToSessionStorage({
  partner,
  affiliate,
}: {
  partner?: string;
  affiliate?: string;
}) {
  try {
    if (partner) {
      sessionStorage.setItem(PARTNER_KEY, partner);
    } else if (affiliate) {
      sessionStorage.setItem(AFFILIATE_KEY, affiliate);
    }
  } catch (error) {
    sendWarningReport("Failed to save brand to sessionStorage", error);
  }
}

function getBrandFromSessionStorage() {
  try {
    const partner = sessionStorage.getItem(PARTNER_KEY);
    const affiliate = sessionStorage.getItem(AFFILIATE_KEY);

    return {
      partner,
      affiliate,
    };
  } catch (error) {
    sendWarningReport("Failed to get brand from sessionStorage", error);

    return {
      partner: null,
      affiliate: null,
    };
  }
}
