import React from "react";
import { Trustpilot } from "@airhelp/react";
import { Box } from "@chakra-ui/react";
import useToLocalNumber from "../../../app-settings/useToLocalNumber";
import { TRUSTPILOT_API_URL, TrustpilotScoreType } from "../../../api/trustpilotScore";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "@tanstack/react-query";

const FunnelTrustpilot = ({ text, ...props }: TrustpilotProps) => {
  const { t } = useTranslation();
  const toLocaleNumber = useToLocalNumber();
  const queryClient = useQueryClient();

  const trustpilotScore = queryClient.getQueryData<TrustpilotScoreType>([TRUSTPILOT_API_URL]);

  const translationsVariables = {
    scoreValue: `${toLocaleNumber(trustpilotScore?.scoreValue ?? 5)}/5`,
    totalReviews: toLocaleNumber(trustpilotScore?.totalReviews ?? 0),
  };

  return (
    <Trustpilot
      data-testid="trustpilot"
      text={t(text || "footer.trustpilot.text", translationsVariables)}
      {...props}
    />
  );
};

type TrustpilotProps = React.ComponentProps<typeof Box> & {
  className?: string;
  text?: string;
};

export default FunnelTrustpilot;
