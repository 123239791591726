import { UpdateFunction } from "../UpdateFunction";
import { FellowPassengerRest } from "../../api/model/FellowPassengerRest";

export class FellowPassenger {
  constructor(
    protected fellowPassenger: FellowPassengerRest,
    protected update: UpdateFunction<FellowPassengerRest> = () => {}
  ) {}

  get id() {
    return this.fellowPassenger.id;
  }

  set id(value) {
    this.update((draft) => {
      draft.id = value;
    });
  }

  get internalId() {
    return this.id || this.fellowPassenger.internalId;
  }

  get firstName() {
    return this.fellowPassenger.firstName;
  }

  set firstName(value) {
    this.update((draft) => {
      draft.firstName = value;
    });
  }

  get lastName() {
    return this.fellowPassenger.lastName;
  }

  set lastName(value) {
    this.update((draft) => {
      draft.lastName = value;
    });
  }

  get email() {
    return this.fellowPassenger.email;
  }

  set email(value) {
    this.update((draft) => {
      draft.email = value;
    });
  }

  get minor() {
    return this.fellowPassenger.minor;
  }

  toggleMinor() {
    this.update((draft) => {
      draft.minor = !this.minor;
    });
  }

  toJson(claimEnquiryId?: number) {
    return this.fellowPassenger.toJson(claimEnquiryId);
  }
}
