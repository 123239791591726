import { Writable } from "./claim-enquiry/ClaimEnquiry";

export type UpdateFunction<T> = (func: (draft: Writable<T>) => void) => void;

export function sliceUpdate<T, U>(
  originalUpdate: UpdateFunction<T>,
  sliceDraft: (draft: T) => U
): UpdateFunction<U> {
  return (slicedUpdate) => {
    originalUpdate((originalDraft) => {
      const slicedDraft = sliceDraft(originalDraft);
      slicedUpdate(slicedDraft);
    });
  };
}
