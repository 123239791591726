import axios from "./axios";

export const TRUSTPILOT_API_URL = "/api/airhelp_score";

const fetchTrustpilotScore = async () => {
  const { data } = await axios
    .get<TrustpilotScoreType>(TRUSTPILOT_API_URL)
    .then((response) => response);

  return {
    totalReviews: data.totalReviews,
    scoreValue: data.scoreValue,
  };
};

export const DEFAULT_TRUSTPILOT_SCORE = {
  totalReviews: 113266,
  scoreValue: 4.6,
};

export type TrustpilotScoreType = {
  totalReviews: number;
  scoreValue: number;
};

const trustpilotApi = {
  fetchTrustpilotScore,
};

export default trustpilotApi;
