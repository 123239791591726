import styles from "./Footer.module.scss";
import FooterContent from "./content/FooterContent";
import OtaFooterContent from "./content/OtaFooterContent";
import classNames from "classnames";
import { isMobileOnly } from "react-device-detect";
import { proxy } from "valtio";

const otaFooterState = proxy({
  enabled: window.location.pathname.includes("/ota"),
});

export function enableOtaFooter() {
  otaFooterState.enabled = true;
}

const Footer = () => {
  const showOtaFooter = isMobileOnly && otaFooterState.enabled;

  return (
    <footer className={classNames(styles.footer, showOtaFooter && styles.otaFooter)}>
      {showOtaFooter ? (
        <OtaFooterContent />
      ) : (
        <div className={styles.footerContainer}>
          <FooterContent />
        </div>
      )}
    </footer>
  );
};

export default Footer;
