import { Locale } from "../../../../app-settings/localesAndCurrencies";
import { SystemProps } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { setAppCurrency, setAppLocale, useAppSettingsSnapshot } from "../../../settings";
import { useDisclosure } from "@chakra-ui/react";
import { LanguageSwitcherModal } from "@airhelp/react";
import {
  currencies,
  Currency,
  locales,
  mapLocaleToFlagKey,
} from "../../../../app-settings/localesAndCurrencies";
const { en, "pt-BR": ptBr, ...restOfAvailableLocales } = locales;
const availableCurrencies = Object.values(currencies);

const modalSections: ModalSectionsConfigurationObject = {
  topSection: {
    title: "footer.settings_modal.suggested_languages",
    items: [en, ptBr],
  },
  middleSection: {
    title: "footer.settings_modal.select_language",
    items: Object.values(restOfAvailableLocales),
    gridParams: { base: "row", lg: "column" },
  },
  footerSection: {
    title: "footer.settings_modal.select_currency",
    items: [
      {
        code: currencies.EUR.code,
        displayName: currencies.EUR.displayName,
      },
      {
        code: currencies.USD.code,
        displayName: currencies.USD.displayName,
      },
    ],
  },
};

export function SettingsModal() {
  const { isOpen, onOpen: openSettingsModal, onClose: closeSettingModal } = useDisclosure();
  const { t } = useTranslation();
  const { locale, currency } = useAppSettingsSnapshot();

  const lists = Object.values(modalSections).map((section) => {
    return {
      listHeading: t(section.title),
      listItems: section.items.map((item: ModalSectionItem) => {
        return {
          id: item.code,
          name: item.displayName,
          icon: item?.icon,
        };
      }),
      listAutoflow: section?.gridParams,
    };
  });

  return (
    <LanguageSwitcherModal
      isOpen={isOpen}
      lists={lists}
      flagKey={mapLocaleToFlagKey(locale)}
      activeItems={[locale.toLowerCase(), currency]}
      onOpen={openSettingsModal}
      onClose={closeSettingModal}
      onItemClick={(item) => {
        handleItemClick(item);
        closeSettingModal();
      }}
      heading=""
    />
  );
}

function detectCurrencyChange(code: string) {
  return availableCurrencies.some((currency) => currency.code === code);
}

function handleItemClick({ id }: { id: string; name: string; icon?: any }) {
  if (detectCurrencyChange(id)) {
    setAppCurrency(id as Currency);
  } else {
    setAppLocale(id as Locale);
  }
}

type ModalSectionsConfigurationObject = {
  [_p in string]: {
    title: string;
    items: ModalSectionItem[];
    gridParams?: SystemProps["gridAutoFlow"];
  };
};

type ModalSectionItem = {
  code: string;
  displayName: string;
  icon?: any;
};
