// @ts-ignore
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: import.meta.env.VITE_GTM_ID,
};

const TrackingScripts = () => {
  if (!import.meta.env.VITE_TRACKING_ENABLED || import.meta.env.VITE_TRACKING_ENABLED === "false") {
    return null;
  }

  TagManager.initialize(tagManagerArgs);
};

export default TrackingScripts;
