let SCRIPT_LOADED = false;
let ERROR_HANDLED = false;

export async function addExternalScript({
  url,
  timeout,
  customOnLoad,
  customOnError,
}: {
  url: string;
  timeout?: number;
  customOnLoad?: () => void;
  customOnError?: () => void;
}) {
  const head = document.querySelector("head");
  const scriptExists = head?.querySelector(`script[src="${url}"]`);

  if (!scriptExists) {
    const script = document.createElement("script");
    script.setAttribute("src", url);

    return new Promise<void>((resolve, reject) => {
      script.onload = handleScriptLoaded(resolve, customOnLoad);
      script.onerror = handleScriptLoadError(reject, customOnError);

      head?.appendChild(script);

      if (timeout) {
        setTimeout(() => {
          if (!SCRIPT_LOADED) {
            handleScriptLoadError(reject, customOnError, `Timeout ${timeout}ms`)();
          }
        }, timeout);
      }
    });
  }
}

function handleScriptLoaded(resolveHandler: () => void, customOnLoad?: () => void) {
  return () => {
    resolveHandler();
    customOnLoad && customOnLoad();
    SCRIPT_LOADED = true;
  };
}

function handleScriptLoadError(
  rejectHandler: (reason: string) => void,
  customOnError?: () => void,
  concreteReason?: string,
) {
  return () => {
    if (!ERROR_HANDLED) {
      rejectHandler("External script load error" + (concreteReason ? `: ${concreteReason}` : ""));
      customOnError && customOnError();
      ERROR_HANDLED = true;
    }
  };
}
