import { useNavigation } from "../hooks/useNavigation";

const WEBSITE_HOST = import.meta.env.VITE_WEBSITE_URL;
const getWebsitePath = (url = "") => WEBSITE_HOST + url;

export const RedirectToWebsiteNotFoundPage = () => {
  const { goTo } = useNavigation();

  goTo(getWebsitePath("/404/"));

  return null;
};
