import { produce, immerable } from "immer";
import { Expose, Type } from "class-transformer";
import { Airport } from "../Airport";
import ItineraryFlight from "./ItineraryFlight";
import { fromJson, toJson } from "api/model/json";

class Itinerary {
  [immerable] = true;

  @Expose()
  @Type(() => ItineraryFlight)
  flights: ItineraryFlight[];

  @Expose() otaLeadId?: string;

  constructor(flights: ItineraryFlight[]) {
    this.flights = flights;
  }

  addConnection() {
    return produce(this, (draft) => {
      const newFlight = new ItineraryFlight();
      newFlight.arrivalAirport = draft.lastFlight.arrivalAirport;
      draft.lastFlight.arrivalAirport = undefined;
      draft.flights.push(newFlight);
      draft.flights.forEach((f) => f.clearAirlineData());
    });
  }

  updateConnection(index: number, connection?: Airport) {
    return produce(this, (draft) => {
      draft.flights[index].arrivalAirport = connection;
      draft.flights[index + 1].departureAirport = connection;
    });
  }

  removeConnection(index: number) {
    return produce(this, (draft) => {
      const clickedFlightIndex = index + 1;
      const clickedFlight = draft.flights[clickedFlightIndex];
      const previousFlight = draft.flights[index];

      previousFlight.arrivalAirport = clickedFlight.arrivalAirport;

      draft.flights.splice(clickedFlightIndex, 1);
      draft.flights.forEach((f) => f.clearAirlineData());
    });
  }

  clearConnections() {
    if (this.connections.length === 0) {
      return this;
    }

    return produce(this, (draft) => {
      const singleFlight = new ItineraryFlight();
      singleFlight.departureAirport = draft.flights[0].departureAirport;
      singleFlight.arrivalAirport = draft.lastFlight.arrivalAirport;
      singleFlight.disrupted = true;
      draft.flights = [singleFlight];
    });
  }

  get departedFrom() {
    return this.flights[0].departureAirport;
  }

  get finalDestination() {
    return this.lastFlight.arrivalAirport;
  }

  setDepartedFrom(airport?: Airport) {
    return produce(this, (draft) => {
      draft.flights[0].departureAirport = airport;
    });
  }

  setFinalDestination(airport?: Airport) {
    return produce(this, (draft) => {
      draft.flights[draft.flights.length - 1].arrivalAirport = airport;
    });
  }

  get connections() {
    return this.flights.slice(1).map((f) => f.departureAirport);
  }

  get lastFlight() {
    return this.flights[this.flights.length - 1];
  }

  static fromJson(itinerary: any) {
    return fromJson(Itinerary, itinerary);
  }

  toJson() {
    return toJson(this);
  }
}

export default Itinerary;
