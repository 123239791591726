import { UpdateFunction } from "../UpdateFunction";
import { AddressRest } from "../../api/model/AddressRest";

export class Address {
  constructor(
    private addressRest: AddressRest,
    private updateAddress: UpdateFunction<AddressRest>
  ) {}

  get id() {
    return this.addressRest.id;
  }

  set id(id) {
    this.updateAddress((draft) => {
      draft.id = id;
    });
  }

  get firstLane() {
    return this.addressRest.firstLane;
  }

  set firstLane(firstLane) {
    this.updateAddress((draft) => {
      draft.firstLane = firstLane;
    });
  }

  get secondLane() {
    return this.addressRest.secondLane;
  }

  set secondLane(secondLane) {
    this.updateAddress((draft) => {
      draft.secondLane = secondLane;
    });
  }

  get city() {
    return this.addressRest.city;
  }

  set city(city) {
    this.updateAddress((draft) => {
      draft.city = city;
    });
  }

  get countryCode() {
    return this.addressRest.countryCode;
  }

  set countryCode(countryCode) {
    this.updateAddress((draft) => {
      draft.countryCode = countryCode;
    });
  }

  get state() {
    return this.addressRest.state;
  }

  set state(state) {
    this.updateAddress((draft) => {
      draft.state = state;
    });
  }

  get zipCode() {
    return this.addressRest.zipCode;
  }

  set zipCode(zipCode) {
    this.updateAddress((draft) => {
      draft.zipCode = zipCode;
    });
  }

  get rest() {
    return this.addressRest;
  }
}
