import { Navigate, useLocation, useParams } from "react-router-dom";

const RedirectWithParam = ({ paramName, to }: RedirectWithParamProps) => {
  const params = useParams<any>();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const param = params[paramName];
  if (param) searchParams.append(paramName, param);

  return (
    <Navigate
      to={{
        pathname: to,
        search: searchParams.toString(),
      }}
      replace={true}
    />
  );
};

type RedirectWithParamProps = {
  to: string;
  paramName: string;
};

export default RedirectWithParam;
