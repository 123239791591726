import { Expose } from "class-transformer";

class Brand {
  @Expose() siteUrl: string;
  @Expose() logoUrl: string;
  @Expose() name: string;
  @Expose() logoPresentInWebapp: boolean;
  @Expose() logoWidth: number;
  @Expose() primaryColor: string;
  @Expose() secondaryColor: string;
  @Expose() isExpedia: boolean;
  @Expose() isEgencia: boolean;
  @Expose() slug: string;
  @Expose() reloadPii: boolean;

  get isAmex() {
    return this.slug === "amex_gbt";
  }

  get isTripit() {
    return this.slug === "tripit";
  }

  get isKiwi() {
    return this.slug === "kiwi";
  }

  get hasDisclaimer() {
    return this.slug === "tripit" || this.isExpedia || this.isEgencia || this.isAmex;
  }

  get isFlyTranqui() {
    return this.slug === "flytranqui";
  }

  get isESky() {
    return this.slug === "esky";
  }
}

export default Brand;
