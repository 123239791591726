import dataLayer from "./tools/dataLayer";
import { replaceUndefinedProperties } from "../utils/object";

const analytics = {
  track(eventName: string, properties?: { [index: string]: any }) {
    dataLayer.push({ ...properties, event: eventName });
  }
};

export const analyticsGA4 = {
  track(eventName: string, properties?: { [index: string]: any }) {
    dataLayer.push(
      replaceUndefinedProperties({
        source: { ...properties, event: eventName },
        recursive: true,
        replacement: ""
      })
    );
  }
};

export default analytics;
