import { generatePath } from "react-router-dom";
import { FunnelDefinition, FunnelStepDefinition, isFunnelDefinition } from "./FunnelDefinition";

type QueryParam = [string, string];
type StepLocation = { pathname: string; search: string };
type NextStepLocation = { step?: FunnelStepDefinition; location: StepLocation };

const EMPTY_LOCATION: NextStepLocation = {
  location: {
    pathname: "",
    search: "",
  },
};

export const calculateFirstStep = (definition: FunnelDefinition, routeParams: any) => {
  const firstStep = findFirstStep(definition);
  return generateStepLocation(firstStep, routeParams);
};

interface CalculateNextStepParams {
  definition: FunnelDefinition;
  routeParams: any;
  nextStepParams: any;
  currentStepId: string;
  nextStepDefinitionKey?: "next" | "previous";
}
/**
 * Function calculating next step of the given definition based on current location (including route params
 * to support different query param combinations). Depending on return type two things may happen:
 *
 *  - `FunnelDefinition` - new funnel will be registered and user will be redirected to the first step
 *  - `NextStepLocation` - user will be redirected to the location of the next step read from `NextStepDefinition.step.route`
 *  - `EMPTY_LOCATION` - Nothing will happen, case for handling end of funnel
 *
 * @param definition - current funnel definition
 * @param currentStepId - id of the current step
 *  `FunnelStepDefinition.next` function to enable dynamic next step calculation.
 * @param routeParams - current route params (for example query params)
 * @param nextStepParams - params that should be passed to nextStep function. Will be available in
 *  `FunnelStepDefinition.next` function to enable dynamic next step calculation.
 * @param nextStepDefinitionKey - calculation of next step can be done based on back and forward navigation,
 *  this parameter allows to specify which key should be used to fetch the next step definition.
 *
 */
export const calculateNextStep = ({
  definition,
  routeParams,
  nextStepParams,
  currentStepId,
  nextStepDefinitionKey = "next",
}: CalculateNextStepParams): NextStepLocation | FunnelDefinition | null => {
  const currentStep = findStepById(definition, currentStepId);

  if (!currentStep) {
    return EMPTY_LOCATION;
  }

  const nextStepId = findNextStepId({
    step: currentStep,
    nextStepParams,
    key: nextStepDefinitionKey,
  });

  if (nextStepId === null) {
    return null;
  }
  if (isFunnelDefinition(nextStepId)) {
    return nextStepId;
  }
  if (!nextStepId) {
    return EMPTY_LOCATION;
  }

  const nextStep = findStepById(definition, nextStepId);
  const searchParams = new URLSearchParams(window.location.search);
  getQueryParams(currentStep).forEach(([key]) => searchParams.delete(key));
  getQueryParams(nextStep).forEach(([key, value]) => searchParams.set(key, value));

  return generateStepLocation(nextStep, routeParams, searchParams);
};

export const generateStepLocation = (
  step: FunnelStepDefinition | undefined,
  routeParams: any,
  searchParams = new URLSearchParams(window.location.search),
): NextStepLocation => ({
  step,
  location: {
    pathname: generatePath(step?.path || "", routeParams),
    search: searchParams.toString(),
  },
});

const findFirstStep = (definition: FunnelDefinition) => {
  return definition.firstStep
    ? findStepById(definition, definition.firstStep)
    : definition.steps[0];
};

export const findStepById = (definition: FunnelDefinition, id: string, minimalStepIndex = -1) =>
  definition.steps.find((step, index) => step.id === id && index >= minimalStepIndex);

export const findStepIndexById = (
  definition: FunnelDefinition,
  id: string,
  minimalStepIndex = -1,
) => definition.steps.findIndex((step, index) => step.id === id && index >= minimalStepIndex);

interface FindNextStepIdParams {
  step: FunnelStepDefinition;
  nextStepParams: any;
  key?: "next" | "previous";
}
const findNextStepId = ({
  step,
  nextStepParams,
  key = "next",
}: FindNextStepIdParams): string | undefined | null | FunnelDefinition => {
  if (step[key] === null) {
    return null;
  }

  if (isFunnelDefinition(step[key]) || typeof step[key] === "string") {
    return step[key] as string | FunnelDefinition;
  }

  if (typeof step[key] === "function") {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
    return (step[key] as Function)({ params: nextStepParams });
  }

  return undefined;
};

const getQueryParams = (step?: FunnelStepDefinition): QueryParam[] =>
  Object.entries(step?.queryParams || {});
