import { Expose, Transform } from "class-transformer";
import { immerable } from "immer";
import ClaimEnquiry from "../../model/claim-enquiry/ClaimEnquiry";
import { Profession } from "../../model/user/Profession";
import { DATE_TO_PLAIN_TRANSFORMER, PLAIN_TO_DATE_TRANSFORMER, toJson } from "./json";
import { MaritalStatus } from "model/user/MaritalStatus";

export class PersonalDetailsRest {
  [immerable] = true;

  @Expose() id?: number;
  @Expose() fiscalNumber?: string | null;
  @Expose() idNumber?: string;
  @Expose() occupation?: string;
  @Expose() nationality?: string;
  @Expose() placeOfBirth?: string;
  @Expose() profession?: Profession;
  @Expose() maritalStatus?: MaritalStatus;

  @Expose()
  @Transform(DATE_TO_PLAIN_TRANSFORMER, { toPlainOnly: true })
  @Transform(PLAIN_TO_DATE_TRANSFORMER, { toClassOnly: true })
  dateOfBirth?: Date;
}

export const personalDetailsToJson = (
  personalDetails: PersonalDetailsRest,
  claimEnquiry: ClaimEnquiry,
  params = { recheck_eligibility: false },
) => ({
  personal_details: toJson(personalDetails),
  claim_enquiry_id: claimEnquiry.id,
  recheck_eligibility: params.recheck_eligibility,
});
