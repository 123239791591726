import { Airline } from "../Airline";
import { Airport } from "../Airport";
import { Expose, Transform, Type } from "class-transformer";
import { produce, immerable } from "immer";
import {
  DATE_TO_PLAIN_TRANSFORMER,
  fromJson,
  PLAIN_TO_DATE_TRANSFORMER,
} from "../../api/model/json";
import { format } from "date-fns";

class ItineraryFlight {
  [immerable] = true;

  @Expose() id?: number;
  @Expose() @Transform(({ value }) => value ?? false) disrupted?: boolean;

  @Expose()
  @Transform(DATE_TO_PLAIN_TRANSFORMER, { toPlainOnly: true, groups: ["api"] })
  @Transform(PLAIN_TO_DATE_TRANSFORMER, { toClassOnly: true })
  localDepartureDate?: Date;

  @Type(() => Airline)
  @Expose({ toClassOnly: true })
  airline?: Airline;

  @Expose({ toClassOnly: true })
  @Type(() => Airport)
  arrivalAirport?: Airport;

  @Expose({ toClassOnly: true })
  @Type(() => Airport)
  departureAirport?: Airport;

  @Expose() flightIdentifier?: string;
  @Expose() departureTime?: string;
  @Expose() actualDepartureTime?: string;
  @Expose() arrivalTime?: string;
  @Expose() actualArrivalTime?: string;
  @Expose() potentiallyMissedConnection?: boolean;
  @Expose() flightNumber?: string;
  @Expose() marketingAirlineName?: string;
  @Expose() operatingAirlineName?: string;
  @Expose() delayMins?: number;
  @Expose() distanceKm?: number;
  @Expose() recognized?: boolean;

  airlineBackup?: Airline;
  flightNumberBackup?: string;

  @Expose({ toPlainOnly: true })
  get airlineIdentifier() {
    return this.airline?.airlineIdentifier;
  }

  @Expose({ toPlainOnly: true })
  get arrivalAirportCode() {
    return this.arrivalAirport?.iata;
  }

  @Expose({ toPlainOnly: true })
  get departureAirportCode() {
    return this.departureAirport?.iata;
  }

  clearAirlineData() {
    this.disrupted = false;
    this.airline = undefined;
    this.flightNumber = undefined;
    this.localDepartureDate = undefined;

    this.airlineBackup = undefined;
    this.flightNumberBackup = undefined;
  }

  get detectedFlightIdentifier() {
    if (
      [
        this.airline?.iata,
        this.flightNumber,
        this.departureAirport?.iata,
        this.arrivalAirport?.iata,
      ].some((value) => typeof value === "undefined")
    ) {
      return undefined;
    }

    if (!this.localDepartureDate) {
      return undefined;
    }

    return [
      this.airline?.iata,
      this.flightNumber,
      format(this.localDepartureDate, "yyyyMMdd"),
      this.departureAirport?.iata,
      this.arrivalAirport?.iata,
    ].join("-");
  }

  isSameAs(other: ItineraryFlight) {
    return (
      this.departureAirport?.iata === other.departureAirport?.iata &&
      this.arrivalAirport?.iata === other.arrivalAirport?.iata
    );
  }

  update(updateFunction: (draft: ItineraryFlight) => void) {
    return produce(this, (draft) => {
      updateFunction(draft);
    });
  }

  static fromJson(itinerary: any) {
    return fromJson(ItineraryFlight, itinerary);
  }
}

export default ItineraryFlight;
