import { extendTheme } from "@chakra-ui/react";
import { theme } from "@airhelp/react";

const defaultFunnelStyles= {
  components: {
    Link: {
      baseStyle: {
        _focus: {
          boxShadow: "none",
        },
      },
    },
    Button: {
      baseStyle: {
        border: "none",
        _focus: {
          boxShadow: "none",
        },
      },
    },
    Modal: {
      baseStyle: {
        CloseButton: {
          _focus: {
            outline: "none",
          },
        },
      },
    },
  },
};

export const defaultFunnelTheme = extendTheme(theme, defaultFunnelStyles);
