import { produce } from "immer";
import { PersonalDetailsRest } from "../../api/model/PersonalDetailsRest";
import { UpdateFunction } from "../UpdateFunction";

export class PersonalDetails {
  constructor(
    private readonly personalDetails: PersonalDetailsRest,
    private updatePersonalDetails: UpdateFunction<PersonalDetailsRest>
  ) {}

  get id() {
    return this.personalDetails.id;
  }

  set id(id) {
    this.updatePersonalDetails((draft) => {
      draft.id = id;
    });
  }

  get fiscalNumber() {
    return this.personalDetails.fiscalNumber;
  }

  set fiscalNumber(fiscalNumber) {
    this.updatePersonalDetails((draft) => {
      draft.fiscalNumber = fiscalNumber;
    });
  }

  get placeOfBirth() {
    return this.personalDetails.placeOfBirth;
  }

  set placeOfBirth(placeOfBirth) {
    this.updatePersonalDetails((draft) => {
      draft.placeOfBirth = placeOfBirth;
    });
  }

  get nationality() {
    return this.personalDetails.nationality;
  }

  set nationality(nationality) {
    this.updatePersonalDetails((draft) => {
      draft.nationality = nationality;
    });
  }

  get profession() {
    return this.personalDetails.profession;
  }

  set profession(profession) {
    this.updatePersonalDetails((draft) => {
      draft.profession = profession;
    });
  }

  get idNumber() {
    return this.personalDetails.idNumber;
  }

  set idNumber(idNumber) {
    this.updatePersonalDetails((draft) => {
      draft.idNumber = idNumber;
    });
  }

  get occupation() {
    return this.personalDetails.occupation;
  }

  set occupation(occupation) {
    this.updatePersonalDetails((draft) => {
      draft.occupation = occupation;
    });
  }

  get maritalStatus() {
    return this.personalDetails.maritalStatus;
  }

  set maritalStatus(maritalStatus) {
    this.updatePersonalDetails((draft) => {
      draft.maritalStatus = maritalStatus;
    });
  }

  get dateOfBirth() {
    return this.personalDetails.dateOfBirth;
  }

  set dateOfBirth(dateOfBirth) {
    this.updatePersonalDetails((draft) => {
      draft.dateOfBirth = dateOfBirth;
    });
  }

  get rest() {
    return produce(this.personalDetails, () => {});
  }
}
