import classNames from "classnames";
import { Suspense, lazy, PropsWithChildren, useRef } from "react";
import { useAppInitializer } from "../../initializers/AppInitializerContext";
import PageLoader from "../../components/loaders/page-loader/PageLoader";
import styles from "./MainPage.module.scss";
import { isMobile } from "react-device-detect";
import Footer from "../../layout/footer/Footer";
import { useNavigation } from "../../hooks/useNavigation";
import Content from "../../layout/content/Content";
import PageViewedEventTracker from "../../tracking/events/PageViewedEventTracker";
import { Box } from "@chakra-ui/react";
import { AlertContext } from "../../components/alerts/AlertContext";

function useCookiePolicy(): boolean {
  return import.meta.env.MODE === "production";
}

const LeftSidebar = lazy(() => import("../../layout/sidebar/SideBar"));

const MainPage = ({ children }: PropsWithChildren) => {
  const { appInitialized } = useAppInitializer();
  const { currentLocation } = useNavigation();
  const alertRef = useRef(null);
  const isScanner = currentLocation?.pathname.includes("barcode/scanner");

  return (
    <>
      {!appInitialized && <PageLoader isLoading />}
      <Box data-testid="alert-portal" ref={alertRef} />
      <AlertContext.Provider value={{ ref: alertRef }}>
        <div className={classNames(styles.layout, { [styles.hide]: !appInitialized })}>
          {!isMobile && (
            <Suspense fallback={null}>
              <LeftSidebar />
            </Suspense>
          )}
          <div className={styles.mainContent}>
            <Content isScanner={isScanner}>
              <PageViewedEventTracker>{children}</PageViewedEventTracker>
            </Content>
            {!isScanner && <Footer />}
          </div>
          {useCookiePolicy()}
        </div>
      </AlertContext.Provider>
    </>
  );
};

export default MainPage;
