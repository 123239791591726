import { Cookies } from "react-cookie";
import { useAppSettings } from "../app-settings/AppSettingsContext";
import { useBrand } from "../brand/BrandContext";
import { getEmberPath } from "../ember/utils";
import useIsTheme from "../theme/ThemeContext";
import { brazilBrandTermsPath, brazilPriceListPath, brazilTermsPath } from "./brazilLinksPaths";
import { Locale } from "app-settings/localesAndCurrencies";
import resolveAppUrl from "utils/resolveAppUrl";

const websiteUrl = resolveAppUrl(window.location.origin, import.meta.env.VITE_WEBSITE_URL);
const emberUrl = resolveAppUrl(window.location.origin, import.meta.env.VITE_EMBER_HOST);
const ahplusUrl = resolveAppUrl(window.location.origin, import.meta.env.VITE_AHPLUS_HOST);
export const flyTranquiUrl = "https://www.flytranqui.es/";
export const amexEgenciaPrivacyStatementUrl = "https://privacy.amexgbt.com/statement";

const yourRightsPath = Object.freeze({
  de: "das-sagen-die-gesetze",
  da: "kend-dine-rettigheder",
  en: "know-your-rights",
  es: "conoce-tus-derechos",
  fi: "tieda-oikeutesi",
  fr: "connaissez-vos-droits",
  el: "mathete-ta-dikaiwmata-sas",
  it: "conosci-i-tuoi-diritti",
  nl: "ken-je-rechten",
  nb: "kjenn-dine-rettigheter",
  pl: "prawa-pasazerow",
  pt: "conheca-os-seus-direitos",
  "pt-BR": "conheca-os-seus-direitos",
  ro: "drepturile-pasagerilor",
  ru: "znai-svoi-prava",
  sv: "vet-dina-rattigheter",
  "zh-CN": "know-your-rights",
  tr: "havayolu-yolcu-haklari",
});

const termsPath = Object.freeze({
  de: "nutzungsbedingungen",
  da: "vilkar",
  en: "terms",
  es: "terminos",
  fi: "ehdot",
  fr: "conditions-dutilisation",
  el: "oroi",
  it: "termini-e-condizioni",
  nl: "voorwaarden",
  nb: "vilkar",
  pl: "regulamin",
  pt: "termos",
  "pt-BR": "termos",
  ro: "termeni",
  ru: "sroki",
  sv: "villkor",
  "zh-CN": "terms",
  tr: "kosullar",
});

const privacyListPath = Object.freeze({
  de: "datenschutz",
  da: "fortrolighed",
  en: "privacy",
  es: "privacidad",
  fi: "yksityisyys",
  fr: "confidentialite",
  el: "exemytheia",
  it: "privacy",
  nl: "privacy",
  nb: "privat",
  pl: "prywatnosc",
  pt: "privacidade",
  "pt-BR": "privacidade",
  ro: "politica-de-confidentialitate",
  ru: "konfidientsialnost",
  sv: "privat",
  "zh-CN": "privacy",
  tr: "gizlilik",
});

const priceListPaths = Object.freeze({
  de: "preisliste",
  da: "prisliste",
  en: "price-list",
  es: "lista-de-precios",
  fi: "hinnasto",
  fr: "liste-de-prix",
  el: "timokatalogos",
  it: "listino-prezzi",
  nl: "prijslijst",
  nb: "prisliste",
  pl: "cennik",
  pt: "lista-de-precos",
  "pt-BR": "lista-de-precos",
  ro: "lista-preturi",
  ru: "prais-list",
  sv: "prislista",
  "zh-CN": "price-list",
  tr: "price-list",
});

const airPassengerRightsPaths = Object.freeze({
  en: "air-passenger-rights",
  de: "fluggastrechte",
  da: "eu-flypassagerrettigheder",
  es: "derechos-de-los-pasajeros-aereos",
  fi: "lentomatkustajan-oikeudet-eussa",
  fr: "droits-des-passagers-aeriens",
  el: "δικαιώματα-επιβατών-σε-πτήσεις-στην-ε",
  it: "diritti-dei-passeggeri-aerei",
  nl: "rechten-van-vliegtuigpassagiers",
  nb: "flypassasjerrettigheter-i-eu",
  pl: "prawa-pasazera",
  pt: "direitos-aereos",
  "pt-BR": "direitos-aereos",
  ro: "drepturile-pasagerilor",
  ru: "права-авиапассажиров-в-ес",
  sv: "flygpassagerares-rattigheter",
  "zh-CN": "air-passenger-rights",
  tr: "havayolu-yolcu-haklari",
});

const deniedBoardingCompensationPaths = Object.freeze({
  en: "air-passenger-law/#when-can-you-claim-compensation-for-a-denied-boarding",
  de: "fluggastrecht/#wann-konnen-sie-eine-entschadigung-fur-eine-nichtbeforderung-fordern",
  da: "lov-om-flypassagerrettigheder/#hvornar-kan-man-kraeve-kompensation-for-en-naegtet-boarding",
  es: "derechos-del-pasajero-aereo/#cuando-se-puede-reclamar-una-compensacion-por-un-embarque-denegado",
  fi: "lentomatkustajan-oikeudet/#milloin-on-mahdollista-hakea-korvausta-lennolle-paasyn-epaamisesta",
  fr: "le-droit-des-passagers-aeriens/#quand-pouvez-vous-faire-une-demande-dindemnisation-pour-un-embarquement-refuse",
  el: "nomooesia-twn-epibatwn-aeroporikwn/#pote-mporeite-na-zitisete-apozimiosi-gia-arnisi-epivivasis",
  it: "ec-261-la-legge-sui-diritti-dei-passeggeri/#quando-puoi-chiedere-un-risarcimento-per-un-imbarco-negato",
  nl: "luchtreizigers-rechten/#wanneer-kunt-u-schadevergoeding-eisen-voor-een-instapweigering",
  nb: "loven-om-flypassasjerrettigheter/#nar-kan-du-kreve-kompensasjon-for-nektet-ombordstigning",
  pl: "prawa-pasazerow/#kiedy-mozna-zlozyc-wniosek-o-odszkodowanie-za-odmowe-przyjecia-na-poklad",
  pt: "direitos-dos-passageiros-aereos/#em-que-situacoes-pode-reclamar-uma-compensacao-devido-a-recusa-de-embarque",
  "pt-BR":
    "direitos-dos-passageiros-aereos/#quando-e-possivel-fazer-um-pedido-de-indenizacao-por-um-embarque-negado",
  ro: "drepturile-pasagerilor-aerieni/#cand-poti-solicita-despagubire-pentru-o-imbarcare-refuzata",
  ru: "zakon-ob-aviapassazirah/#kogda-vy-mozhete-podat-zayavku-na-kompensaciyu-za-otkaz-v-posadke-na-reys",
  sv: "lagen-om-flygpassagerares-raettigheter/#nar-kan-du-krava-ersattning-for-nekad-ombordstigning",
  "zh-CN": "air-passenger-law/#when-can-you-claim-compensation-for-a-denied-boarding",
  tr: "air-passenger-law/#when-can-you-claim-compensation-for-a-denied-boarding", // TO DO
});

export const dashboardPath = `/claims-and-flights`;
export const dashboardLink = `${emberUrl}${dashboardPath}`;
export const newClaimPath = "/claims/new";
export const claimPath = (id: number) => `/claims/${id}`;
const claimLink = (id: number) => `${emberUrl}${claimPath(id)}`;
const fellowFunnelPath = (token: string) => `${emberUrl}/claims/fellow-details/${token}`;

const ahplusMembershipPurchaseLink = (claimId?: number) => {
  const url = new URL(`${ahplusUrl}/memberships/buy?tier=smart&channel=claim_funnel_smart`);

  if (claimId) {
    url.searchParams.set("claimId", claimId.toString());
  }

  return url;
};

const ahplusMembershipLiteLink = new URL(`${ahplusUrl}/memberships/buy/checkout?tier=lite`);

const useApplicationLinks = () => {
  const brand = useBrand();
  const { locale } = useAppSettings();
  const cookies = new Cookies();
  const currentLocale = locale.toLowerCase();
  const originalLocale = cookies.get("initial_language") || currentLocale;

  const isBrazilTheme = useIsTheme("brazil");
  const termsAndConditionsPath = isBrazilTheme ? brazilTermsPath : termsPath;

  const websiteLocaleWithout = (excludedLocales: Locale[] = ["zh-CN"]) =>
    excludedLocales.includes(locale) ? "en" : currentLocale;
  const websiteLocaleWithoutTr = websiteLocaleWithout(["tr"]);

  const defaultLinks = {
    manageAhPlusMembership: getEmberPath("/manage-membership"),
    signOut: getEmberPath("/users/sign_out"),
    dashboard: dashboardLink,
    claim: claimLink,
    fellowFunnel: fellowFunnelPath,
    ahplusMembershipPurchase: ahplusMembershipPurchaseLink,
    ahplusMembershipLiteLink: ahplusMembershipLiteLink,
    contactUs: getEmberPath("/contact"),
    privacyPolicy: `${websiteUrl}/${currentLocale}/${privacyListPath[locale]}`,
    passengerRights: `${websiteUrl}/${currentLocale}/${yourRightsPath[locale]}`,
    airPassengerRights: `${websiteUrl}/${currentLocale}/${airPassengerRightsPaths[locale]}`,
    help: `${websiteUrl}/${websiteLocaleWithout(["tr", "zh-CN"])}/help`,
    termsAndConditions: isBrazilTheme
      ? `${websiteUrl}/${websiteLocaleWithoutTr}/${termsAndConditionsPath[locale]}`
      : `${websiteUrl}/${currentLocale}/${termsAndConditionsPath[locale]}`,
    priceList: isBrazilTheme
      ? `${websiteUrl}/${websiteLocaleWithout(["tr", "zh-CN"])}/${brazilPriceListPath[locale]}`
      : `${websiteUrl}/${currentLocale}/${priceListPaths[locale]}`,
    deniedBoardingCompensation: `${websiteUrl}/${websiteLocaleWithout(["tr", "zh-CN"])}/help/${
      deniedBoardingCompensationPaths[locale]
    }`,
    airhelpPlusPath: `${websiteUrl}/${currentLocale}/airhelp-plus`,
    airhelpPlusCheckout: getEmberPath("/plus-membership/checkout"),
    contactBrand: undefined,
    referralProgram: `${websiteUrl}/${websiteLocaleWithout(["tr", "zh-CN"])}/help-a-friend`,
    websitePath: `${websiteUrl}/${originalLocale}`,
    cookiesPolicy: `${websiteUrl}/${currentLocale}/${
      currentLocale === "tr" ? "cerezler" : "cookies"
    }`,
  };

  if (brand) {
    const { slug } = brand;

    return {
      ...defaultLinks,
      passengerRights: `${websiteUrl}/${websiteLocaleWithoutTr}/ota/${slug}/faq#q3`,
      help: `${websiteUrl}/${websiteLocaleWithoutTr}/ota/${slug}/faq`,
      termsAndConditions: isBrazilTheme
        ? `${websiteUrl}/${websiteLocaleWithoutTr}/ota/${slug}/${brazilBrandTermsPath[locale]}`
        : `${websiteUrl}/${currentLocale}/ota/${slug}/${
            currentLocale === "tr" ? "kosullar" : "terms"
          }`,
      priceList: `${websiteUrl}/${currentLocale}/ota/${slug}/price-list`,
      contactBrand: `${websiteUrl}/${websiteLocaleWithoutTr}/ota/${slug}/contact`,
    };
  } else {
    return defaultLinks;
  }
};

export default useApplicationLinks;
