const { VITE_REGIONAL_DOMAINS } = import.meta.env;

const supportedRegionalDomains = VITE_REGIONAL_DOMAINS.split(",");

export default function resolveAppUrl(currentUrl: string, destinationUrl: string): string {
  if (currentUrl.includes("localhost")) {
    return destinationUrl;
  }

  const currentDomainSuffix = new URL(currentUrl).hostname.split(".").slice(-1)[0];

  if (supportedRegionalDomains.includes(currentDomainSuffix)) {
    const urlParts = destinationUrl.split(".");
    urlParts[urlParts.length - 1] = currentDomainSuffix;
    return urlParts.join(".");
  } else {
    return destinationUrl;
  }
}
