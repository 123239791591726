import sha256 from 'sha256';

const exactag = (message: string) => {
  const { exactag } = window as any;

  if (exactag && exactag.hash && typeof exactag.hash === "function") {
    return exactag.hash(message);
  }
};

const hashing = {
  sha256,
  exactag,
};

export default hashing;
