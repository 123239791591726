import { useMutation, useQuery, useQueryClient, UseQueryOptions } from "@tanstack/react-query";
import { isAuthError } from "v2/utils/ky";
import { fetchUserProfile, signoutUser } from "v2/api/user";
import { defaultQueryClient } from "../../../config";

const USER_PROFILE_QUERY_KEY: ["user-profile"] = ["user-profile"];

export class AuthRequiredError extends Error {
  public onAuthRedirect?: string;
  public errorDescriptionKey?: string;

  constructor({
    message,
    onAuthRedirect,
    errorDescriptionKey,
  }: {
    message?: string;
    onAuthRedirect?: string;
    errorDescriptionKey?: string;
  }) {
    super(message || "Authentication required");
    this.onAuthRedirect = onAuthRedirect;
    this.errorDescriptionKey = errorDescriptionKey;
  }
}

function userProfileQueryFn() {
  return fetchUserProfile({ retry: 0 }).catch((error) => {
    if (isAuthError(error)) {
      return null;
    }

    throw error;
  });
}

export async function loadUserProfile() {
  return defaultQueryClient.fetchQuery({
    queryKey: USER_PROFILE_QUERY_KEY,
    queryFn: userProfileQueryFn,
    retry: false,
    staleTime: Infinity,
  });
}

export function useUserProfile(
  options: Omit<
    UseQueryOptions<
      unknown,
      unknown,
      Awaited<ReturnType<typeof fetchUserProfile>>,
      ["user-profile"]
    >,
    "queryKey" | "queryFn"
  > = {},
) {
  return useQuery({
    queryKey: USER_PROFILE_QUERY_KEY,
    queryFn: userProfileQueryFn,
    retry: false,
    staleTime: Infinity,
    ...options,
  });
}

export function useUser() {
  const { data: profile, isFetching, isLoading } = useUserProfile();

  return {
    profile,
    isAuthenticated: !!profile,
    isLoading: isLoading || isFetching,
  };
}

export function useSignout() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      await signoutUser();
      await new Promise((resolve) => setTimeout(resolve, 1000));
      await queryClient.invalidateQueries({
        queryKey: USER_PROFILE_QUERY_KEY,
      });
    },
  });
}
