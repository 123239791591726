import { Locale } from "../../../../app-settings/localesAndCurrencies";
import { Locale as LocaleDateFns } from "date-fns/locale";

const LocaleLoaders = {
  en: () => import("date-fns/locale/en-US"),
  da: () => import("date-fns/locale/da"),
  de: () => import("date-fns/locale/de"),
  es: () => import("date-fns/locale/es"),
  el: () => import("date-fns/locale/el"),
  fr: () => import("date-fns/locale/fr"),
  it: () => import("date-fns/locale/it"),
  nl: () => import("date-fns/locale/nl"),
  nb: () => import("date-fns/locale/nb"),
  pl: () => import("date-fns/locale/pl"),
  "pt-BR": () => import("date-fns/locale/pt-BR"),
  pt: () => import("date-fns/locale/pt"),
  ru: () => import("date-fns/locale/ru"),
  ro: () => import("date-fns/locale/ro"),
  fi: () => import("date-fns/locale/fi"),
  sv: () => import("date-fns/locale/sv"),
  "zh-CN": () => import("date-fns/locale/zh-CN"),
  tr: () => import("date-fns/locale/tr"),
};

type LocaleLibrary = {
  default: any;
  [key: string]: LocaleDateFns;
};

export const getLocaleLibrary = async (locale: Locale): Promise<LocaleDateFns> => {
  const loader = LocaleLoaders[locale];
  if (loader) {
    try {
      const loadedLocaleLibrary = (await loader()) as unknown as LocaleLibrary;
      return loadedLocaleLibrary.default;
    } catch {
      throw new Error(`Locale "${locale}" failed to load`);
    }
  } else {
    throw new Error(`Locale "${locale}" is not supported`);
  }
};
