import ClaimEnquiry from "../../model/claim-enquiry/ClaimEnquiry";
import User from "../../model/user/User";
import { immerable } from "immer";
import { Expose, Transform } from "class-transformer";
import { fromJson, toJson } from "./json";

export interface AddressRestWrapper {
  address: AddressRest;
}

export class AddressRest {
  [immerable] = true;

  @Expose() id?: number;

  @Expose({ name: "address_1" })
  @Transform(({ obj }) => obj.address1, { toClassOnly: true })
  firstLane?: string;

  @Expose({ name: "address_2" })
  @Transform(({ obj }) => obj.address2, { toClassOnly: true })
  secondLane?: string;

  @Expose() city?: string;
  @Expose() countryCode?: string;
  @Expose() state?: string;
  @Expose({ name: "zip" }) zipCode?: string;

  static fromJson(response: any) {
    return fromJson(AddressRest, response);
  }

  toJson(claimEnquiry: ClaimEnquiry, user: User) {
    return {
      address: {
        ...toJson(this),
        user_id: user.id,
        claim_enquiry_id: claimEnquiry.id,
      },
    };
  }
}
