import { useQuery } from "@tanstack/react-query";
import trustpilotApi, {
  DEFAULT_TRUSTPILOT_SCORE,
  TRUSTPILOT_API_URL,
  TrustpilotScoreType,
} from "../trustpilotScore";

const useTrustpilotScore = () => {
  useQuery<TrustpilotScoreType, Error>({
    queryKey: [TRUSTPILOT_API_URL],
    queryFn: trustpilotApi.fetchTrustpilotScore,
    retry: 1,
    initialData: DEFAULT_TRUSTPILOT_SCORE,
  });
};

export default useTrustpilotScore;
