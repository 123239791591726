import { UpdateFunction } from "../UpdateFunction";
import { PhoneNumberRest } from "../../api/model/PhoneNumberRest";

export class PhoneNumber {
  constructor(
    private phoneNumberRest: PhoneNumberRest,
    private updatePhoneNumber: UpdateFunction<PhoneNumberRest>,
  ) {}

  get id() {
    return this.phoneNumberRest.id;
  }

  set id(id) {
    this.updatePhoneNumber((draft) => {
      draft.id = id;
    });
  }

  get number() {
    return this.phoneNumberRest.number;
  }

  set number(number) {
    this.updatePhoneNumber((draft) => {
      draft.number = number;
      if (number === undefined || number.length === 0) {
        draft.whatsAppConsent = false;
      }
    });
  }

  set whatsAppConsent(value) {
    this.updatePhoneNumber((draft) => {
      draft.whatsAppConsent = value;
    });
  }

  get whatsAppConsent() {
    return this.phoneNumberRest.whatsAppConsent;
  }

  toggleWhatsAppConsent() {
    this.whatsAppConsent = !this.whatsAppConsent;
  }

  get rest() {
    return this.phoneNumberRest;
  }
}
