import { Airhelp, AirhelpPlus } from "@airhelp/react";
import { useApplicationLinks } from "v2/settings/applicationLinks";
import Logotype from "components/ui-elements/logotype/Logotype";
import { useBrandSnapshot } from "v2/modules/branding";
import { Box } from "@chakra-ui/react";
import { useUser } from "v2/modules/auth";

export function AppLogo() {
  const { profile } = useUser();
  const { websitePath } = useApplicationLinks();
  const brand = useBrandSnapshot();
  const logo = profile?.ahPlusMembership ? (
    <AirhelpPlus
      color={"var(--color-primary-900)"}
      width="auto"
      height={{ base: "25px", sm: "30px" }}
    />
  ) : (
    <Airhelp
      color={"var(--color-primary-900)"}
      width="auto"
      height={{ base: "34px", sm: "40px" }}
    />
  );
  return (
    <Box>
      <Box
        display={{ base: "block", xl: "none" }}
        mb={{ base: "2", sm: 0 }}
        mt={3}
        height={{ base: "35px", sm: "auto" }}
      >
        {brand?.logoPresentInWebapp ? (
          <Logotype
            logoSrc={brand.logoUrl}
            href={brand.siteUrl}
            dataTestId="logo-brand"
            brandName={brand.slug}
            logoWidth={brand.logoWidth}
          />
        ) : (
          <Logotype logo={logo} href={websitePath} dataTestId="logo-airhelp" />
        )}
      </Box>
    </Box>
  );
}
