import { FeatureFlag } from "../app-settings/FeatureFlagsContext";
import axios from "./axios";

type FeatureFlagsRest = {
  feature_toggles: FeatureFlag[];
};

const fetchFeatureFlags = async (): Promise<FeatureFlag[]> => {
  const { data } = await axios.get<FeatureFlagsRest>("/api/feature_toggles");

  return data.feature_toggles;
};

const featureFlagsApi = { fetchFeatureFlags };

export default featureFlagsApi;
