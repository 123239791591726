import { useTranslation } from "react-i18next";
import { useBrand } from "../../../brand/BrandContext";
import styles from "./FooterBrandDisclaimer.module.scss";
import findBrandLabel from "./footerBrandLabels";
import classNames from "classnames";
import { amexEgenciaPrivacyStatementUrl } from "../../../constants/linksPaths";
import TranslationWithHTML from "../../../components/ui-elements/translation-with-html/TranslationWithHTML";

const FooterBrandDisclaimer = ({ className }: FooterBrandDisclaimerProps) => {
  const { t } = useTranslation();
  const brand = useBrand();
  const year = new Date().getFullYear();

  if (!brand?.hasDisclaimer) {
    return null;
  }

  const { isEgencia, isExpedia, isTripit, isAmex, slug } = brand!;
  const isAmexOrEgencia = isAmex || isEgencia;

  return (
    <div className={classNames(styles.disclaimer, className)} data-testid="brandDisclaimer">
      {isExpedia && t("footer.disclaimer.expedia", { brandLabel: findBrandLabel(slug) })}
      {isTripit && t("footer.disclaimer.tripit")}
      {isAmexOrEgencia && (
        <TranslationWithHTML
          fontSize="sm"
          color="greyscale.500"
          sx={{ a: { textDecoration: "underline" } }}
          styles={{ _a: { color: "primary.500" } }}
          text={t("footer.disclaimer.amex_and_egencia", {
            privacyStatementLink: amexEgenciaPrivacyStatementUrl,
            year,
          })}
        />
      )}
    </div>
  );
};

type FooterBrandDisclaimerProps = {
  className?: string;
};

export default FooterBrandDisclaimer;
