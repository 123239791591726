import { FunnelStepDefinition } from "../funnels/builder/FunnelDefinition";

export interface Location {
  pathname: string;
  search: string;
  hash: string;
}

const emptyLocation = {
  pathname: "",
  search: "",
  hash: "",
};

function createLocationFromPartial(partial: Partial<Location>) {
  return {
    ...emptyLocation,
    ...partial,
  } as Location;
}

export function hasStepChangedBackwards(
  lastLocation: Location | undefined,
  currentLocation: Location,
  stepList: FunnelStepDefinition[],
) {
  if (!lastLocation) {
    return false;
  }

  const currentRouteIndex = findLocationStepIndex(currentLocation, stepList);
  const lastRouteIndex = findLocationStepIndex(lastLocation, stepList);

  return currentRouteIndex < lastRouteIndex;
}

function findLocationStepIndex(location: Location, stepList: FunnelStepDefinition[]) {
  // Routes contain parameters - route /route/:uuid has a param :uuid. We are creating a regular
  // expression that can check if a route string matches this pattern, e.g. /route/123 == /route/:uuid.
  // So for each route with parameters we create an expression that matches that exact route but
  // with parameters replaced by a partial regex that captures any valid path segment into
  // a group e.g: :param is replaced with (\w|-)+ and that matches "words-123-with-hyphens"
  const matcher = (route: string) => new RegExp(route.replace(/:\w+/g, "(\\w|-)+"));
  const stepIds = stepList.map((step) => step.path);

  // Find the index of the last route and current route in the history, we have
  // to match them with regexes as routes in the form of pathnames with generic params
  return stepIds.findIndex((id) => matcher(id).test(location.pathname));
}

export function areLocationsDifferent(left: Partial<Location>, right: Partial<Location>) {
  const leftLocation = createLocationFromPartial(left);
  const rightLocation = createLocationFromPartial(right);

  return (
    leftLocation.pathname !== rightLocation.pathname ||
    leftLocation.search !== rightLocation.search ||
    leftLocation.hash !== rightLocation.hash
  );
}
