import * as Sentry from "@sentry/browser";

type ReportData =
  | string
  | Error
  | object
  | { [key: string]: unknown }
  | CallableFunction
  | Date
  | any[]
  | undefined;

export function sendErrorReport(error: unknown, message?: string) {
  sendReport({ data: error as ReportData, message, level: "error" });
}

export function sendWarningReport(message: string, error?: unknown) {
  sendReport({ data: error as ReportData, message, level: "warning" });
}

export function sendInfoReport(message: string) {
  sendReport({ data: JSON.stringify(message), message, level: "info" });
}

interface Report {
  data: ReportData;
  message?: string;
  level: "error" | "warning" | "info";
}

function logData(data: ReportData, level: string) {
  switch (level) {
    case "error":
      console.error(data);
      break;
    case "warning":
      console.warn(data);
      break;
    case "info":
      console.log(data);
      break;
  }
}

function sendReport({ data, message = "no_message_provided", level = "error" }: Report) {
  if (!import.meta.env.VITE_SENTRY_DSN_TOKEN) {
    console.warn("Sentry DSN token is not set, skipping sending report");
    logData(data, level);

    return;
  }

  if (import.meta.env.MODE === "development") {
    console.warn("Error logging in development mode.");
    logData(data, level);
  }

  switch (level) {
    case "error":
      Sentry.withScope((scope) => {
        scope.setTag("errorMessage", message);
        Sentry.captureMessage(message, { level: "error" });
        Sentry.captureException(data, { level: "error" });
      });
      break;
    case "warning":
      Sentry.withScope((scope) => {
        scope.setTag("errorMessage", message);
        Sentry.captureMessage(message, { level: "warning" });
        Sentry.captureException(data, { level: "warning" });
      });
      break;
    case "info":
      Sentry.captureMessage(message, { level: "info" });
      break;
  }
}
