import User from "model/user/User";
import Brand from "../../brand/Brand";
import { FellowPassenger } from "../fellow-passenger/FellowPassenger";
import ItineraryFlight from "../itinerary-flight/ItineraryFlight";
import { sliceUpdate, UpdateFunction } from "../UpdateFunction";
import CompensationDetails from "./CompensationDetails";
import { Contact } from "./Contact";
import { Documents } from "./documents/Documents";
import OtaClaimEnquiry from "./ota/OtaClaimEnquiry";
import { ClaimEnquiryRest } from "../../api/model/ClaimEnquiryRest";
import { AppSettingsContextType } from "../../app-settings/AppSettingsContext";

export type Writable<T> = {
  -readonly [K in keyof T]: T[K];
};

type WritableObject<O> = {
  -readonly [K in keyof O]: O[K];
};

export const DUMMY_BOOKING_REF = "NOT-REQUIRED";

const readOnlyAirlineReasons = [
  "no_remember",
  "technical",
  "weather",
  "other_flights",
  "airport_problems",
  "strike",
  "no_reason",
  "force_majeure",
  "overbooked",
] as const;
const _airlineReasons = readOnlyAirlineReasons as Writable<typeof readOnlyAirlineReasons>;
export type AirlineReason = (typeof _airlineReasons)[number];

const readOnlyTypes = ["delayed", "cancelled", "overbooked"] as const;
export const types = readOnlyTypes as Writable<typeof readOnlyTypes>;
export type ClaimType = (typeof types)[number];

const readOnlyDelays = [
  "lt1",
  "lt2",
  "lt3",
  "gt1_lt2",
  "gt2_lt3",
  "gt2_lt4",
  "gt3_lt4",
  "gt4_lt5",
  "gt3_lt5",
  "gt5_lt6",
  "gt6_lt7",
  "gt7_lt8",
  "gt5_lt9",
  "gt8_lt9",
  "lt4",
  "gt2",
  "gt3",
  "gt4",
  "gt9",
  "never",
] as const;
export const delays = readOnlyDelays as Writable<typeof readOnlyDelays>;
export type Delay = (typeof delays)[number];

export const mapDelayToDisruptionMins = (delay: Delay): DisruptionMins => {
  const delayToDisruptionMins = {
    lt1: { min: "1", max: "60" },
    lt2: { min: "1", max: "119" },
    lt3: { min: "1", max: "179" },
    gt1_lt2: { min: "61", max: "120" },
    gt2_lt3: { min: "121", max: "180" },
    gt2_lt4: { min: "121", max: "240" },
    gt4_lt5: { min: "241", max: "300" },
    gt3_lt4: { min: "181", max: "240" },
    gt3_lt5: { min: "181", max: "300" },
    gt5_lt6: { min: "301", max: "360" },
    gt6_lt7: { min: "361", max: "420" },
    gt7_lt8: { min: "421", max: "480" },
    gt8_lt9: { min: "481", max: "540" },
    gt5_lt9: { min: "301", max: "540" },
    lt4: { min: "1", max: "240" },
    gt2: { min: "120", max: "infinity" },
    gt3: { min: "180", max: "infinity" },
    gt4: { min: "241", max: "infinity" },
    gt9: { min: "541", max: "infinity" },
    never: { min: "never", max: "never" },
  };
  return delayToDisruptionMins[delay] as DisruptionMins;
};

const readOnlyDisruptionMins = [
  { min: "1", max: "60" },
  { min: "1", max: "119" },
  { min: "1", max: "179" },
  { min: "61", max: "120" },
  { min: "121", max: "180" },
  { min: "121", max: "240" },
  { min: "241", max: "300" },
  { min: "181", max: "240" },
  { min: "181", max: "300" },
  { min: "301", max: "360" },
  { min: "301", max: "540" },
  { min: "361", max: "420" },
  { min: "421", max: "480" },
  { min: "481", max: "540" },
  { min: "1", max: "240" },
  { min: "120", max: "infinity" },
  { min: "180", max: "infinity" },
  { min: "241", max: "infinity" },
  { min: "541", max: "infinity" },
  { min: "never", max: "never" },
] as const;
const _disruptionMins = readOnlyDisruptionMins as WritableObject<typeof readOnlyDisruptionMins>;
export type DisruptionMins = (typeof _disruptionMins)[number];

const readOnlyInformedBeforehandDays = [
  { min: "0", max: "1" },
  { min: "14", max: "60" },
  { min: "60", max: "infinity" },
  { min: "1", max: "13" },
  { min: "1", max: "3" },
  { min: "1", max: "6" },
  { min: "7", max: "13" },
  { min: "4", max: "infinity" },
  { min: "14", max: "infinity" },
] as const;
const _informedBeforehandDays = readOnlyInformedBeforehandDays as WritableObject<
  typeof readOnlyInformedBeforehandDays
>;
export type InformedBeforehandDays = (typeof _informedBeforehandDays)[number];

export type ClaimChannel = "ch_web" | "ch_ota" | "ch_partner" | "ch_affiliate";

export type EligibilityResult = "eligible" | "ineligible" | "no_response";

class ClaimEnquiry {
  readonly documents: Documents;
  readonly contact: Contact;
  readonly compensationDetails: CompensationDetails;
  readonly fellowPassengers: FellowPassenger[];
  readonly user?: User;

  constructor(
    protected claimEnquiry: ClaimEnquiryRest,
    protected updateEnquiry: UpdateFunction<ClaimEnquiryRest> = () => {},
  ) {
    this.documents = new Documents(claimEnquiry.documents, updateEnquiry);
    this.contact = new Contact(claimEnquiry, updateEnquiry);
    this.compensationDetails = new CompensationDetails(claimEnquiry);
    this.fellowPassengers =
      claimEnquiry.fellowPassengers?.map(
        (fellow, index) =>
          new FellowPassenger(
            fellow,
            sliceUpdate(updateEnquiry, (draft) => draft.fellowPassengers![index]),
          ),
      ) || [];
    this.user = claimEnquiry.user ? new User(claimEnquiry.user, () => {}) : undefined;
  }

  update(func: (draft: ClaimEnquiryRest) => void) {
    return this.updateEnquiry(func) as unknown as ClaimEnquiryRest;
  }

  invalidated() {
    return this.update((draft) => {
      draft.claimValid = false;
    });
  }

  get ahplus() {
    return this.claimEnquiry.ahplus;
  }

  get ahPlusSelfAssigned() {
    return this.claimEnquiry.ahplusSelfAssigned;
  }

  get isDelayed() {
    return this.claimType === "delayed";
  }

  get isOverbooked() {
    return this.claimType === "overbooked";
  }

  get isCancelled() {
    return this.claimType === "cancelled";
  }

  get isBarcodeScanner() {
    return window.location.pathname.includes("barcode");
  }

  get id() {
    return this.claimEnquiry.id;
  }

  get uuid() {
    return this.claimEnquiry.uuid;
  }

  get claimType() {
    return this.claimEnquiry.claimType;
  }

  get airlineReason() {
    return this.claimEnquiry.airlineReason;
  }

  isOta(): this is OtaClaimEnquiry {
    return this.channel === "ch_ota";
  }

  get isWeb() {
    return this.channel === "ch_web";
  }

  get channel() {
    return this.claimEnquiry.channel;
  }

  get delay() {
    return this.claimEnquiry.delay;
  }

  get informedBeforehandDays() {
    return this.claimEnquiry.informedBeforehandDays;
  }

  get disruptionMins() {
    return this.claimEnquiry.disruptionMins;
  }

  get voluntarilySurrendered() {
    return this.claimEnquiry.voluntarilySurrendered;
  }

  get disruptedFlight() {
    return this.itineraryFlights.find((flight: ItineraryFlight) => flight.disrupted);
  }

  get claimId() {
    return this.claimEnquiry.claimId;
  }

  get bookingReference() {
    return this.claimEnquiry.bookingRef;
  }

  get bookingReferenceProvidedByUser() {
    return this.claimEnquiry.bookingReferenceProvidedByUser;
  }

  get whatHappened() {
    return this.claimEnquiry.whatHappened;
  }

  get itinerary() {
    return this.claimEnquiry.itinerary;
  }

  get itineraryFlights() {
    return this.itinerary?.flights || [];
  }

  get eligibilityCheckResult() {
    return this.claimEnquiry.eligibilityCheckResult;
  }

  get processingRegulation() {
    return this.claimEnquiry.processingRegulation;
  }

  get isEligible() {
    return this.claimEnquiry.claimValid;
  }

  get isBrazilEligible() {
    return this.claimEnquiry.processingRegulation === "brazil";
  }

  get isSaudiArabiaEligible() {
    return this.claimEnquiry.processingRegulation === "saudi_arabia";
  }

  get isEC261Eligible() {
    return this.claimEnquiry.processingRegulation === "ec261";
  }

  get isUK261Eligible() {
    return this.claimEnquiry.processingRegulation === "uk261";
  }

  get isCaGoodwillEligible() {
    return this.claimEnquiry.processingRegulation === "ca_goodwill";
  }

  get finalArrivalAirport() {
    return this.itineraryFlights.at(-1)?.arrivalAirport;
  }

  get isEligibleOnHold() {
    return this.claimEnquiry.eligibleOnHold;
  }

  get bookingSourceIdentifier() {
    return this.claimEnquiry.bookingSourceIdentifier;
  }

  get offeredRerouting() {
    return this.claimEnquiry.offeredRerouting;
  }

  get missedConnection() {
    return this.claimEnquiry.missedConnection;
  }

  get goodwillPaymentCreditCard() {
    return this.claimEnquiry.goodwillPaymentCreditCard;
  }

  get isUnderAhPlus() {
    return (
      this.claimEnquiry.ahplus ||
      this.claimEnquiry.ahplusSelfAssigned ||
      this.claimEnquiry.user?.ahPlusMembership
    );
  }

  get isFastTrack() {
    return this.claimEnquiry.claimEnquiryFunnelDetails?.fastTrack;
  }

  get isExtraExpensesEnabled(): boolean {
    if (this.isUK261Eligible || this.isFastTrack) {
      return false;
    }

    if (this.isOta()) {
      return !!this.claimEnquiry.extraExpensesEnabled;
    }

    return true;
  }

  get optionalQuestionsId() {
    return this.claimEnquiry.optionalQuestionsId;
  }

  toJson(settings: AppSettingsContextType, brand?: Brand, user?: User) {
    return this.claimEnquiry.toJson(this, settings, brand, user);
  }
}

export default ClaimEnquiry;
