import { Image } from "@chakra-ui/react";
import React from "react";

const Logotype = ({ href, logo, logoSrc, dataTestId, brandName, logoWidth }: LogoProps) => (
  <a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    data-testid={dataTestId}
    data-brandname={brandName}
  >
    {logo ? (
      logo
    ) : (
      <Image
        src={logoSrc}
        alt="Brand logo"
        width="auto"
        maxWidth={logoWidth}
        height={{ base: "100%", lg: "auto" }}
      />
    )}
  </a>
);

type LogoProps = {
  logo?: React.ReactNode;
  logoSrc?: string;
  dataTestId: string;
  href?: string;
  brandName?: string;
  logoWidth?: number;
};

export default Logotype;
