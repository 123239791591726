import FunnelStepHeader from "components/step/funnel-step/header/FunnelStepHeader";
import SettingsModal from "components/modals/app-settings/SettingsModal";
import { useBrand } from "../../brand/BrandContext";
import styles from "./Header.module.scss";
import HeaderLogo from "./logo/HeaderLogo";
import classNames from "classnames";

const Header = () => {
  const brand = useBrand();

  return (
    <header className={styles.header}>
      <div className={styles.headerContainer}>
        <div className={styles.logo}>
          <HeaderLogo />
        </div>
        <div className={styles.rightSection}>
          <div
            className={classNames(styles.shield, {
              [styles.brandLogoPresent]: brand?.logoPresentInWebapp,
            })}
          >
            <FunnelStepHeader />
          </div>
          <SettingsModal />
        </div>
      </div>
    </header>
  );
};

export default Header;
