import { proxy } from "valtio/vanilla";

interface SessionTime {
  start: number;
  getDuration: () => number;
}

export const sessionTime = proxy<SessionTime>({
  start: window.performance.now(),
  getDuration() {
    const sessionDuration = window.performance.now() - this.start;
    return Math.round(sessionDuration / 1000);
  },
});
