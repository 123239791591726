import { Expose, Transform, Type } from "class-transformer";
import { fromJson } from "../api/model/json";

export class AirportSearch {
  @Expose()
  @Transform(({ obj }) =>
    [...obj.airports, ...obj.metropolitanAreas.flatMap((m: any) => m.visibleAirports)].map(
      (airport) => fromJson(Airport, airport),
    ),
  )
  @Type(() => Airport)
  airports: Airport[];
}

export class Airport {
  @Expose() city: string;
  @Expose() country: string;
  @Expose() countryCode: string;
  @Expose() iata: string;
  @Expose() name: string;
  @Expose() rank: number;
  @Expose() searchText: string;
  @Expose() latitude: number;
  @Expose() longitude: number;
  @Expose() cityTranslations: {
    [key: string]: string;
  };
  @Expose() ec261: boolean;
  @Expose() timeZone: string;

  get cityInfo() {
    return `${this.city} (${this.iata})`;
  }
}
