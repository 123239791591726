import { Expose } from "class-transformer";
import { Locale as DatePickerLocale } from "date-fns/locale";
import {
  Br,
  Cn,
  De,
  Dk,
  Es,
  Fi,
  Fr,
  Gb,
  Gr,
  It,
  Nl,
  No,
  Pl,
  Pt,
  Ro,
  Ru,
  Se,
  Tr,
} from "@airhelp/flags";
import { getLocaleLibrary } from "components/ui-elements/itinerary-flight-details/arrived/getFormattedDelayTime";

export type Locale = keyof typeof locales;
export type Currency = keyof typeof currencies;

export const currencies = Object.freeze({
  EUR: {
    code: "EUR",
    symbol: "€",
    displayName: "EUR Euro",
  },
  USD: {
    code: "USD",
    currencySymbol: "$",
    displayName: "USD US Dollar",
  },
  BRL: {
    code: "BRL",
    currencySymbol: "R$",
    displayName: "Brazil Real",
  },
  GBP: {
    code: "GBP",
    currencySymbol: "£",
    displayName: "Pound sterling",
  },
  CAD: {
    code: "CAD",
    currencySymbol: "CA$",
    displayName: "Canadian dollar",
  },
});

export const locales = Object.freeze({
  da: {
    code: "da",
    displayName: "Dansk",
    icon: Dk,
  },
  de: {
    code: "de",
    displayName: "Deutsch",
    icon: De,
  },
  en: {
    code: "en",
    displayName: "English",
    icon: Gb,
  },
  es: {
    code: "es",
    displayName: "Español",
    icon: Es,
  },
  el: {
    code: "el",
    displayName: "Ελληνικά",
    icon: Gr,
  },
  fr: {
    code: "fr",
    displayName: "Français",
    icon: Fr,
  },
  it: {
    code: "it",
    displayName: "Italiano",
    icon: It,
  },
  nl: {
    code: "nl",
    displayName: "Nederlands",
    icon: Nl,
  },
  nb: {
    code: "nb",
    displayName: "Norsk",
    icon: No,
  },
  pl: {
    code: "pl",
    displayName: "Polski",
    icon: Pl,
  },
  "pt-BR": {
    code: "pt-BR",
    displayName: "Português (Brasil)",
    icon: Br,
  },
  pt: {
    code: "pt",
    displayName: "Português (Portugal)",
    icon: Pt,
  },
  ru: {
    code: "ru",
    displayName: "Русский",
    icon: Ru,
  },
  ro: {
    code: "ro",
    displayName: "Română",
    icon: Ro,
  },
  fi: {
    code: "fi",
    displayName: "Suomi",
    icon: Fi,
  },
  sv: {
    code: "sv",
    displayName: "Svenska",
    icon: Se,
  },
  tr: {
    code: "tr",
    displayName: "Türkçe",
    icon: Tr,
  },
  "zh-CN": {
    code: "zh-CN",
    displayName: "简体中文",
    icon: Cn,
  },
});

export const mapLocaleToDatePickerLocale = async (locale: Locale): Promise<DatePickerLocale> => {
  const localeLibrary = await getLocaleLibrary(locale);
  return localeLibrary;
};

export const mapLocaleToFlagKey = (locale: Locale): string => {
  const localeToFlagKeyMap = {
    da: "Da",
    de: "De",
    en: "EnGb",
    es: "Es",
    el: "El",
    fr: "Fr",
    it: "It",
    nl: "Nl",
    nb: "Nb",
    pl: "Pl",
    "pt-BR": "PtBr",
    pt: "Pt",
    ru: "Ru",
    ro: "Ro",
    fi: "Fi",
    sv: "Sv",
    "zh-CN": "ZhCn",
    tr: "Tr",
  };
  return localeToFlagKeyMap[locale];
};

export class CompensationInCurrency {
  @Expose({ name: "eur" }) EUR?: number;
  @Expose({ name: "usd" }) USD?: number;
  @Expose({ name: "brl" }) BRL?: number;
  @Expose({ name: "gbp" }) GBP?: number;
  @Expose({ name: "cad" }) CAD?: number;
}

export const normalizeLocale = (locale: string): Locale => {
  const lowerCasedLocale = locale.toLowerCase();
  for (const key in locales) {
    if (key.toLocaleLowerCase() === lowerCasedLocale) {
      return key as Locale;
    }
  }
  if (lowerCasedLocale.includes("-")) {
    const localePrefix = lowerCasedLocale.split("-")[0];
    for (const key in locales) {
      if (key === localePrefix) {
        return key as Locale;
      }
    }
  }

  if (lowerCasedLocale.startsWith("zh")) {
    return "zh-CN" as Locale;
  }
  return "en";
};

export const mapBrowserLanguageToLocale = (locale: string): Locale => {
  const browserLanguageToLocale: { [key: string]: Locale } = {
    da: "da",
    "da-DA": "da",
    de: "de",
    "de-DE": "de",
    el: "el",
    "el-EL": "el",
    es: "es",
    "es-ES": "es",
    fi: "fi",
    "fi-FI": "fi",
    fr: "fr",
    "fr-FR": "fr",
    it: "it",
    "it-IT": "it",
    nb: "nb",
    "nb-NB": "nb",
    nl: "nl",
    "nl-NL": "nl",
    ro: "ro",
    "ro-RO": "ro",
    ru: "ru",
    "ru-RU": "ru",
    sv: "sv",
    "sv-SV": "sv",
    pl: "pl",
    "pl-PL": "pl",
    pt: "pt",
    "pt-PT": "pt",
    "pt-br": "pt-BR",
    "pt-BR": "pt-BR",
    en: "en",
    "en-US": "en",
    "en-GB": "en",
    zh: "zh-CN",
    "zh-cn": "zh-CN",
    "zh-CN": "zh-CN",
    "zh-Hans-CN": "zh-CN",
    tr: "tr",
    "tr-TR": "tr",
  };

  return browserLanguageToLocale[locale] as Locale;
};
