import { proxy, useSnapshot } from "valtio";
import { sendWarningReport } from "../reporting";
import { fetchTrustpilotScore } from "../../api/trustpilot";

const DEFAULT_TOTAL_REVIEWS = 123000;
const DEFAULT_SCORE_VALUE = 4.6;

export const trustpilotScoreState = proxy({
  initialised: false,
  totalReviews: DEFAULT_TOTAL_REVIEWS,
  scoreValue: DEFAULT_SCORE_VALUE,
});

export async function loadTrustpilotScore({ forceReload = false } = {}) {
  try {
    if (!trustpilotScoreState.initialised || forceReload) {
      const { totalReviews, scoreValue } = await fetchTrustpilotScore();
      trustpilotScoreState.totalReviews = totalReviews;
      trustpilotScoreState.scoreValue = scoreValue;
      trustpilotScoreState.initialised = true;
    }
  } catch (e) {
    sendWarningReport("Failed to load trustpilot score", e);
  }
}

export function useTrustpilotScore(locale: string) {
  const snapshot = useSnapshot(trustpilotScoreState);

  return {
    totalReviews: new Intl.NumberFormat(locale).format(snapshot.totalReviews),
    scoreValue: new Intl.NumberFormat(locale).format(snapshot.scoreValue),
  };
}
