import { Spinner } from "@chakra-ui/react";
import classNames from "classnames";
import { ComponentProps } from "react";
import styles from "./LoadingWrapper.module.scss";

const LoadingWrapper = ({
  isLoading,
  size = "large",
  className,
  children,
}: LoadingWrapperProps) => (
  <>
    {isLoading ? (
      <div className={classNames(styles.loadingSpinner, className)}>
        <Spinner variant={size === "large" ? "contentLarge" : "contentMedium"} />
      </div>
    ) : (
      children
    )}
  </>
);

export type LoadingWrapperProps = ComponentProps<typeof Spinner> & {
  isLoading?: boolean;
};

export default LoadingWrapper;
