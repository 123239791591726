const themeTypes = ["ec261", "brazil", "uk261", "tr_shy", "saudi_arabia", "us_goodwill"] as const;
export type Theme = (typeof themeTypes)[number];

export const isTheme = (value?: any): value is Theme => {
  if (typeof value === "string") {
    return themeTypes.some((c) => c === value);
  }
  return false;
};

export default Theme;
