import getLogger from "./analyticsLogger";
import { Optimizely } from "./optimizely/types";
import { proxy } from "valtio/vanilla";

type DefaultDataLayerData = {
  app: "webapp-funnel";
  brand?: string | null;
};
export const defaultDataLayerData = proxy<DefaultDataLayerData>({
  app: "webapp-funnel",
});

declare global {
  interface Window {
    dataLayer: any;
    google_optimize: any;
    optimizely: Optimizely;
  }
}

const logDataLayer = getLogger("DataLayer", "📈");

const MOCKED_DATA_LAYER = {
  push(...event: any) {
    logDataLayer("Pushing event", ...event);
  },
};

const getDataLayer = (): [] => {
  return window.dataLayer || MOCKED_DATA_LAYER;
};

const dataLayer = {
  setBrand(brand: DefaultDataLayerData["brand"]) {
    defaultDataLayerData.brand = brand;
  },
  push(event: { [index: string]: any } = {}) {
    // @ts-ignore
    getDataLayer().push({ ...defaultDataLayerData, ...event });
  },
};

export default dataLayer;
