import React from "react";
import FunnelAvatar from "../components/ui-elements/avatar/FunnelAvatar";
import styles from "./FunnelWrapper.module.scss";
import classNames from "classnames";
import { useNavigation } from "../hooks/useNavigation";

const FunnelWrapper = ({ children }: { children?: React.ReactNode }) => {
  const { currentLocation } = useNavigation();
  const isScanner = currentLocation?.pathname.includes("barcode/scanner");

  return (
    <div className={classNames(styles.funnelContent, isScanner && styles.scannerContent)}>
      {!isScanner && <FunnelAvatar />}
      {children}
    </div>
  );
};

export default FunnelWrapper;
