import { useCallback } from "react";
import featureFlagsApi from "../api/featureFlags";
import { useSetFeatureFlags } from "../app-settings/FeatureFlagsContext";

const useFeatureFlagsInitializer = () => {
  const setFeatureFlags = useSetFeatureFlags();

  return useCallback(() => featureFlagsApi.fetchFeatureFlags().then(setFeatureFlags), [
    setFeatureFlags,
  ]);
};

export default useFeatureFlagsInitializer;
