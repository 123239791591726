import { lazy, Suspense } from "react";
import * as Sentry from "@sentry/react";
import { createBrowserRouter, Navigate, Outlet, RouteObject } from "react-router-dom";
import RedirectWithParam from "./utils/RedirectWithParam";
import FunnelWrapper from "./funnels/FunnelWrapper";
import PageLoader from "./components/loaders/page-loader/PageLoader";
import { RedirectToEmber } from "./ember/RedirectToEmber";
import { ApplicationShell, ApplicationShellV2 } from "./config";
import { preEligibilityLoader } from "./loaders/preEligibilityLoader";
import {
  FUNNEL_ROUTE_ID,
  POST_ELIGIBILITY_ROUTE_ID,
  PRE_ELIGIBILITY_ROUTE_ID,
} from "./constants/routing";
import { FunnelDefinitionProvider } from "./funnels/FunnelDefinitionContext";
import { translationInitV2 } from "./i18n";
import { appSettingsSnapshot } from "./v2/settings";
import { preEligibilityLoaderV2 } from "loaders/v2PreEligibilityLoader";
import { RedirectToWebsiteNotFoundPage } from "./website/RedirectToWebsiteNotFoundPage";

const ErrorPage = lazy(() => import("./pages/error-page/ErrorPage"));
const ErrorBlockedPage = lazy(() => import("./pages/error-blocked-page/ErrorBlockedPage"));
const OtaNotFoundPage = lazy(() => import("./pages/ota-not-found/OtaNotFoundPage"));

const PreEligibilityBarcodeFunnel = lazy(
  () => import("./funnels/pre-eligibility/barcode/PreEligibilityBarcodeFunnel"),
);
const PostEligibilityFunnel = lazy(
  () => import("./funnels/post-eligibility/PostEligibilityFunnel"),
);
const OtaFunnel = lazy(() => import("./funnels/ota/OtaFunnel"));

const routes: RouteObject[] = [
  {
    path: "/partners/:partner",
    element: <RedirectWithParam to="/claims/new" paramName="partner" />,
  },
  {
    path: "/affiliates/:affiliate",
    element: <RedirectWithParam to="/claims/new" paramName="affiliate" />,
  },
  {
    loader: async () => {
      const { locale } = appSettingsSnapshot();
      await translationInitV2(locale);

      return null;
    },
    element: (
      <ApplicationShellV2>
        <Outlet />
      </ApplicationShellV2>
    ),
    children: [
      {
        path: "/auth",
        lazy: () => import("./v2/components/pages/AuthPage"),
      },
      {
        path: "/auth/reset-password",
        lazy: () => import("./v2/components/pages/PasswordResetPage"),
      },
    ],
  },
  ...v2Routes(),
  {
    path: "/",
    element: <ApplicationShell />,
    children: [
      {
        path: "claims/processing-error",
        element: (
          <Suspense fallback={<PageLoader isLoading={true} />}>
            <ErrorPage />
          </Suspense>
        ),
      },
      {
        path: "claims/error-blocked",
        element: (
          <Suspense fallback={<PageLoader isLoading={true} />}>
            <ErrorBlockedPage />
          </Suspense>
        ),
      },
      {
        path: "ota/:slug/:uuid/not-found",
        element: (
          <Suspense fallback={<PageLoader isLoading={true} />}>
            <OtaNotFoundPage />
          </Suspense>
        ),
      },
      {
        id: FUNNEL_ROUTE_ID,
        element: (
          <Suspense fallback={<PageLoader isLoading={true} />}>
            <FunnelWrapper>
              <FunnelDefinitionProvider>
                <Outlet />
              </FunnelDefinitionProvider>
            </FunnelWrapper>
          </Suspense>
        ),
        errorElement: (
          <Suspense fallback={<PageLoader isLoading={true} />}>
            <ErrorPage />
          </Suspense>
        ),
        children: [
          {
            id: "claim-verification",
            path: "claims/verify/:uuid/*",
            element: (
              <Suspense fallback={<PageLoader isLoading={true} />}>
                <PostEligibilityFunnel />
              </Suspense>
            ),
          },
          {
            id: POST_ELIGIBILITY_ROUTE_ID,
            path: "claim_enquiries/:uuid/*",
            element: (
              <Suspense fallback={<PageLoader isLoading={true} />}>
                <PostEligibilityFunnel />
              </Suspense>
            ),
          },
          {
            id: PRE_ELIGIBILITY_ROUTE_ID + "V1",
            loader: preEligibilityLoader,
            children: [
              {
                path: "claims/barcode/*",
                element: (
                  <Suspense fallback={<PageLoader isLoading={true} />}>
                    <PreEligibilityBarcodeFunnel />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: "ota/:slug/:uuid/*",
            element: (
              <Suspense fallback={<PageLoader isLoading={true} />}>
                <OtaFunnel />
              </Suspense>
            ),
            errorElement: <OtaNotFoundPage />,
          },
          {
            path: "claims/:uuid/*",
            element: <RedirectToEmber />,
          },
        ],
      },
      { path: "", element: <Navigate to="/claims/new" /> },
      {
        path: "*",
        element: <RedirectToWebsiteNotFoundPage />,
      },
    ],
  },
];

function v2Routes() {
  return [
    {
      element: (
        <ApplicationShellV2>
          <Outlet />
        </ApplicationShellV2>
      ),
      children: [
        {
          path: "fellow/:token/*",
          shouldRevalidate: () => false,
          lazy: () => import("./v2/funnels/fellow/FellowFunnelRoute"),
        },
        {
          id: PRE_ELIGIBILITY_ROUTE_ID,
          loader: preEligibilityLoaderV2,
          shouldRevalidate: () => false,
          children: [
            {
              path: "claims/new/*",
              shouldRevalidate: () => false,
              lazy: () => import("./v2/funnels/pre-eligibility/PreEligibilityFunnelRoute"),
            },
          ],
        },
        {
          path: "error/magic-link-expired",
          lazy: () => import("./v2/components/pages/MagicLinkExpired"),
        },
        {
          path: "error/:type",
          lazy: () => import("./v2/components/pages/ErrorPage"),
        },
        {
          path: "error/:type/:token",
          lazy: () => import("./v2/components/pages/ErrorPage"),
        },
      ],
    },
  ];
}

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter(routes);
