import { ParseParams, ZodSchema, ZodTypeDef } from "zod";
import { sendErrorReport } from "../modules/reporting";

/**
 * Validate response data against a schema, and if it fails, send an error report.
 * The tag param is used to identify the request in the error report.
 */
export function validateResponse<TSchemaOutput = any, TSchemaDef extends ZodTypeDef = ZodTypeDef>({
  data,
  schema,
  schemaParams,
  tag,
}: {
  data: unknown;
  schema: ZodSchema<TSchemaOutput, TSchemaDef>;
  schemaParams?: Partial<ParseParams>;
  tag: string;
}): TSchemaOutput | never {
  try {
    return schema.parse(data, schemaParams);
  } catch (error) {
    sendErrorReport(
      error,
      `Failed to validate tagged response: ${tag}, please ensure that the response matches the schema.`,
    );
    throw error;
  }
}

export function validatePayload<TSchemaOutput = any, TSchemaDef extends ZodTypeDef = ZodTypeDef>({
  data,
  schema,
  schemaParams,
  tag,
}: {
  data: unknown;
  schema: ZodSchema<TSchemaOutput, TSchemaDef>;
  schemaParams?: Partial<ParseParams>;
  tag: string;
}): TSchemaOutput | never {
  try {
    return schema.parse(data, schemaParams);
  } catch (error) {
    sendErrorReport(
      error,
      `Failed to validate tagged payload: ${tag}, please ensure that the payload matches the schema.`,
    );
    throw error;
  }
}
