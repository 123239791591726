import { useBrand } from "brand/BrandContext";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import useApplicationLinks from "../../../constants/linksPaths";
import FooterLink from "./link/FooterLink";
import styles from "./FooterLinks.module.scss";

const useFooterLinks = () => {
  const links = useApplicationLinks();
  const brand = useBrand();

  return useMemo(
    () => [
      {
        title: "footer.help",
        link: links.help,
      },
      {
        title: "footer.terms_and_conditions",
        link: links.termsAndConditions,
      },
      {
        title: "footer.contact_brand",
        link: links.contactBrand,
      },
      {
        title: "footer.price_list",
        link: brand && links.priceList,
      },
      {
        title: "footer.privacy_policy",
        link: !brand && links.privacyPolicy,
      },
    ],
    [links, brand],
  );
};

const FooterLinks = ({ className }: FooterLinksProps) => {
  const footerLinks = useFooterLinks();
  const { t } = useTranslation();

  return (
    <ul className={className ? className : styles.links}>
      {footerLinks.map(
        ({ link, title }) =>
          link && <FooterLink key={title} title={t(title)} link={link} className={styles.link} />,
      )}
    </ul>
  );
};

type FooterLinksProps = {
  className?: string;
};

export default FooterLinks;
