import { useQuery } from "@tanstack/react-query";
import coralApi, { CORAL_URL, DEFAULT_CORAL_CAMPAIGN } from "../coral";
import type { CoralCampaignResponse } from "../coral";

export function useCoralCampaign() {
  useQuery<CoralCampaignResponse, Error>({
    queryKey: [CORAL_URL],
    queryFn: coralApi.getCampaign,
    initialData: DEFAULT_CORAL_CAMPAIGN,
    retry: 1,
  });
}
