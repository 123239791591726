import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useBrand } from "../../../brand/BrandContext";
import styles from "./PoweredByLogo.module.scss";
import AirHelpLogo from "../../../layout/header/logo/airhelp-logo/AirHelpLogo";
import classNames from "classnames";
import { defaultCompensation } from "../../../constants/compensation";
import useToCurrency from "../../../app-settings/useToCurrency";
import { isMobileOnly } from "react-device-detect";

const websiteUrl = import.meta.env.VITE_WEBSITE_URL;

type PoweredByLogoProps = {
  className?: string;
};

const PoweredByLogo = ({ className }: PoweredByLogoProps) => {
  const { t } = useTranslation();
  const brand = useBrand();
  const toCurrency = useToCurrency();
  const isAmexOrEgencia = brand?.isAmex || brand?.isEgencia;

  return (
    <div className={classNames(className, styles.profile)}>
      {brand?.logoPresentInWebapp && (
        <Popover
          placement={isMobileOnly ? "top-end" : "right-end"}
          trigger={isMobileOnly ? "click" : "hover"}
          preventOverflow={true}
        >
          <PopoverTrigger>
            <div>
              <div className={styles.logo}>
                {!isAmexOrEgencia && (
                  <span className={styles.text} data-testid="poweredByText">
                    {t("header.powered_by")}
                  </span>
                )}
                <div className={styles.logoImage}>
                  <AirHelpLogo />
                </div>
              </div>
            </div>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverHeader>{t("components.powered_by_popover.heading")}</PopoverHeader>
            <PopoverBody>
              <div>
                <div className={styles.textSection}>
                  {t("components.powered_by_popover.worlds_largest")}
                </div>
                <div className={styles.textSection}>
                  {t("components.powered_by_popover.flight_disruption", {
                    amount: toCurrency(defaultCompensation),
                  })}
                </div>
                <a href={websiteUrl} target="_blank" rel="noopener noreferrer">
                  {websiteUrl}
                </a>
              </div>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      )}
    </div>
  );
};

export default PoweredByLogo;
